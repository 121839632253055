const margins = [0, 5, 10, 20, 40, 60]
const genSpacingFn = (
  property: string,
  dir: 'x' | 'y' | 'top' | 'bottom' | 'left' | 'right',
) => {
  return (n: 0 | 1 | 2 | 3 | 4 | 5) => {
    switch (dir) {
      case 'x': {
        return `
          ${property}-left: ${margins[n]}px;
          ${property}-right: ${margins[n]}px;
        `
      }
      case 'y': {
        return `
          ${property}-top: ${margins[n]}px;
          ${property}-bottom: ${margins[n]}px;
        `
      }
      default: {
        return `
          ${property}-${dir}: ${margins[n]}px;
        `
      }
    }
  }
}
export const my = genSpacingFn('margin', 'y')
export const mx = genSpacingFn('margin', 'x')
export const mt = genSpacingFn('margin', 'top')
export const mb = genSpacingFn('margin', 'bottom')
export const ml = genSpacingFn('margin', 'left')
export const mr = genSpacingFn('margin', 'right')

export const py = genSpacingFn('padding', 'y')
export const px = genSpacingFn('padding', 'x')
export const pt = genSpacingFn('padding', 'top')
export const pb = genSpacingFn('padding', 'bottom')
export const pl = genSpacingFn('padding', 'left')
export const pr = genSpacingFn('padding', 'right')
