import React from 'react'
import { Link } from 'react-router-dom'

import Default from '../layouts/Default'
import ContentContainer from '../components/ContentContainer'
import { PageTitle } from '../styles/fonts'

const PrivacyPolicy: React.FC = () => {
  return (
    <Default logo={null} footerLogo={null}>
      <ContentContainer>
        <p>
          <Link to="/">Home</Link>
        </p>
        <PageTitle>Privacy and Cookies Statement</PageTitle>

        <p>
          We know that sharing your personal information with us is based on
          trust. We take this seriously and are committed to ensuring that we
          respect your privacy when you visit our website or use our services.
          Please review this privacy and cookies statement (“Statement”)
          carefully to learn about our privacy practices.
        </p>
        <p>
          We operate a service that enables users (“you”, “your”) to research,
          find, and book travel experiences worldwide. In this Statement, these
          are collectively referred to as our Services.
        </p>
        <p>
          Our Services are owned and operated by Traversel NZ Ltd. By using our
          Services and visiting our websites and related applications, whether
          on a computer, phone, tablet or similar device (these are all referred
          to as a “Device”), you are accepting the practices described below.
        </p>
        <p>
          As a global company, we operate in a number of countries and
          territories where the laws and customs differ. This Statement provides
          a general overview of our privacy practices.
        </p>
        <h3>Information Collected</h3>

        <p>
          When you access or use our Services, we collect information from and
          about you to provide a more personalized and relevant experience. Some
          information we collect automatically. Other information we collect
          from different sources, including affiliated entities, business
          partners, and other independent third-party sources. When you use our
          Services by “clicking-through” from a third-party site or when you
          visit third-party sites via our Services, those third-party sites may
          share information with us about your use of their service. Information
          collected may include the following:
        </p>
        <ul>
          <li>
            Contact information, including name, phone number and email
            addresses
          </li>

          <li>
            Billing or payment information (such as your credit card number,
            cardholder name, expiration date, authentication code and billing
            address)
          </li>

          <li>
            Device information, such as when you accessed our Services and
            information about the Device used (for example, IP address, software
            or internet browser used, preferred languages, unique Device
            identifiers and advertising identifiers)
          </li>

          <li>
            Online activity, including pages you have visited, content reviewed
            and apps reviewed
          </li>

          <li>Booking history</li>
        </ul>
        <p>
          We may also collect, in instances where you have provided it,
          information about other travellers, including their email address and
          other travel-related information. If you are sharing information with
          us about other individuals, you must obtain their consent.
        </p>
        <p>
          In addition to the categories noted above, we may also collect
          location information if you have instructed your Device to send such
          information via the privacy settings on that Device. We may use your
          location information directly and/or share your location with third
          parties. We collect and share location information in order to provide
          you with relevant content, to analyze your use of our Services, to
          improve our Services and to provide contextual advertising or
          recommendations. For example, we may use your location to show you
          reviews of attractions near you when you are traveling.
        </p>
        <p>
          You can change the privacy settings of your Device at any time in
          order to turn off the functionality that collects and shares location
          information and/or the functionality to tag your photos with location
          information. However, turning off location-sharing may affect certain
          features that we offer. If you have any questions about the privacy
          settings of your Device, we suggest you contact the manufacturer of
          your Device or your mobile service provider for help.
        </p>
        <p>
          Please see the section on Cookies below for more information regarding
          the use of cookies and other technology described in this section,
          including regarding your choices relating to such technologies.
        </p>
        <h3>Information Uses</h3>

        <p>
          We are committed to providing you with relevant content on our
          Services and respect the data privacy laws of the different
          jurisdictions in which we operate. We use information about you so we
          can help you enjoy and use our Services, including in the following
          ways:
        </p>
        <ul>
          <li>Manage your account</li>

          <li>Facilitate your bookings</li>

          <li>Measure interest in and improve our Services</li>

          <li>
            Notify you about special offers, prize draws, competitions and
            services available from us, our affiliates, or our partners that may
            be of interest to you
          </li>

          <li>Communicate with you</li>

          <li>
            Enable communication between travelers and operators of travel
            experiences listed through us
          </li>

          <li>Customize your experience</li>

          <li>Make inferences about your interests or preferences</li>

          <li>
            Provide you with an optimized experience and to group users of our
            Services based on, for example, usage and demographics
          </li>

          <li>Send you survey or market research invitations</li>

          <li>Respond to your questions and comments</li>

          <li>Resolve disputes or troubleshoot problems</li>

          <li>Prevent potentially prohibited or unlawful activities</li>

          <li>
            Enforce our terms of use and notices; and as otherwise described to
            you at the point of collection
          </li>
        </ul>
        <p>
          Please note some information about you is required in order for us to
          provide you with relevant offerings from us, our affiliates and our
          partners and to enable you to enjoy other benefits of being a
          Traversel user. Please review the “Information Choices” section below
          with respect to your choices related to the collection and use of your
          information.
        </p>
        <h3>Information Sharing</h3>

        <p>
          In order to provide some of our Services, we may need to share
          information with certain other third parties, including our group of
          companies, in the following circumstances:
        </p>
        <ul>
          <li>
            Experience Suppliers. We share your information with suppliers of
            travel experiences to allow them to fulfil the bookings you make
            through our Services.
          </li>

          <li>
            Service Providers. We share information with certain service
            providers used to facilitate our Services, including to help with
            transactions.
          </li>

          <li>
            Business Partners. We may share information about you, your Devices
            and your use of our Services with our trusted business partners. For
            example, we may share information so that we can provide you with
            relevant content or in order to facilitate payment for a booking.
            This sharing is generally pursuant to written agreements which
            include confidentiality, privacy and security obligations; however,
            note that we do not control the privacy practices of these
            third-party business partners.
          </li>

          <li>
            Social Media Sites. When you use our Services and elect to share
            information with third-party social media sites, the information you
            share will be governed by the privacy policies of those social media
            sites and the privacy settings you have set with those social media
            websites.
          </li>

          <li>
            Other third parties, such as referring websites.Third parties may
            also assist us in collecting information by, for example, operating
            features of our website or facilitating the delivery of online
            advertising tailored to your interests. We may share audience
            segments and other information with third parties that use that
            information for tailored advertising to you.
          </li>
        </ul>
        <p>
          Third parties may only collect or access information as needed to
          perform their permitted functions.
        </p>
        <p>
          Certain Device operating system versions permit you to opt out of
          certain types of information sharing, including to certain advertising
          networks. Please check your Device settings if you want to limit such
          tracking.
        </p>
        <h3>Information Choices</h3>

        <p>
          You have options with respect to the collection and use of your
          information.
        </p>
        <p>Your options:</p>
        <ul>
          <li>
            You can choose not to provide us with certain information, although
            it may be needed to take advantage of certain features offered on
            our websites.
          </li>

          <li>
            You can prevent the collection of geolocation information, but note
            that turning off location sharing may affect certain features of our
            Services.
          </li>

          <li>
            You also can add or update information and close your account. If
            you close your account, we will deactivate your account and remove
            your profile information. We may retain some information associated
            with your account (including past transactions) for internal
            purposes including backups, fraud prevention, dispute resolution,
            investigations and legal compliance for the period necessary to
            fulfill the purposes outlined in this Statement.
          </li>

          <li>
            You will be given the opportunity to unsubscribe from promotional
            messages, including emails with travel-related opportunities, in any
            such promotional message we send.
          </li>
        </ul>
        <h3>Information Transfers</h3>

        <p>
          We are a global company and we operate in a number of different
          jurisdictions. If we transfer your information to other countries, we
          will use and protect that information as described in this Statement
          and in accordance with applicable law.
        </p>
        <h3>Information Security</h3>

        <p>
          We have implemented appropriate administrative, technical, and
          physical security procedures to help protect your information. We only
          authorize specific employees to access personal information and they
          may do so only for permitted business functions. We use encryption
          when transmitting your information between your system and ours, and
          between our system and those of the parties with whom we share
          information. We also employ firewalls and intrusion detection systems
          to help prevent unauthorized access to your information. However, we
          cannot guarantee the security of information from unauthorized entry
          or use, hardware or software failure, or other circumstances outside
          of our control.
        </p>
        <h3>Information Retention</h3>

        <p>
          We will retain copies of your information for as long as you maintain
          your account or as necessary in connection with the purposes set out
          in this Statement, unless applicable law requires a longer retention
          period. In addition, we may retain your information for the duration
          of any period necessary to establish, exercise or defend any legal
          rights.
        </p>
        <h3>Do Not Track Signals</h3>

        <p>
          Our website is not designed to currently respond to “Do Not Track”
          (“DNT”) signal requests from browsers. This is due to the lack of
          global standardized interpretation that defines “Do Not Track”
          signals. Once the industry has settled on standards related to this
          issue, we may re-evaluate this approach.
        </p>
        <h3>Google Analytics</h3>

        <p>
          The controller has integrated the component Google Analytics (with
          anonymisation function) on this website.
        </p>
        <p>
          Google Analytics is a web analytics service. Web analysis is the
          gathering, collection and analysis of data about the behavior of
          visitors to websites. Among other things, a web analysis service
          collects data on which website a data subject has come to a website
          from (so-called referrers), which subpages of the website were
          accessed or how often and for which period of time a subpage was
          viewed. A web analysis is mainly used to optimize a website and for
          the cost-benefit analysis of Internet advertising.
        </p>
        <p>
          The operator of the Google Analytics component is Google Inc., 1600
          Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA.
        </p>
        <p>
          Google Analytics uses cookies. The information generated by the cookie
          about your use of this website is usually transmitted to a Google
          server in the USA and stored there. Google might transfer the personal
          information collected via this technical procedure to third parties.
        </p>
        <p>
          As IP anonymization is activated on our website, your IP address will
          be shortened by Google within Member States of the European Union or
          other states in agreement with the European Economic Area. Only in
          exceptional cases, the full IP address is sent to and shortened by a
          Google server in the USA. On behalf of the operator of the website,
          Google will use this information to evaluate your use of the website,
          compile reports on website activity and to provide further services
          related to website and internet use to us. The IP address transferred
          through your browser to Google Analytics will not be combined with
          other data held by Google.
        </p>
        <p>
          In addition, this website uses the Analytics feature UserID to track
          interaction data. This User ID will be additionally anonymized and
          encrypted and will not be linked with other data.
        </p>
        <p>
          You can prevent the storage of cookies by a corresponding setting of
          your browser software; however, please note that if you do this, you
          may not be able to use all the features of this website to the fullest
          extent possible.
        </p>
        <p>
          In addition, you may prevent the collection of the data generated by
          the cookie and related to your use of the website (including your IP
          address) by Google as well as the processing of this data by Google by
          downloading and installing the browser plug-in available under the
          following link:{' '}
          <a href="https://tools.google.com/dlpage/gaoptout?hl=en">
            https://tools.google.com/dlpage/gaoptout?hl=en
          </a>
        </p>
        <p>
          This browser add-on informs Google Analytics via JavaScript that no
          data and information about website visits may be transmitted to Google
          Analytics.
        </p>
        <p>
          In addition, a cookie already set by Google Analytics can be deleted
          at any time via the Internet browser or other software programs.
        </p>
        <p>
          Further information and Google‘s applicable privacy regulations can be
          found at{' '}
          <a href="https://policies.google.com/privacy?hl=en">
            https://policies.google.com/privacy?hl=en
          </a>{' '}
          and{' '}
          <a href="https://marketingplatform.google.com/about/">
            https://marketingplatform.google.com/about/
          </a>
        </p>
        <p>
          The following link provides a further explanation of Google Analytics{' '}
          <a href="https://marketingplatform.google.com/about/">
            https://marketingplatform.google.com/about/
          </a>
        </p>
        <p>
          Our website also uses Google Analytics performance reports relating to
          demographics and interests and reports on Google Display Network
          impressions. You can disable Google Analytics for display advertising
          and customize the ads on the Google Display Network by visiting the ad
          settings at this link: https://adssettings.google.com.
        </p>
        <h3>Cookies</h3>

        <p>
          We want your access to our Services to be as easy, efficient and
          useful as possible. To help us do this, we use cookies and similar
          technologies to improve your experience, to enhance site security and
          to show you relevant advertising.
        </p>
        <p>
          <strong>What are cookies?</strong>
        </p>
        <p>
          Cookies are small text files that are automatically placed on your
          Device when you visit almost any website. They are stored by your
          internet browser and contain basic information about your internet
          use. Your browser sends these cookies back to a website every time you
          revisit it, so it can recognize your Device and improve your
          experience by, among other things, providing you with personalized
          content. We also use cookies to remember your login details, so you
          don’t have to re-enter them repeatedly when you use our Services.
          Other cookies help us understand your preferences. The cookies we use
          fall into three categories.
        </p>
        <ul>
          <li>
            Essential - These cookies are necessary to help you access and move
            around the website and use all its features. Without these cookies,
            the website would not work properly and you would not be able to use
            certain important features. For example, we use a cookie to keep you
            logged in during your visit, so the site does not require you to log
            in repeatedly to access different pages. We may also use essential
            cookies for fraud detection and prevention purposes. You cannot turn
            off essential cookies through the Cookie Consent Tool, as such
            cookies are necessary for you to access and use the features of the
            website.
          </li>

          <li>
            Analytics and customisation - We use these cookies to help us
            understand how the website is being used and how we can improve your
            experience of it. These cookies can provide us with information to
            help us understand which parts of the website interest our visitors
            and if they experience any errors. We use these cookies to test
            different designs and features for our sites and we also use them to
            help us monitor how visitors reach the website. We also use cookies
            to save your settings and preferences on the website, such as
            language preference and information you&apos;ve previously entered
            when searching for travel experiences. Some customization cookies
            are essential if you want to use certain features of the website.
          </li>
        </ul>
        <h3>Cookie Settings Tool</h3>

        <p>
          To give you control, we provide you with a tool, which allows you to
          review first and third party cookies placed through our website and
          adjust your cookie settings, including whether or not to accept such
          cookies. You can access this tool at any time by navigating to the
          home page of our website and clicking on the Manage Settings link at
          the bottom of every page. In addition, you can manage cookies through
          the settings of your internet browser. You can have the browser notify
          you when you receive a new cookie, delete individual cookies or delete
          all cookies.
        </p>
        <p>
          If you delete your cookies, your access to some functionality and
          areas of our Services might be degraded or restricted.
        </p>
        <p>
          Our use of any information we collect through cookies is subject to
          this Statement, which is linked on every page of our website.
        </p>
        <h3>Statement Changes and Notification</h3>

        <p>
          We may update this Statement in the future. If we believe any changes
          are material, we will let you know by doing one or more of the
          following: sending you a communication about the changes, placing a
          notice on the website and/or posting an updated Statement on the
          website. We will note at the top of this Statement when it was most
          recently updated. We encourage you to check back from time to time to
          review the most current version and to periodically review this
          Statement for the latest information on our privacy practices.
        </p>
        <h3>Contact</h3>

        <p>
          If you have a data privacy request, such as a request to delete or
          access your data, please send us a request{' '}
          <a href="mailto:support@traversel.com?subject=Data request">here</a>.
          For general data privacy inquiries or questions concerning our Privacy
          and Cookies Statement, please contact our privacy team by clicking{' '}
          <a href="mailto:support@traversel.com?subject=Data request">here</a>.
        </p>
        <h3>Europe</h3>

        <p>
          This GDPR Statement applies to persons in the European Economic Area
          (“EEA”), including those based in the United Kingdom. This GDPR
          Statement supplements our Statement; however, where the Statement
          conflicts with the GDPR Statement, the GDPR Statement will prevail as
          to persons in the EEA.
        </p>
        <p>
          <strong>Your rights under GDPR</strong>
        </p>
        <p>
          You have certain rights regarding your personal data. Your rights with
          respect to your own personal data include the following:
        </p>
        <ul>
          <li>
            The right to request access to your personal data. This enables you
            to receive a copy of the personal data we hold about you.
          </li>

          <li>
            The right to request to correct your personal data if it is
            inaccurate. You may also supplement any incomplete personal data we
            have, taking into account the purposes of the processing.
          </li>

          <li>The right to request deletion of your personal data if: </li>
          <ul>
            <li>
              your personal data is no longer necessary for the purposes for
              which we collected or processed it; or
            </li>

            <li>
              you withdraw your consent if the processing of your personal data
              is based on consent and no other legal ground exists; or
            </li>

            <li>
              you object to the processing of your personal data and we do not
              have an overriding legitimate ground for processing; or
            </li>

            <li>your personal data is unlawfully processed; or</li>

            <li>
              your personal data must be deleted for compliance with a legal
              obligation.
            </li>
          </ul>

          <li>
            The right to object to the processing of your personal data. We will
            comply with your request, unless we have a compelling overriding
            legitimate interest for processing or we need to continue processing
            your personal data to establish, exercise or defend a legal claim.
          </li>

          <li>The right to restrict the processing of personal data, if: </li>
          <ul>
            <li>
              the accuracy of your personal data is contested by you, for the
              period in which we have to verify the accuracy of the personal
              data; or
            </li>

            <li>
              the processing is unlawful and you oppose the deletion of your
              personal data and request restriction; or
            </li>

            <li>
              we no longer need your personal data for the purposes of
              processing, but your personal data is required by you for legal
              claims; or
            </li>

            <li>
              you have objected to the processing, for the period in which we
              have to verify overriding legitimate grounds.
            </li>
          </ul>

          <li>
            The right to data portability. You may request that we send this
            personal data to a third-party, where feasible. You only have this
            right if it relates to personal data you have provided to us where
            the processing is based on consent or necessity for the performance
            of a contract between you and us, and the processing is conducted by
            automated means.
          </li>
        </ul>
        <p>
          You will not have to pay a fee to access your personal data (or to
          exercise any of the other rights described in this Statement).
          However, we may charge a reasonable fee if your request is clearly
          unfounded, repetitive or excessive. We may need to request specific
          information from you to help us confirm your identity and ensure your
          right to access your personal data (or to exercise any of your other
          rights). This is a security measure to ensure that personal data is
          not disclosed to any person who has no right to receive it. In an
          effort to speed up our response, we may also contact you to ask you
          for further information in relation to your request. You can exercise
          several of your rights through the personal information section of
          your account. To exercise your other rights you can file a request by{' '}
          <a href="mailto:support@traversel.com?subject=Data request">
            clicking here
          </a>
          .
        </p>
        <p>
          <strong>Information Uses</strong>
        </p>
        <p>
          We will only use your personal data when the law allows us to.
          Pursuant to GDPR, we will use your personal data in one or more of the
          following circumstances:
        </p>
        <ul>
          <li>
            Where we need to perform the contract we are about to enter into or
            have entered into with you
          </li>

          <li>
            Where it is necessary for our legitimate interests (or those of a
            third-party) and your interests and fundamental rights do not
            override those interests
          </li>

          <li>Where we need to comply with a legal or regulatory obligation</li>

          <li>With your consent</li>
        </ul>
        <p>
          <strong>International Transfers</strong>
        </p>
        <p>
          Your personal data may be stored or transferred to countries outside
          the EEA for the purposes described in this Statement. When we store or
          transfer your personal data outside the EEA, we take the following
          precautions to ensure that your personal data is properly protected.
        </p>
        <p>
          Whenever we store or transfer your personal data outside the EEA, we
          will do so in accordance with applicable law and we will ensure a
          similar degree of protection is afforded to it by implementing
          appropriate safeguards. Transfers of personal data are made:
        </p>
        <ul>
          <li>
            to a country recognised by the European Commission as providing an
            adequate level of protection; or
          </li>

          <li>
            to a country which does not offer adequate protection but whose
            transfer has been governed by the standard contractual clauses of
            the European Commission or by implementing other appropriate
            cross-border transfer solutions to provide adequate protection.
          </li>
        </ul>
        <p>
          By using our services, you understand that your personal data may be
          transferred to our facilities and those third-parties with whom we
          share it as described in this Statement.
        </p>
      </ContentContainer>
    </Default>
  )
}

export default PrivacyPolicy
