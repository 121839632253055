import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import styled from 'styled-components'
import { Row, Col } from 'react-grid-system'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useHistory } from 'react-router-dom'
import ContentContainer from '../ContentContainer'
import { TextRight } from '../../styles/layout'
import Button from '../Button'
import { BillingDetails, ConfirmedCartItem } from '../../types'
import { makeBooking } from '../../api'
import { shadowCol } from '../../styles/misc'
import { py, pt, pl, pr, px, mt, mb } from '../../styles/spacing'
import { breakpointUp } from '../../styles/media'
import SectionHeading from './SectionHeading'
import storage from '../../util/storage'
import cartContext from '../../hooks/cartContext'
import { trackEvent } from '../../util/analytics'

const LeftColInner = styled.div`
  ${pt(4)}
  ${breakpointUp('md', `${py(4)}${pr(4)}`)}
`

const MaxWidth = styled.div`
  max-width: 400px;
`

const StripeWrapper = styled.div`
  margin-bottom: 20px;
  border: 2px solid #ccc;
  padding: 10px;
`

const PayButton = styled(Button)`
  width: 100%;
  text-transform: uppercase;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 2em;
`

const MaxHeightRow = styled(Row)`
  min-height: calc(100vh - 80px);
`

const RightCol = styled(Col)`
  ${shadowCol}
`

const RightColInner = styled.div`
  ${py(4)}
  ${breakpointUp(
    'md',
    `
    ${pl(4)}
  `,
  )}
`

const BoxedDetails = styled.div`
  ${px(3)}
  ${py(3)}
  ${mt(3)}
  border: 1px solid #000;
`

const TsAndCs = styled.p`
  font-size: 0.8em;
  ${mt(3)}
  ${mb(4)}
`

const Error = styled.p`
  ${py(3)}
  ${px(3)}
  background: #f9c7c7;
`

type CheckoutFormProps = {
  billingDetails: BillingDetails
  items: ConfirmedCartItem[]
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  items,
  billingDetails,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = React.useState('')
  const cartDispatch = cartContext.useCartDispatch()
  const history = useHistory()
  const [formValid, setFormValid] = React.useState(false)

  // listen to changes on the stripe card element
  // only allow the user to submit when it's valid
  React.useEffect(() => {
    if (!elements) return
    const card = elements.getElement('card')
    if (!card) return
    card.on('change', (event) => {
      setFormValid(event.complete)
    })
  }, [elements])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!formValid || !stripe || !elements || isSubmitting) return
    setError('')
    const card = elements.getElement(CardElement)
    if (!card) {
      setError(
        'There was an error contacting the credit card issuer. Please try again.',
      )
      return
    }

    try {
      setIsSubmitting(true)
      const bookingResponse = await makeBooking(items[0].time, [items[0]], {
        email: billingDetails.email,
        phone: billingDetails.phone,
      })
      const token = (bookingResponse as any).data.data.paymentToken
      const paymentResult = await stripe.confirmCardPayment(token, {
        payment_method: {
          card,
          billing_details: {
            name: `${billingDetails.firstName} ${billingDetails.lastName}`,
          },
        },
      })
      if (paymentResult.error) {
        console.log(paymentResult)
        setError(
          'There was an error confirming your payment, please try again.',
        )
        return
      }

      // track the successful event
      trackEvent('purchase', {
        departingDate: '2020-11-25',
        pageType: 'purchase',
        ecommerce: {
          currencyCode: 'NZD',
          purchase: {
            actionField: {
              id: bookingResponse.data.data.id, // booking reference
              affiliation: 'traversel',
              revenue: items[0].total, // total cost including fees
              coupon: undefined,
            },
            products: items[0].pax.map((pax) => {
              return {
                name: items[0].title, // product name
                id: items[0].productId, // product ID
                price: pax.price, // ticket price
                category: `Tours/${items[0].attraction.city}`, // e.g. Tours/Picton
                brand: items[0].attraction.title, // supplier
                variant: pax.label, // adult, child, etc
                quantity: pax.quantity,
              }
            }),
          },
        },
      })

      // clear our local cart
      storage.removeItem('tsl-checkout-cart')
      storage.removeItem('tsl-checkout-billing')
      storage.removeItem('tsl-checkout-detailed-cart')
      cartDispatch({
        action: 'ClearCart',
      })
      history.push('/checkout/success')
    } catch (e) {
      console.log('error creating booking', e)
      setError(
        'There was an error creating your booking, please check your details and try again.',
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <StripeWrapper>
        <CardElement />
      </StripeWrapper>
      <TsAndCs>
        By clicking the &ldquo;Pay Now&rdquo; button, you are acknowledging that
        you have read and are bound by our{' '}
        <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">
          Terms &amp; Conditions
        </a>
        ,{' '}
        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
          Privacy &amp; Cookie Policy
        </a>
        ,{' '}
        <a
          href="/cancellation-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cancellation Policy
        </a>
        , plus the tour operator&apos;s rules &amp; regulations (see the listing
        for more details).
      </TsAndCs>
      {error && <Error>{error}</Error>}
      <PayButton
        type="submit"
        buttonStyle="tertiary"
        disabled={!formValid || !stripe || isSubmitting}
      >
        {isSubmitting && <FontAwesomeIcon icon={faSpinner} spin size="lg" />}
        {!isSubmitting && <span>Pay Now</span>}
      </PayButton>
    </form>
  )
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '')

type Props = {
  billingDetails: BillingDetails
  items: ConfirmedCartItem[]
}
const Payment: React.FC<Props> = ({ billingDetails, items }) => {
  if (!items.length) return <div>No Booking Data</div>

  return (
    <div>
      <ContentContainer>
        <MaxHeightRow>
          <Col md={5}>
            <LeftColInner>
              <SectionHeading noMargin>For your information</SectionHeading>
              <p>
                You will be charged the total amount once your order is
                confirmed.
              </p>
              <p>
                If confirmation isn&apos;t received instantly, an authorization
                for the total amount will be held until your booking is
                confirmed.
              </p>
              <p>
                If you change or cancel your booking request, charges may apply.
              </p>
              <p>
                To learn more about bookings and payments, please &nbsp;
                <a href="/support" target="_blank" rel="noopener noreferrer">
                  visit our support page.
                </a>
              </p>
              <BoxedDetails>
                <SectionHeading noMargin>Booking Confirmation</SectionHeading>
                <p>
                  Your booking will be confirmed within 24 hours. Please note
                  that the ticket is valid for the selected date and time only.
                </p>
                <SectionHeading>Cancellation/Refund Policy</SectionHeading>
                <p>
                  We&apos;ve made booking and cancelling easy and flexible. You
                  can cancel your booking at any time up to 48 hours prior to
                  the date of selection and receive a 100% refund.
                </p>
                <p>
                  Should you have extenuating circumstances and have to cancel
                  within that 48-hour window, contact us via our support page,
                  and we&apos;ll help you out.
                </p>
              </BoxedDetails>
            </LeftColInner>
          </Col>
          <RightCol md={7}>
            <RightColInner>
              <MaxWidth>
                <SectionHeading noMargin>Summary</SectionHeading>
                {items.map((item) => (
                  <Row key={item.productId}>
                    <Col>{item.title}</Col>
                    <Col>
                      <TextRight>NZD {item.total.toFixed(2)}</TextRight>
                    </Col>
                  </Row>
                ))}
                <SectionHeading>Billing Details</SectionHeading>
                <p>
                  {billingDetails.firstName} {billingDetails.lastName}
                  <br />
                  {billingDetails.email}
                  {billingDetails.phone && (
                    <>
                      <br /> {billingDetails.phone}
                    </>
                  )}
                </p>
                <SectionHeading>Secure Payment</SectionHeading>
                <Elements stripe={stripePromise}>
                  <CheckoutForm items={items} billingDetails={billingDetails} />
                </Elements>
              </MaxWidth>
            </RightColInner>
          </RightCol>
        </MaxHeightRow>
      </ContentContainer>
    </div>
  )
}

export default Payment
