import React from 'react'
import { Row, Col } from 'react-grid-system'
import { useField } from 'formik'
import styled, { css } from 'styled-components'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline'

import { fontHeading } from '../../styles/fonts'
import { Label } from '../../styles/forms'

const NumberInput = styled.input`
  ${fontHeading}
  -moz-appearance: textfield;
  border: none;
  outline: none;
  width: 35px;
  text-align: center;
  background: none;
  font-weight: bold;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const IconButton = styled.button`
  padding: 0;
  border: none;
  appearance: none;
  font-size: 24px;
  line-height: 18px;
  vertical-align: middle;
  color: #d8d8d8;
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0;
    `}
`

const SubLabel = styled.span`
  ${fontHeading}
  display: block;
  font-weight: 300;
  font-size: 10px;
`

const LabelDesc = styled.span`
  font-weight: normal;
  color: #4a4a4a;
`

type IncrementProps = {
  name: string
  label: string
  subLabel?: string
  min?: number
  desc?: string
  className?: string
  disableIncrement?: boolean
  disableDecrement?: boolean
}
const Increment: React.FC<IncrementProps> = ({
  name,
  label,
  min,
  subLabel,
  desc,
  className,
  disableDecrement,
  disableIncrement,
}) => {
  const [field, _meta, helpers] = useField(name)

  const getValue = () => {
    return field.value || 0
  }

  const increment = () => {
    if (disableIncrement) return
    helpers.setValue(getValue() + 1)
  }

  const decrement = () => {
    if (disableDecrement) return
    if (min !== undefined && field.value - 1 < min) return
    helpers.setValue(getValue() - 1)
  }

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = parseInt(e.currentTarget.value, 10) || 0
    helpers.setValue(value)
  }
  return (
    <div className={className}>
      <Row>
        <Col>
          <Label>
            {label}
            {desc && <LabelDesc> {desc}</LabelDesc>}
          </Label>
          {subLabel && <SubLabel>{subLabel}</SubLabel>}
        </Col>
        <Col xs="content">
          <IconButton
            type="button"
            onClick={decrement}
            disabled={disableDecrement}
          >
            <RemoveIcon />
          </IconButton>
          <NumberInput
            type="number"
            value={field.value}
            onChange={onChange}
            name={name}
            disabled
          />
          <IconButton
            type="button"
            onClick={increment}
            disabled={disableIncrement}
          >
            <AddIcon />
          </IconButton>
        </Col>
      </Row>
    </div>
  )
}

export default Increment
