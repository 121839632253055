import React from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

type Props = {
  noMargin?: boolean
}
const Wrapper = styled.div<Props>`
  margin: 40px auto;
  text-align: center;

  ${(props) =>
    props.noMargin &&
    css`
      margin: 0 auto;
    `}
`

const Loading: React.FC<Props> = ({ noMargin }) => {
  return (
    <Wrapper noMargin={noMargin}>
      <FontAwesomeIcon icon={faSpinner} spin size="lg" />
    </Wrapper>
  )
}

export default Loading
