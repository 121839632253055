import React from 'react'
import styled from 'styled-components'
import shuffle from 'lodash/shuffle'

import { Col, Row } from 'react-grid-system'
import Default from '../layouts/Default'
import ContentContainer from '../components/ContentContainer'
import { h3Black } from '../styles/fonts'
import ProductGrid from '../components/ProductGrid'
import { useProductSate } from '../hooks/productContext'
import LocationHeader from '../components/LocationHeader'
import { ProductSearchResult } from '../types'

const AttractionSection = styled.section`
  padding: 30px 0;
  background: #fff;
`

const Heading2 = styled.h2`
  ${h3Black}
  margin: 0 0 28px;
`

const Home: React.FC = () => {
  const [finalProducts, setFinalProducts] = React.useState<
    ProductSearchResult[]
  >([])
  const { isLoading, homePageProducts, discountedProducts } = useProductSate()

  React.useEffect(() => {
    if (isLoading || !homePageProducts.length) return

    // shuffle the array
    const productsCopy = shuffle(homePageProducts).slice(0, 4)
    const hasDiscounted = productsCopy.some((p) => p.discounted)
    // we need to include some discounted ones
    if (!hasDiscounted && discountedProducts.length) {
      const randomDiscountedProd =
        discountedProducts[
          Math.floor(Math.random() * discountedProducts.length)
        ]
      setFinalProducts(
        shuffle([...productsCopy.slice(1, 4), randomDiscountedProd]),
      )
    } else {
      setFinalProducts(productsCopy)
    }
  }, [isLoading, homePageProducts, discountedProducts])
  return (
    <Default>
      <LocationHeader />
      <AttractionSection>
        <ContentContainer>
          <Row>
            <Col>
              <Heading2>Featured Experiences</Heading2>
            </Col>
          </Row>
          <ProductGrid products={finalProducts} isLoading={isLoading} />
        </ContentContainer>
      </AttractionSection>
    </Default>
  )
}

export default Home
