import React from 'react'
import { Link } from 'react-router-dom'

import Default from '../layouts/Default'
import ContentContainer from '../components/ContentContainer'
import { PageTitle } from '../styles/fonts'

const Support: React.FC = () => {
  return (
    <Default>
      <ContentContainer>
        <p>
          <Link to="/">Home</Link>
        </p>
        <PageTitle>Support</PageTitle>
        <p>
          For assistance you can contact the Mix and Match New Zealand-based
          Call Centre on:
        </p>

        <p>
          Phone: 0800 367 468
          <br />
          International callers dial: +64 3 357 3021
          <br />
          Email: help@mixandmatch.co.nz
          <br />
        </p>

        <p>Call Centre open hours:</p>

        <p>
          Monday-Friday: 08.00am-midnight
          <br />
          Sat - Sun: 09.00am-5.30pm
          <br />
          Public Holidays: 09.00am-5.30pm
          <br />
          After hours calls will be diverted to an answering service.
          <br />
        </p>
      </ContentContainer>
    </Default>
  )
}

export default Support
