import React from 'react'
import styled from 'styled-components'

import { px, py } from '../../styles/spacing'
import Header from '../Header'

const WrapperInner = styled.div`
  ${px(2)}
  ${py(2)}
  text-align: center;
  max-width: 500px;
  margin: auto;
`

const CheckoutSuccess: React.FC = () => {
  return (
    <div>
      <Header />
      <WrapperInner>
        <h1>Success!</h1>
        <p>
          Thank you for your booking. You will receive a payment receipt shortly
          and an email containing your tickets will be sent when the booking is
          confirmed with the tour operator.
        </p>
      </WrapperInner>
    </div>
  )
}

export default CheckoutSuccess
