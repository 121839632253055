import React from 'react'
import ReactModal from 'react-modal'
import styled, { ThemeContext } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { CSSTransition } from 'react-transition-group'
import sortBy from 'lodash/sortBy'
import reverse from 'lodash/reverse'
import moment from 'moment'
import { Row, Col } from 'react-grid-system'

import Button from './Button'
import { h3Black, h2Black } from '../styles/fonts'
import BlankButton from './BlankButton'
import Loading from './Loading'
import { getBookings } from '../api'
import { BookingListItem, BookingStatus } from '../types'
import BookingDetail from './BookingDetail'
import { px, py, mb, mt } from '../styles/spacing'
import { breakpointUp } from '../styles/media'

const ModalWrapper = styled.div`
  .booking-detail-enter {
    opacity: 0;
  }

  .booking-detail-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }

  .booking-detail-exit {
    opacity: 1;
  }

  .booking-detail-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
`

const BookingDetailWrap = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  color: #000;
  ${px(4)}
  ${py(4)}
  overflow-y: auto;

  ${breakpointUp(
    'lg',
    `
    right: calc(33% + 60px);
  `,
  )}
`

const CloseRow = styled.div`
  position: absolute;
  right: 15px;
  top: 30px;
`

const SidebarTitle = styled.h2`
  ${h2Black}
  margin-top: 0;
`

const BookingTitle = styled.h3`
  ${h3Black}
  ${mb(0)}
  ${mt(2)}

  ${breakpointUp('lg', `${mt(0)}`)}
`

const BookingSection = styled.div`
  ${mb(4)}
`

const BookingImage = styled.div`
  height: 140px;
  background-color: #eaeaea;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

const AddressText = styled.div`
  text-transform: uppercase;
`

const BookingListItemWrap = styled.div`
  ${mb(3)}
`

type ListItemProps = {
  booking: BookingListItem
  onClick(): void
}
const BookingListItemDisplay: React.FC<ListItemProps> = ({
  booking,
  onClick,
}) => {
  const visitDate = moment.parseZone(booking.visitDateLocal)
  const addressItems: (keyof BookingListItem)[] = [
    'productSuburb',
    'productCity',
    'productCountry',
  ]
  const address = addressItems
    .reduce<string[]>((prev, current) => {
      const item = booking[current]
      if (item) return [...prev, item]
      return [...prev]
    }, [])
    .join(', ')
  return (
    <BookingListItemWrap>
      <Row>
        <Col xs={12} lg={4}>
          <BlankButton onClick={onClick} block>
            <BookingImage
              style={{
                backgroundImage: booking.productImage?.url
                  ? `url(${booking.productImage.url})`
                  : undefined,
              }}
            />
          </BlankButton>
        </Col>
        <Col xs={12} lg={8}>
          <BookingTitle>
            <BlankButton onClick={onClick} block>
              {booking.productName || 'Missing product name'}
            </BlankButton>
          </BookingTitle>
          <AddressText>{address}</AddressText>
          <div>{visitDate.format('ddd, MMM D, YYYY')}</div>
        </Col>
      </Row>
    </BookingListItemWrap>
  )
}

type BookingSidebarProps = {
  onAfterClose?: () => void
}
const BookingSidebar: React.FC<BookingSidebarProps> = ({ onAfterClose }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const themeContext = React.useContext(ThemeContext)
  const [loadingBookings, setLoadingBookings] = React.useState(true)
  const [bookings, setBookings] = React.useState<BookingListItem[]>([])
  const [previousBookings, setPreviousBookings] = React.useState<
    BookingListItem[]
  >([])
  const [cancelledBookings, setCancelledBookings] = React.useState<
    BookingListItem[]
  >([])
  const [bookingDetail, setBookingDetail] = React.useState<string | null>(null)
  const [pendingBookings, setPendingBookings] = React.useState<
    BookingListItem[]
  >([])

  React.useEffect(() => {
    if (!isOpen) return
    const runGetBookings = async () => {
      try {
        const response = await getBookings()

        // format the booking
        const formatted: BookingListItem[] = response.data.data.map(
          (booking) => {
            return {
              ...booking,
              productImage: booking.productImage
                ? JSON.parse(booking.productImage)
                : null,
            }
          },
        )

        // sort all the bookings by date
        const sorted = reverse(
          sortBy(formatted, (booking) => {
            return moment(booking.visitDate).unix()
          }),
        )

        const today = moment()
        const currentBookingStatus: BookingStatus[] = ['Booked']
        const current = sorted.filter((booking) => {
          return (
            currentBookingStatus.includes(booking.statusCode) &&
            moment(booking.visitDate).isSameOrAfter(today)
          )
        })

        const previousBookingStatus: BookingStatus[] = ['Expired']
        const previous = sorted.filter((booking) => {
          return (
            previousBookingStatus.includes(booking.statusCode) ||
            (booking.statusCode === 'Booked' &&
              moment(booking.visitDate).isBefore(today))
          )
        })

        const cancelledBookingStatus: BookingStatus[] = [
          'Cancelled',
          'Cancelling',
        ]
        const cancelled = sorted.filter((booking) => {
          return cancelledBookingStatus.includes(booking.statusCode)
        })

        const pendingStatus: BookingStatus[] = ['Pending', 'PreBooked']
        const pending = sorted.filter((booking) => {
          return pendingStatus.includes(booking.statusCode)
        })

        setBookings(current)
        setPreviousBookings(previous)
        setCancelledBookings(cancelled)
        setPendingBookings(pending)
      } catch (e) {
        console.log('could not fetch bookings', e)
      } finally {
        setLoadingBookings(false)
      }
    }
    runGetBookings()
  }, [isOpen])

  return (
    <>
      <Button buttonStyle="filled" onclick={() => setIsOpen(true)}>
        My Bookings
      </Button>
      <ReactModal
        isOpen={isOpen}
        onAfterClose={onAfterClose}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
          content: {
            position: 'fixed',
            left: 'auto',
            top: 0,
            right: 0,
            bottom: 0,
            width: '33%',
            minWidth: '260px',
            maxWidth: '710px',
            padding: '30px',
            borderRadius: 0,
            background: themeContext.colors.primary,
            color: themeContext.colors.secondary,
            borderColor: themeContext.colors.primary,
            fontWeight: 400,
          },
        }}
      >
        <ModalWrapper>
          <CSSTransition
            in={!!bookingDetail}
            timeout={200}
            classNames="booking-detail"
            unmountOnExit
          >
            <BookingDetailWrap className="booking-detail">
              <CloseRow>
                <BlankButton onClick={() => setBookingDetail(null)}>
                  <FontAwesomeIcon icon={faTimes} size="2x" />
                </BlankButton>
              </CloseRow>
              <BookingDetail bookingId={bookingDetail} />
            </BookingDetailWrap>
          </CSSTransition>
          <CloseRow>
            <BlankButton onClick={() => setIsOpen(false)}>
              <FontAwesomeIcon icon={faTimes} size="2x" />
            </BlankButton>
          </CloseRow>
          {!!pendingBookings.length && (
            <BookingSection>
              <SidebarTitle>Pending Bookings</SidebarTitle>
              {pendingBookings.map((booking) => (
                <BookingListItemDisplay
                  booking={booking}
                  onClick={() => setBookingDetail(booking.bookingId)}
                  key={booking.bookingId}
                />
              ))}
            </BookingSection>
          )}
          <BookingSection>
            <SidebarTitle>Current Bookings</SidebarTitle>
            {loadingBookings && <Loading />}
            {!loadingBookings && bookings.length === 0 && (
              <p>You do not have any current bookings.</p>
            )}
            {!!(!loadingBookings && bookings.length) && (
              <div>
                {bookings.map((booking) => (
                  <BookingListItemDisplay
                    booking={booking}
                    onClick={() => setBookingDetail(booking.bookingId)}
                    key={booking.bookingId}
                  />
                ))}
              </div>
            )}
          </BookingSection>
          <BookingSection>
            <SidebarTitle>Previous Bookings</SidebarTitle>
            {loadingBookings && <Loading />}
            {!loadingBookings && previousBookings.length === 0 && (
              <p>You do not have any previous bookings.</p>
            )}
            {!!(!loadingBookings && previousBookings.length) && (
              <div>
                {previousBookings.map((booking) => (
                  <BookingListItemDisplay
                    booking={booking}
                    onClick={() => setBookingDetail(booking.bookingId)}
                    key={booking.bookingId}
                  />
                ))}
              </div>
            )}
          </BookingSection>
          {cancelledBookings.length > 0 && (
            <BookingSection>
              <SidebarTitle>Cancelled Bookings</SidebarTitle>
              {!!(!loadingBookings && cancelledBookings.length) && (
                <div>
                  {cancelledBookings.map((booking) => (
                    <BookingListItemDisplay
                      booking={booking}
                      onClick={() => setBookingDetail(booking.bookingId)}
                      key={booking.bookingId}
                    />
                  ))}
                </div>
              )}
            </BookingSection>
          )}
        </ModalWrapper>
      </ReactModal>
    </>
  )
}

export default BookingSidebar
