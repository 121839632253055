import * as React from 'react'
import moment from 'moment-timezone'
import { useFormikContext } from 'formik'
import SingleDate from '../forms/SingleDate'
import SectionHeading from './SectionHeading'
import { getAvailability, getNextAvailability } from '../../api'

interface Props {
  quantity: number
  dob: string
  attractionId: string
  productId: string
  timeZone: string
  isFreeSell: boolean
}
export default function BookingDate({
  quantity,
  dob,
  attractionId,
  productId,
  timeZone,
  isFreeSell,
}: Props): JSX.Element | null {
  const { setFieldValue } = useFormikContext()
  const [minDate, setMinDate] = React.useState<moment.Moment>(
    moment().add(1, 'day').startOf('day'),
  )
  const [noAvailability, setNoAvailability] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [currentMonthDisplay, setCurrentMonthDisplay] = React.useState<
    null | string
  >(null)
  const [blockedDays, setBlockedDays] = React.useState<Record<number, boolean>>(
    {},
  )
  React.useEffect(() => {
    if (!currentMonthDisplay) {
      return
    }

    const getMonthAvailable = async () => {
      const start = moment.tz(currentMonthDisplay, timeZone)
      const now = moment.tz(moment(), timeZone)
      const end = moment
        .tz(currentMonthDisplay, timeZone)
        .endOf('month')
        .format()

      const availabilityItems = await getAvailability(
        attractionId,
        productId,
        quantity,
        dob,
        {
          visitStartDate: start.isBefore(now) ? now.format() : start.format(),
          visitEndDate: end,
        },
      )
      const dayMap: Record<number, boolean> = {}
      for (let i = moment(start); i.isBefore(end); i.add(1, 'day')) {
        dayMap[i.startOf('day').unix()] = true
      }
      availabilityItems.forEach((item) => {
        const mItem = moment(item.startDateTimeAttractionTz)
        dayMap[mItem.startOf('day').unix()] = false
      })
      setBlockedDays(dayMap)
    }
    getMonthAvailable().catch(console.error)
  }, [
    currentMonthDisplay,
    productId,
    quantity,
    dob,
    attractionId,
    timeZone,
    isFreeSell,
  ])

  React.useEffect(() => {
    if (!quantity) return
    ;(async () => {
      try {
        const visitStart = moment.tz(moment(), timeZone)
        const visitEnd = moment(visitStart).add(1, 'year')

        const response = await getNextAvailability(
          attractionId,
          productId,
          quantity,
          dob,
          visitStart.format(),
          visitEnd.format(),
        )
        if (response.length) {
          const newStartDate = moment(response[0].startDateTime)
          if (newStartDate > moment().add(1, 'day').startOf('day')) {
            setMinDate(newStartDate)
          }
          setNoAvailability(false)
        } else {
          setNoAvailability(true)
        }
        setLoading(false)
      } catch (e) {
        console.log(e)
      }
    })()
  }, [quantity, dob, attractionId, productId, timeZone])
  return (
    <div>
      <SectionHeading>Dates</SectionHeading>
      {!noAvailability && !loading && (
        <div>
          <SingleDate
            name="date"
            min={minDate}
            yearMin={moment().year()}
            yearMax={moment().year() + 5}
            onChange={(d) => {
              if (!isFreeSell || !d) return
              const time = d.format('YYYY-MM-DDT00:00:00')
              setFieldValue('time', time)
            }}
            onDisplayMonthChange={(m) => {
              setCurrentMonthDisplay(
                m.startOf('month').format('YYYY-MM-DDT00:00:00'),
              )
            }}
            blocked={blockedDays}
          />
          <p>Dates are shown in the vendor&apos;s local time.</p>
        </div>
      )}
      {noAvailability && <p>Sorry, there are no dates available.</p>}
    </div>
  )
}
