import React from 'react'
import { Link } from 'react-router-dom'

import Default from '../layouts/Default'
import ContentContainer from '../components/ContentContainer'
import { PageTitle } from '../styles/fonts'

const Cancellation: React.FC = () => {
  return (
    <Default logo={null} footerLogo={null}>
      <ContentContainer>
        <p>
          <Link to="/">Home</Link>
        </p>
        <PageTitle>Cancellations and Missed Bookings</PageTitle>

        <p>
          The need for flexibility when booking experiences has never been
          greater which is why we’ve tried to make things simple and give you
          access to unwind when things change.
        </p>
        <h2>My booking is more than 48 hours away</h2>
        <p>
          If your booking is more than 48 hours away you can change or cancel
          directly on Mix and Match. In the case of a change there will be no
          fee incurred. In the case of cancellation, we will refund you the full
          cost of the experience.
        </p>
        <h2>My booking is within 48 hours / I missed my booking</h2>
        <p>
          Unfortunately, once inside the 48 hour window or if you have missed
          your booking there are no refunds. This is so that experience
          providers have sufficient time to offer your place to someone else and
          are not left out of pocket.
        </p>
        <h2>My booking doesn’t have a date specified</h2>
        <p>
          For bookings without a specific date or time specified on the ticket,
          the ticket may be cancelled at any time up until 6 months from the
          date of purchase.
        </p>
        <h2>The experience provider cancelled my booking</h2>
        <p>
          In the circumstance where a service provider cancels the experience
          e.g. due to bad weather, you will be eligible for a full refund
          regardless of how far away the experience is. The experience provider
          may also choose to offer you an alternative date/time for your
          experience, however, it is your choice as to whether you wish to
          accept this or claim your refund.
        </p>
      </ContentContainer>
    </Default>
  )
}

export default Cancellation
