import React from 'react'

type Reducer<S, A> = (s: S, a: A) => S
type Dispatch<A> = (a: A) => void

const createUse = (name: string, Context: React.Context<any>) => () => {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error(`use${name}State must be used within a ${name}Provider`)
  }
  return context
}

export default function createReducerContextWithProvider<S, A>(
  name: string,
  reducer: Reducer<S, A>,
  initial: S,
): {
  Provider: React.FC<{ initial?: S }>
  useState: () => S
  useDispatch: () => Dispatch<A>
} {
  const StateContext = React.createContext<S | undefined>(undefined)
  const DispatchContext = React.createContext<Dispatch<A> | undefined>(
    undefined,
  )

  const Provider: React.FC<{ initial?: S }> = ({
    children,
    initial: override,
  }) => {
    const [state, dispatch] = React.useReducer(reducer, override || initial)
    return (
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          {children}
        </DispatchContext.Provider>
      </StateContext.Provider>
    )
  }

  const useState = createUse(name, StateContext)
  const useDispatch = createUse(name, DispatchContext)

  return { Provider, useState, useDispatch }
}
