import React from 'react'

import { Link } from 'react-router-dom'
import Default from '../layouts/Default'
import ContentContainer from '../components/ContentContainer'
import { PageTitle } from '../styles/fonts'

const TermsOfUse: React.FC = () => {
  return (
    <Default logo={null} footerLogo={null}>
      <ContentContainer>
        <p>
          <Link to="/">Home</Link>
        </p>
        <PageTitle>Customer Terms of Use</PageTitle>
        <p>
          Traversel is offering its supplier’s services to customers via the Mix
          and Match website. The following are Traversel’s Terms of Use (&quot;
          <strong>Terms</strong>&quot;). The Terms you see below are important
          because they:
        </p>
        <ul>
          <li>Outline your legal rights towards Traversel</li>

          <li>
            Describe the rules everyone needs to follow when using Traversel
          </li>

          <li>
            Describe how to resolve any disputes that may arise from your use of
            Traversel
          </li>
        </ul>
        <p>
          Please read these Terms, our{' '}
          <Link to="/privacy-policy">Privacy Policy</Link> and any other terms
          referenced in this document carefully.
        </p>
        <p />
        <p>
          <strong>1. Who are we?</strong>
        </p>
        <p>
          We are operated by Traversel NZ Limited. When we refer to &quot;
          <strong>Traversel</strong>
          &quot;, &quot;<strong>we</strong>&quot;, &quot;<strong>us</strong>
          &quot; or &quot;<strong>our</strong>&quot; in these Terms, we mean
          Traversel NZ Ltd. Traversel enables you to discover, search, book or
          purchase various products and services, including tours, activities,
          tickets, restaurant reservations, transportation and other experiences
          (individually and collectively known as &quot;
          <strong>Services</strong>
          &quot;), which are supplied and operated by third parties (&quot;
          <strong>Experience Providers</strong>&quot;). You may access our
          Services through our website (&quot;<strong>Website</strong>&quot;) or
          mobile application (&quot;<strong>App</strong>&quot;), collectively
          known as our &quot;<strong>Platforms</strong>&quot;.
        </p>
        <p />
        <p>
          <strong>2. What am I agreeing to?</strong>
        </p>
        <p>
          When we use &quot;<strong>I</strong>&quot;, &quot;<strong>you</strong>
          &quot;, &quot;<strong>your</strong>&quot; or &quot;
          <strong>user</strong>
          &quot; in these Terms, we refer to anyone who accesses or uses our
          Platforms. By accessing or using our Platforms, you acknowledge that
          you have read and understood these Terms, accept these Terms and agree
          to be bound by them. You also represent and warrant that you have the
          legal capacity and authority to enter into a contract with us. If you
          do not agree with these Terms, please discontinue your access and use
          of our Platforms.
        </p>
        <p />
        <p>
          <strong>3. Your account</strong>
        </p>
        <p>
          <strong>3.1</strong> In the course of using our Platforms, you may
          need to register and maintain a user account with us (&quot;
          <strong>Traversel Account</strong>&quot;). Traversel Accounts cannot
          be assigned or transferred to another person. You agree not to
          register or maintain more than one (1) Traversel Account at the same
          time unless authorised by us. When you register a Traversel Account
          with us, you agree to provide and maintain accurate, current and
          complete information during registration.
        </p>
        <p>
          <strong>3.2</strong> You are responsible for maintaining the
          confidentiality of the information and password associated with your
          Traversel account, and also for any activities occurring under your
          Traversel Account. You must immediately notify us of any unauthorised
          use of your Traversel account, password or any other breach of
          security. We will not be liable for any loss or damage arising from
          your failure to comply with this provision. To the maximum extent
          permitted by applicable laws and regulations, we may, at our sole
          discretion and at any time, refuse registration of a Traversel Account
          or cancel an existing Traversel Account without prior notice or
          liability.
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>4. Your bookings</strong>
        </p>
        <p>
          <strong>4.1 </strong>When making a booking through our Platforms, that
          booking is made directly with the Experience Provider named on the
          booking page, and our Platforms only act as a facilitator for booking.
          We do not provide, own or control any of the Services that you can
          access through our Platforms. The Services are owned, controlled or
          made available by the Experience Providers either directly or as an
          agent. Experience Providers are responsible for the Services. Your
          interaction with any Experience Provider through our Platforms is at
          your own risk. Traversel does not bear any responsibility should
          anything go wrong during your travel. The display on our Platforms of
          a Service or Experience Provider does not in any way imply or suggest
          any sponsorship or endorsement by us, or any affiliation between the
          Experience Provider and us and by using Our Platforms, you acknowledge
          such.
        </p>
        <p>
          <strong>4.2 </strong>An Experience Provider may impose additional
          terms and conditions, rules and restrictions (&quot;
          <strong>Experience Provider Terms</strong>&quot;) that will apply to
          your booking. You will be provided with the name of the Experience
          Provider and the applicable Experience Provider Terms during the
          booking process. As the Experience Provider Terms applicable for each
          Service may vary, you should ensure that you fully understand and
          accept each of the applicable Experience Provider Terms before
          completing your booking. If you are booking on behalf of other
          persons, you are required to ensure that they are aware of and agree
          to the applicable Experience Provider Terms. If you fail to comply
          with the Experience Provider Terms, the Experience Provider has a
          right to refuse or cancel your booking without offering any refunds.
          For enquiries about the Experience Provider’s products and services or
          its Experience Provider Terms, please contact the Experience Provider
          directly.
        </p>
        <p>
          <strong>4.3 </strong>After making a booking, you will be notified via
          email (&quot;<strong>Booking Confirmation</strong>&quot;) which
          contains a confirmation number (&quot;
          <strong>Booking Confirmation Number</strong>&quot;). Depending on the
          policy of the Experience Provider, the Booking Confirmation may
          provide a ticket for redemption (&quot;<strong>Booking Ticket</strong>
          &quot;). The Experience Provider nor we will be responsible for
          Booking Confirmation Numbers or Booking Tickets that are lost, stolen
          or destroyed.
        </p>
        <p>
          <strong>4.4 </strong>To utilise your booking, you will need to appear
          in person at the meeting point designated and at the stipulated time,
          and present such documents and information as may be required by the
          Experience Provider, who is solely responsible for accepting your
          Booking Confirmation. If you fail to appear for a booking (&quot;
          <strong>No-Show</strong>&quot;) or fail to utilise a booking within
          the specific time period that it is valid for, your booking cannot be
          re-used and you will not be eligible for any refunds.
        </p>
        <p>
          <strong>4.5 </strong>We reserve the right to deny and cancel any
          bookings that we deem to violate these Terms, at our sole discretion
          and without any refunds. If, despite complying with these Terms and
          the Experience Provider’s Terms, you are unable to utilise your
          booking due to the fault of the Experience Provider or otherwise,
          please contact us at feedback@traversel.com .
        </p>
        <p />
        <p>
          <strong>5. Your travels</strong>
        </p>
        <p>
          <strong>5.1 </strong>It is your responsibility to ensure that you
          satisfy all legal requirements when entering a country, including visa
          and health requirements. As these requirements are subject to change
          without notice, Traversel recommends that you verify with the
          appropriate consulate before departure. It is your responsibility to
          ensure that you are either insured by your own insurance company, or
          by the Experience Providers who may provide insurance coverage.
          Traversel takes no responsibility in the insurance process and
          recommends that you be covered with a comprehensive travel insurance
          policy before departure.
        </p>
        <p />
        <p>
          <strong>6. Pricing & payments</strong>
        </p>
        <p>
          <strong>6.1 </strong>When booking products and services on our
          Platforms, you will be quoted a price that needs to be paid before the
          booking is confirmed. All prices shown are on a per-person basis,
          unless otherwise specified. All prices are subject to changes at any
          time before purchase, and you should refer to the latest published
          rates before making any purchase. Although our Services are currently
          free for all users, Traversel reserves the right to require an
          additional charge(s) for the use of its Platforms and Services at any
          time, including but not limited to booking fees or processing fees for
          specific payment methods. Any new or increased charges will be
          notified to you via email or posting on our Platforms, and your use of
          our Platforms and Services following such notification constitutes
          acceptance.
        </p>
        <p>
          <strong>6.2 </strong>When you complete your purchase, you will be
          notified of the payment methods that are acceptable for that purchase.
          If there is a processing fee charged for the use of a payment method,
          this will be notified to you. The amount payable presented to you when
          you complete your purchase will include all applicable prices, fees,
          taxes, duties and charges. The amount payable does not include any
          tips or gratuities, personal insurance, passport or visa fees that may
          be required, or fees that are charged by your bank or credit or debit
          card company. The amount payable will be charged in the currency
          presented to you. If you choose to pay in a currency different from
          what is stated, a foreign currency conversion based on the prevailing
          exchange rate at the time the contract is concluded may be applied,
          incurring a conversion charge.
        </p>
        <p>
          <strong>6.3 </strong>For your payment to be processed, you are
          required to provide us with information regarding your credit card or
          other payment instruments. You represent and warrant that such
          information is true and accurate and that you are authorised to use
          the chosen payment instrument. You also represent and warrant that you
          will update your payment information if any changes should occur,
          including but not limited to changes to your billing address or expiry
          of a credit card. Notwithstanding your right to choose to pay in a
          currency different from what is stated in 2 above, there may be a
          difference between the amount displayed on our Platforms and the final
          amount charged to your bank account or credit or debit card statement.
          This is because the amount that is processed may have its currency
          converted by your bank or credit or debit card company and may include
          additional charges imposed by your bank or credit or debit card
          company.
        </p>
        <p />
        <p>
          <strong>7. Cancellations &amp; refunds</strong>
        </p>
        <p>
          <strong>7.1 </strong>Each Service will have a stipulated cancellation
          period and policy, which governs the terms of your cancellation and
          any refunds applicable. We have a{' '}
          <Link to="/cancellation-policy">General Cancellation Policy</Link>,
          but cancellation periods and policies may vary depending on the
          Service. If a different cancellation period and policy applies to your
          Service, this will be notified to you at the time of booking. You may
          cancel a booking by visiting any of our Platforms or by contacting
          Traversel customer service. Before completing any booking
          cancellation, you will be notified of the total refund amount you will
          receive, if any. Cancellations or refunds will no longer be available
          once the booked Service has commenced. If your booking is denied or
          cancelled by an Experience Provider, we will notify you as soon as
          reasonably practicable, and we will refund you in full.
        </p>
        <p>
          <strong>7.2 </strong>Any refunds that we make to you will be via the
          same payment method and in the same currency that was used for the
          booking. Traversel makes no guarantee as to when the refund will be
          completed, as that will depend on your bank or credit or debit card
          company’s processing speed.
        </p>
        <p />
        <p>
          <strong>8. What rules do I need to follow?</strong>
        </p>
        <p>
          <strong>8.1 </strong>Our Platforms are the property of Traversel and
          our licensors. To this end, we grant you a non-exclusive,
          non-transferable and limited license to access our Platforms to enjoy
          personal, non-commercial use of our Services per these Terms. You
          represent and warrant to us that your access to, and use of, our
          Platforms is intended only for personal, non-commercial use.
        </p>
        <p>
          <strong>8.2 </strong>You will not use the Platforms for any purpose
          that is unlawful or prohibited by these Terms, or for any other
          purpose not reasonably intended by us. By way of example, and not as a
          limitation, you agree not to use our Platforms to:
        </p>
        <p>(a) resell any products or services obtained via our Platforms;</p>
        <p>
          (b) make false, fraudulent or speculative bookings on our Platforms;
        </p>
        <p>
          (c) pass off or attempt to pass off our Platforms as the product of
          anyone other than Traversel, including removing, altering or replacing
          any notices of authorship, trademarks, business names, logos or other
          designations of origin;
        </p>
        <p>
          (d) interfere with or disrupt our Platforms or servers or networks
          connected to our Platforms, including running or activating processes
          on our Platforms that interfere with their proper working or place an
          unreasonable load on our Platforms’ infrastructure;
        </p>
        <p>
          (e) violate the security of any network, including cracking passwords
          or encryption codes, transferring or storing any illegal material, or
          otherwise obtaining the password, account or private information from
          any other user of our Platforms;
        </p>
        <p>
          (f) obtain or attempt to gain unauthorised access, via whatever means,
          to any of Traversel’s systems;
        </p>
        <p>
          (g) disassemble, reverse engineer, decompile or modify any software or
          application contained in or available on our Platforms in whole or in
          part, or otherwise attempt to obtain or access the source code of our
          Platforms;
        </p>
        <p>
          (h) use manual or automated software or processes to extract, export
          or otherwise scrape any material displayed on our Platforms, including
          text, graphics, photographs, images, illustrations, audio, video,
          data, ratings, reviews and other information (&quot;
          <strong>Content</strong>&quot;). Such manual or automated software or
          processes include data mining, scraping, spidering, indexing, storing
          or rehosting the content outside our Platforms;
        </p>
        <p>
          (i) use manual or automated software or procedures to create
          derivative works based on the material in our Platforms;
        </p>
        <p>(j) abuse, harass, threaten, impersonate or intimidate anyone;</p>
        <p>
          (k) post or transmit, or cause to be posted or transmitted, any
          material that is libellous, defamatory, obscene, pornographic,
          abusive, offensive or profane;
        </p>
        <p>
          (l) post or transmit, or cause to be posted or transmitted, malicious
          content including malware, Trojan horses, or viruses, or otherwise
          interfere with any user’s access to our Platforms;
        </p>
        <p>
          (m) post or transmit, or cause to be posted or transmitted,
          unsolicited mass mailings or other forms of spam, including junk mail
          or chain letters;
        </p>
        <p>
          (n) infringe any rights of any third party, including intellectual
          property rights or proprietary rights; and
        </p>
        <p>(o) violate any applicable law, statute, ordinance or regulation.</p>
        <p>
          <strong>8.3 </strong>We reserve the right to investigate and take
          appropriate legal action against anyone who, in our opinion, violates
          these provisions, including reporting them to the relevant law
          enforcement authorities.
        </p>
        <p />
        <p>
          <strong>9. Content and availability of our platforms</strong>
        </p>
        <p>
          <strong>9.1 </strong>We have compiled all content displayed on our
          Platforms in good faith. We may, at our sole discretion and at any
          time, add, remove or modify any Content on our Platforms without any
          prior notice or liability, including but not limited to altering their
          presentation, substance or functionality.
        </p>
        <p>
          <strong>9.2 </strong>We make no guarantees as to the authenticity,
          accuracy or completeness of any Content on our Platforms, including
          content provided by other users or our Experience Providers. We also
          make no guarantees that the content available on our Platforms has not
          been altered through technical defects or by unauthorised third
          parties. All content accessed by you using our Platforms is at your
          own risk, and you will be solely responsible for any damage or loss to
          any party resulting from there. Under no circumstance shall Traversel
          be liable in any way for any loss or damage of any kind incurred in
          connection with the use of or exposure to any Content accessed on our
          Platforms, including but not limited to any errors or omissions, or,
          any additions, removals, or modifications that we make on our
          Platforms. We make no guarantee that our Platforms will always be
          available or uninterrupted. We will not be liable to you for any loss
          or damage resulting from any unavailability or interruption of our
          Platforms or Services.
        </p>
        <p />
        <p>
          <strong>10. Privacy policy</strong>
        </p>
        <p>
          <strong>10.1 </strong>For information regarding our treatment of your
          personal data, please review our current{' '}
          <Link to="/privacy-policy">Privacy Policy</Link>.
        </p>
        <p />
        <p>
          <strong>11. Intellectual property</strong>
        </p>
        <p>
          <strong>11.1 </strong>All intellectual property rights subsisting in
          respect of our Platforms are owned by or licensed to Traversel. You
          will abide by all copyright notices, trademark rules, information, and
          restrictions contained in any Content accessed through our Platform.
          You will not acquire any right, title or interest in or to the
          intellectual property rights except for the limited licence to use our
          Platforms and our Services granted to you under these Terms.
          Traversel’s trade or service marks may not be copied, imitated or
          used, in whole or in part, without prior written permission. Nothing
          in these Terms should be construed as granting, by implication,
          estoppel or otherwise, any license or right to use any of Traversel’s
          trade or service marks.
        </p>
        <p>
          <strong>11.2 </strong>Other company, product, and service names and
          logos used and displayed via our Platforms may be trademarks or
          service marks of their respective owners who may or may not be
          endorsed by, or affiliated with, or connected to Traversel.
        </p>
        <p />
        <p>
          <strong>12. Disclaimers &amp; Limitations of liability</strong>
        </p>
        <p>
          <strong>12.1 </strong>Traversel has no special relationship with or
          fiduciary duty to you for accessing and using our Platforms, our
          Content and our Services. We have no control over, and no obligation
          to take any action regarding:
        </p>
        <p>(a) which users gain access to our Platforms;</p>
        <p>(b) what content you access via our Platforms;</p>
        <p>(c) what effects our Content may have on you;</p>
        <p>(d) how you may interpret or use our Content; and</p>
        <p>
          (e) what actions you may take as a result of having been exposed to
          the Content.
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>12.2 </strong>We are also not liable for any costs associated
          with your network provider, who may charge you for accessing their
          connection services to access and use our Platform. We are also not
          responsible for the availability and quality of your telecommunication
          reception when accessing or using our Platforms or Services.
        </p>
        <p>
          <strong>12.3 </strong>The Platforms, including all Services and
          Content therein, are provided on an &quot;as is&quot; and &quot;as
          available&quot; basis. To the maximum extent permitted by applicable
          laws and regulations, we disclaim any warranties (express, implied or
          otherwise), including without limitation, warranties of suitability,
          merchantability, safety, non-infringement, fitness for a particular
          purpose, that our Platforms are free from interruptions, errors or
          other inaccuracies, whether arising by a course of dealing, usage,
          trade practice or course of performance.
        </p>
        <p>
          <strong>12.4 </strong>Without prejudice to the generality of clause 12
          and the maximum extent permitted by applicable laws and regulations,
          Traversel disclaims all liability arising out of or in connection
          with:
        </p>
        <p>
          (a) errors or inaccuracies on our Platforms, including without
          limitation, errors in descriptions, pricing, booking availability,
          photographs, features, inclusions and exclusions, reviews and ratings
          and Traversel reserves the right to correct any inaccuracies in
          booking availability and errors in pricing on our Platforms at all
          times;
        </p>
        <p>
          (b) legality, decency or propriety of any material contained or
          accessed through our Platforms, including content on other websites
          that we may direct you to;
        </p>
        <p>
          (c) any advertising material submitted by third parties and displayed
          on our Platforms, including but without limitation, any error,
          omission or inaccuracy;
        </p>
        <p>
          (d) any information shown, and the suggestions or recommendations
          made, about the Services or Content on our Platforms, for any purpose;
          and
        </p>
        <p>
          (e) any viruses or other harmful components contained in email that we
          may send.
        </p>
        <p>
          <strong>12.5 </strong>To the maximum extent permitted by applicable
          laws and regulations, Traversel, its parents, subsidiaries,
          affiliates, officers, directors, and employees will not be liable for
          any:
        </p>
        <p>
          (a) loss of actual or anticipated income (whether direct or indirect);
        </p>
        <p>
          (b) loss of actual or anticipated profits (whether direct or
          indirect);
        </p>
        <p>
          (c) loss of contracts or business (whether direct or indirect); or
        </p>
        <p>
          (d) special, indirect or consequential loss or damage of any kind
          howsoever arising under or in connection with these Terms, even if we
          have been advised of the possibility of such damages. This provision
          applies regardless of whether the damages are based in contract, tort
          (including negligence), breach of statutory duty or otherwise,
          including under the indemnity obligations under these Terms.
        </p>
        <p>
          <strong>12.5.2 </strong>You acknowledge and agree that Traversel is
          not a party to any transaction concerning the Services provided by any
          Experience Provider or other third party and that we have no control
          over or responsibility whatsoever for any arrangements you make with
          them as a result of your use of our Platforms or Services. You also
          acknowledge and agree that if there is a dispute between you and any
          Experience Provider or other third party, Traversel is under no
          obligation to become involved. Traversel disclaims all liability
          arising out of or in connection with any loss or damage you incur with
          any Experience Provider or other third party.
        </p>
        <p>
          <strong>12.6 </strong>If Traversel is found liable for any loss or
          damage that arises out of or is in any way connected with your use of
          our Platforms or Services, or your booking or use of products or
          services made available through our Platforms, then Traversel’s
          liabilities will in no event exceed the total amount payable for the
          booking giving rise to the claim.
        </p>
        <p />
        <p>
          <strong>13. Indemnity</strong>
        </p>
        <p>
          <strong>13.1 </strong>You will defend, indemnify and hold Traversel,
          its parents, subsidiaries, affiliates, officers, directors, and
          employees harmless from all damages, liabilities, settlements, costs
          and attorney’s fees, claims or demands made by any third party arising
          out of or related to:
        </p>
        <p>(a) your access to any of our Platforms;</p>
        <p>(b) your use of any of our Services;</p>
        <p>
          (c) your booking or use of any products and services obtained through
          our Platforms;
        </p>
        <p>(d) your negligence or willful misconduct;</p>
        <p>(e) any violation by you of these Terms;</p>
        <p>
          (f) any breach by you of any applicable law, statute, ordinance or
          regulation;
        </p>
        <p>
          (g) any infringement or misappropriation by you, or by any other
          person using your Traversel account, of the rights of a third party,
          including without limitation any intellectual property rights or
          rights of publicity or privacy, (the &quot;
          <strong>Third Party Claims</strong>&quot;).
        </p>
        <p>
          <strong>13.2 </strong>We may, if necessary and at our sole discretion,
          participate in defence of any claim or action and any negotiations for
          settlement arising out of or in connection with any Third Party Claim.
          You will not make any settlement that may adversely affect the rights
          or obligations of Traversel without our prior written approval. We
          reserve the right, at our expense and upon notice to you, to assume
          exclusive defence and control of any claim or action.
        </p>
        <p />
        <p>
          <strong>14. Interactions with third parties</strong>
        </p>
        <p>
          <strong>14.1 </strong>Our Platforms may contain links to, and our
          Services may be accessible via, third party websites or services
          (&quot;<strong>Third Party Websites</strong>&quot;) that are not
          owned, controlled or maintained by Traversel. Links to Third-Party
          Websites are provided as a convenience to you as a user of our
          Platforms and does not imply the endorsement by us of any information,
          products, advertising or other materials that can be found on those
          Third Party Websites. Traversel will not and cannot monitor, verify,
          censor or edit the Content of any Third Party Website. Traversel
          disclaims all liability for any loss, damages or injury arising out of
          or in connection with the content, accuracy, privacy policies, or
          practices of or opinions expressed in any Third Party Websites.
        </p>
        <p>
          <strong>14.2 </strong>When accessing these Third Party Websites, you
          do so at your own risk. You represent and warrant that you have read
          and agree to be bound by all applicable policies of these Third-Party
          Websites, whether relating to your use of our Services or otherwise
          and that you will act following those policies, in addition to your
          obligations under these Terms. Your interactions with these third
          parties, including but not limited to the purchasing of goods or
          services, and any other terms, conditions, warranties or
          representations associated with such dealings, are solely between you
          and the third parties. You should conduct whatever investigation you
          feel necessary or appropriate before proceeding with any online or
          offline transaction with any of these third parties.
        </p>
        <p>
          <strong>14.3 </strong>Any third party that wishes to establish links
          to our Platforms should notify Traversel of their intention before
          doing so. Traversel may deny permission for any such links to our
          Platforms. If Traversel gives its permission for any such links,
          Traversel is not under any obligation to establish reciprocal links
          with the third party.
        </p>
        <p />
        <p>
          <strong>15. Termination & suspension</strong>
        </p>
        <p>
          <strong>15.1 </strong>These Terms shall remain in full force and
          effect while you use our Platforms or Services. Traversel may modify,
          suspend or terminate any access to the Platforms or Services,
          including your Traversel Account, at any time and for any reason,
          without any notice or incurring any liability to you or any third
          party. If these Terms are terminated as a result of your breach,
          Traversel reserves the right to cancel any outstanding bookings made
          before said termination, without refund or liability to you. All
          provisions of these Terms which, by their nature, should survive
          termination, shall survive termination, including but not limited to
          ownership provisions, warranty disclaimers, and limitations of
          liability.
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>16. Changes</strong>
        </p>
        <p>
          <strong>16.1 </strong>Unless otherwise provided in these Terms,
          Traversel reserves the right, at its sole discretion and to the
          maximum extent permitted by applicable laws and requirements, to
          change or modify any part of these Terms at any time and we will give
          you reasonable notice of any material changes. Any changes or
          modification will supersede the previous version, and any additional
          terms applicable to our Services that may be posted on the page
          relating to such Services from time to time. You are responsible for
          reviewing the most current version of these Terms each time you visit
          our Platforms. Your continued use of our Platforms after such changes
          or modifications constitutes your acceptance of the revised Terms. The
          updated Terms will supersede all previous versions of these Terms. If
          you do not agree to abide by the amended Terms, do not use or access
          or continue to use or access our Platforms.
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>17. Miscellaneous</strong>
        </p>
        <p>
          <strong>17.1 </strong>These Terms (and any disputes arising out of or
          in connection with these Terms, including without limitation any
          alleged breach, or challenge to the validity or enforceability, of
          these Terms or any provision thereof) will be subject to the laws of
          New Zealand. Any disputes arising out of or in connection with these
          Terms shall be referred to the courts of New Zealand, which will have
          exclusive jurisdiction in case of any dispute.
        </p>
        <p>
          <strong>17.2 </strong>Traversel shall not be liable for any delay or
          non-performance of its obligations under these Terms to the extent
          that it arises from any act or conditions beyond its reasonable
          control, including but not limited to: any extremely severe weather,
          landslide, storm, lightning, fire, subsidence, epidemic, earthquake,
          flood, an act of God, an outbreak of military hostilities (whether or
          not war is declared, expropriation by governmental authorities,
          changes in laws and regulations, failure of third parties (other than
          subcontractors), embargo, riot, sabotage, strike, demonstration,
          explosion or civil commotion.
        </p>
        <p>
          <strong>17.3 </strong>These Terms and any other documents expressly
          referred to in these Terms, as may be amended from time to time,
          constitute the entire agreement and understanding between Traversel
          and you concerning the subject matter of these Terms and supersedes
          any previous agreement or understanding between Traversel and yourself
          about such subject matter.
        </p>
        <p>
          <strong>17.4 </strong>Traversel’s failure to enforce any part of these
          Terms shall not be considered to be a waiver of them or a waiver of
          the right to enforce them subsequently. The invalidity or
          unenforceability of any part of these Terms shall not affect the
          validity or enforceability of the remaining provisions. Where a court
          of competent jurisdiction holds a provision to be invalid or
          unenforceable, whether in whole or in part, that whole or part of the
          provision shall be limited or eliminated to the minimum extent
          necessary so that these Terms shall otherwise remain in full force and
          effect and enforceable.
        </p>
        <p>
          <strong>17.5 </strong> A person who is not a party to these Terms
          shall have no right to rely upon or enforce any term of these Terms.
          These Terms are not assignable, transferable or sub-licensable by you
          except with Traversel’s prior written consent. Traversel may transfer,
          assign or delegate these Terms and its rights and obligations without
          prior notice to you.
        </p>
        <p>
          <strong>17.6 </strong>While these Terms have been translated into the
          local language (as applicable), the translation is provided for your
          convenience only, and the English language version of these Terms will
          govern your relationship with us. In the event of any inconsistencies
          between the English and local language versions, the English version
          shall prevail to the extent permitted by law and the local language
          version will be deemed to be amended to conform with and to make the
          relevant local law version consistent with the corresponding English
          version.
        </p>
        <p />
        <p>
          <strong>Contact</strong>
        </p>
        <p>
          <strong>18.1 </strong>If you have any questions, complaints, or claims
          concerning our Platforms or Services, you may contact us at
          feedback@traversel.com .
        </p>
      </ContentContainer>
    </Default>
  )
}

export default TermsOfUse
