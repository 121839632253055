/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react'

export function useWindowSize() {
  const isClient = typeof window === 'object'

  function getSize() {
    return {
      width: window?.innerWidth,
      height: window?.innerHeight,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize())

  useEffect(() => {
    if (!isClient) {
      return undefined
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [windowSize, isClient])

  return windowSize
}
