import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'react-grid-system'
import { useParams } from 'react-router-dom'
import LocationHeader from '../components/LocationHeader'
// import { useParams } from 'react-router-dom'
import Default from '../layouts/Default'
import { h3Black } from '../styles/fonts'
import ContentContainer from '../components/ContentContainer'
import ProductGrid from '../components/ProductGrid'
import { useProductSate } from '../hooks/productContext'
import { getRegionByUrlValue } from '../util/regions'
import FourOhFour from './404'

const AttractionSection = styled.section`
  padding: 30px 0;
  background: #fff;
`

const Heading2 = styled.h2`
  ${h3Black}
  margin: 0 0 28px;
`

const Location: React.FC = () => {
  const productState = useProductSate()
  const { region } = useParams<{ country?: string; region?: string }>()
  const selected = getRegionByUrlValue(region || '')
  const regionCode = selected?.[0]
  const products =
    (regionCode && productState.productsByRegion[regionCode]) || []

  if (!selected) return <FourOhFour />
  return (
    <Default>
      <LocationHeader />
      <AttractionSection>
        <ContentContainer>
          <Row>
            <Col>
              <Heading2>Experience {selected?.[1] || ''}</Heading2>
            </Col>
          </Row>
          <ProductGrid products={products} isLoading={productState.isLoading} />
        </ContentContainer>
      </AttractionSection>
    </Default>
  )
}

export default Location
