import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Cookies, { CookieAttributes } from 'js-cookie'

import { breakpointUp } from '../styles/media'
import { mx, px, py } from '../styles/spacing'

import Button from './Button'

const CookieWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background: #000;
  color: #fff;
  text-align: center;
  ${px(3)}
  ${py(3)}

  a {
    color: #fff;
  }
`

const AcceptButton = styled(Button)`
  ${mx(2)}
`

const Text = styled.p`
  ${breakpointUp('md', `display: inline-block;`)}
`

const CookieConsent: React.FC = () => {
  const cookieName = 'tsl-cookie-consent'
  const [visible, setIsVisible] = React.useState(false)

  React.useEffect(() => {
    // get the cookie value
    const cookieValue = Cookies.get(cookieName)

    // if it's undefined it means it hasn't been set yet, so show it
    if (cookieValue === undefined) {
      setIsVisible(true)
    }
  }, [])

  const onAccept = () => {
    const cookieOptions: CookieAttributes = {
      expires: 365,
      sameSite: 'lax',
      secure: window.location.protocol === 'https:',
    }

    // set the regular cookie
    Cookies.set(cookieName, 'true', cookieOptions)
    setIsVisible(false)
  }

  if (!visible) return null
  return (
    <CookieWrapper>
      <Text>
        This site uses cookies. By continuing to browse the site, you are
        agreeing to our <Link to="/privacy-policy">use of cookies</Link>
      </Text>
      <AcceptButton buttonStyle="tertiary" onclick={onAccept}>
        OK
      </AcceptButton>
    </CookieWrapper>
  )
}

export default CookieConsent
