import { css } from 'styled-components'

import { labelLarge } from './fonts'
import { breakpointUp } from './media'

export const shadowCol = css`
  position: relative;

  @media (min-width: 768px) {
    padding-left: 150px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 10px;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
    }
  }
`

export const checkoutFormFooter = css`
  ${labelLarge}
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};

  ${breakpointUp(
    'md',
    `
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  `,
  )}
`
