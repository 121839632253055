import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  max-width: 1260px;
  padding: 0 15px;
  margin: auto;
  @media only screen and (min-width: 768px) {
    .detailsColumn {
      border-left: 1px solid black;
    }
  }
`

type Props = {
  className?: string
}
const ContentContainer: React.FC<Props> = ({ children, className }) => {
  return <Container className={className}>{children}</Container>
}

export default ContentContainer
