import React from 'react'
import { Tab, TabPanel, Tabs } from 'react-tabs'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import TabList from '../components/TabList'
import BookingDetails from '../components/checkout/BookingDetails'
import Payment from '../components/checkout/Payment'
import { ConfirmedCartItem, BillingDetails } from '../types'
import storage from '../util/storage'
import { breakpointUp } from '../styles/media'
import cartContext from '../hooks/cartContext'
import BillingAndContact from '../components/checkout/BillingAndContact'

const initialBillingValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
}

const CloseCheckout: React.FC<{ className?: string }> = ({ className }) => {
  const cartState = cartContext.useCartState()
  const item = cartState.cart[0]
  const link = item
    ? `/attraction/${item.attractionId}/product/${item.productId}`
    : '/'
  return (
    <Link to={link} className={className}>
      <FontAwesomeIcon icon={faTimes} />
    </Link>
  )
}

const StyledCloseCheckout = styled(CloseCheckout)`
  position: absolute;
  top: -25px;
  color: #fff;
  text-decoration: none;
  right: 0px;
  padding: 0 15px;

  .react-tabs__tab-list {
    margin-right: 20px;
  }

  ${breakpointUp(
    'md',
    `
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  `,
  )}
`

const CheckoutWrap = styled.div`
  padding-top: 30px;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 30px;
    background: ${({ theme }) => theme.colors.primary};
    opacity: 0.9;
  }

  ${breakpointUp('md', 'padding-top: 0;')}
`

const localProductKey = 'tsl-checkout-cart'
const localBillingKey = 'tsl-checkout-billing'

const Checkout: React.FC = () => {
  const localProduct =
    storage.getItem<ConfirmedCartItem[]>(localProductKey, true) || []
  const localBilling = storage.getItem<BillingDetails>(
    localBillingKey,
    true,
  ) || {
    ...initialBillingValues,
  }
  const [billingDetails, setBillingDetails] = React.useState<BillingDetails>(
    localBilling,
  )
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0)
  const [confirmedItems, setConfirmedItems] = React.useState<
    ConfirmedCartItem[]
  >(localProduct)

  const onTabSelect = (idx: number) => {
    if (idx < selectedTabIndex) setSelectedTabIndex(idx)
  }

  const onProductConfirm = (items: ConfirmedCartItem[]) => {
    setConfirmedItems(items)
    setSelectedTabIndex(1)
    storage.setItem(localProductKey, items)
  }

  const onBillingConfirm = (
    items: ConfirmedCartItem[],
    updatedBillingDetails: BillingDetails,
  ) => {
    setConfirmedItems(items)
    setBillingDetails(updatedBillingDetails)
    setSelectedTabIndex(2)
    storage.setItem(localProductKey, items)
    storage.setItem(localBillingKey, updatedBillingDetails)
  }

  // cleanup when the component dismounts
  React.useEffect(() => {
    return () => {
      storage.removeItem(localProductKey)
      storage.removeItem(localBillingKey)
    }
  }, [])

  return (
    <CheckoutWrap>
      <Tabs selectedIndex={selectedTabIndex} onSelect={onTabSelect}>
        <TabList rightContent={<StyledCloseCheckout />}>
          <Tab>Booking Details</Tab>
          <Tab disabled={selectedTabIndex < 1}>Billing &amp; Contact</Tab>
          <Tab disabled={selectedTabIndex < 1}>Payment</Tab>
        </TabList>
        <TabPanel>
          <BookingDetails
            onConfirm={onProductConfirm}
            currentItems={confirmedItems}
            currentBilling={billingDetails}
          />
        </TabPanel>
        <TabPanel>
          <BillingAndContact
            items={confirmedItems}
            currentBilling={billingDetails}
            onConfirm={onBillingConfirm}
          />
        </TabPanel>
        <TabPanel>
          <Payment billingDetails={billingDetails} items={confirmedItems} />
        </TabPanel>
      </Tabs>
    </CheckoutWrap>
  )
}

export default Checkout
