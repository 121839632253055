import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-grid-system'
import { Tab, TabPanel, Tabs } from 'react-tabs'
import { useParams, useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInfoCircle,
  faImages,
  faTasks,
  faMapMarked,
} from '@fortawesome/free-solid-svg-icons'
import ReactMarkdown from 'react-markdown'

import Default from '../layouts/Default'
import { Product } from '../types'
import { fontHeading } from '../styles/fonts'
import Button from '../components/Button'
import TabList from '../components/TabList'
import ContentContainer from '../components/ContentContainer'
import cartContext from '../hooks/cartContext'
import Loading from '../components/Loading'
import { getProduct } from '../api'
import ProductGallery from '../components/ProductGallery'
import GMap from '../components/GoogleMap'
import { useWindowSize } from '../hooks/useWindowSize'
import { addCloudinaryArgs } from '../util/images'
import { getDiscountedProductIds } from '../util/pricing'

const OnSaleText = styled.span`
  background: ${(props) => props.theme.colors.tertiary};
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  left: 0;
  top: 65px;
  padding: 5px 15px;
  text-transform: uppercase;
  z-index: 10;
  pointer-events: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 17px 10px 0 0;
    border-color: ${(props) => props.theme.colors.tertiary} transparent
      transparent transparent;
  }

  &::after {
    top: auto;
    bottom: 0;
    border-width: 17px 0 0 10px;
    border-color: transparent transparent transparent
      ${(props) => props.theme.colors.tertiary};
  }

  @media only screen and (max-width: 665px) {
    top: 20px;
  }
`

const ImageWrap = styled.div`
  position: relative;
  padding: 1px;
  background: #ccc;

  @media only screen and (max-width: 665px) {
    display: flex;
    justify-content: center;
  }
`

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ProductHeadingWrap = styled.div`
  margin: 65px 65px 45px auto;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  position: relative;
  width: 515px;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 665px) {
    display: flex;
    justify-content: center;
    margin: 65px 0px;
  }
`

const HeadingContent = styled.div`
  padding: 20px;
  min-height: 380px;
  flex-basis: 100%;
`

const Location = styled.div`
  ${fontHeading}
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 70px;
  @media only screen and (max-width: 450px) {
    font-size: 14px;
  }
`

const Title = styled.h1`
  ${fontHeading}
  font-size: 60px;
  font-weight: bold;
  line-height: 54px;
  margin: 0 0 20px;
  @media only screen and (max-width: 450px) {
    font-size: 35px;
  }
`

const ButtonRow = styled.div`
  ${fontHeading}
  align-self: flex-end;
  flex-basis: 100%;
  border: 5px solid #000;
  background: #000;
`

const Price = styled.div`
  font-size: 36px;
  font-weight: bold;
  @media only screen and (max-width: 450px) {
    font-size: 25px;
  }
`

const PriceCol = styled.div`
  padding: 10px 0 10px 20px;
`

const BuyButton = styled(Button)`
  height: 100%;
  width: 100%;
  text-transform: uppercase;
`

const SubTitle = styled.div`
  font-size: 22px;
  font-weight: 700;
`

const ProductDetails = styled.div`
  margin: 35px 20px;
  .detailsRow {
    display: grid;
    grid-template-columns: 80px auto;
    .title {
      font-weight: 600;
      font-size: 13px;
    }
    .details {
      font-size: 13px;
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (max-width: 767px) {
    margin: 35px 60px;
  }
`

const Hightlights = styled.div`
  margin: 35px 20px;
  .title {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 22px;
  }
  .highlight_li {
    padding-left: 8px;
    font-size: 11px;
    font-weight: 300;
    display: flex;
    align-items: center;
    .li_point {
      height: 2px;
      width: 2px;
      background-color: black;
      margin-right: 9px;
    }
  }
  li {
    list-style-type: none;
  }
  @media only screen and (max-width: 767px) {
    margin: 35px 60px;
    .highlight_li {
      font-size: 14px;
    }
  }
`

const Certifications = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .certification {
    width: 33%;
  }

  .certification__inner {
    padding: 10px;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 100%;
  }
`

const MapWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`

const ProductView: React.FC = () => {
  const { attractionId, productId } = useParams<{
    attractionId: string
    productId: string
  }>()
  const cartDispatch = cartContext.useCartDispatch()
  const [product, setProduct] = React.useState<null | Product>(null)
  const history = useHistory()
  const windowSize = useWindowSize()

  React.useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productData = await getProduct(attractionId, productId)
        if (!productData.isActive) throw new Error('Product is not active')
        setProduct(productData)
      } catch (e) {
        console.log(e)
        alert('Could not load product')
      }
    }

    fetchProduct()
  }, [attractionId, productId])

  const onBookNow = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!product) return

    cartDispatch({ action: 'ClearCart' })
    cartDispatch({
      action: 'AddToCart',
      item: {
        attractionId: product.attractionId,
        attraction: {
          title: product.attractionTitle,
          city: product.city,
        },
        productId: product.id,
        productCode: product.code,
        title: product.title,
        displayPrice: product.displayPrice,
        currency: product.currency,
        image: product.image,
        bookingFields: product.bookingFields,
        pickup: product.pickup,
        timeZone: product.timeZone,
        min: product.quantityRequiredMin,
        max: product.quantityRequiredMax,
        isFreeSell: product.isFreeSell,
      },
    })

    // go to checkout
    history.push('/checkout')
  }

  const productDurationNum = parseInt(product?.duration || '0', 10)
  const productDurationHours = Math.floor(productDurationNum / 60)
  const productDurationMins = productDurationNum % 60

  const srcSetSizeMin = 320
  const srcSetSizeMax = 4000
  const srcSet = []
  for (let i = srcSetSizeMin; i <= srcSetSizeMax; i += 200) {
    const size = addCloudinaryArgs(product?.image?.url || '', `w_${i}`)
    srcSet.push(`${size} ${i}w`)
  }

  return (
    <Default>
      {product === null && <Loading />}
      {product !== null && (
        <div>
          <ImageWrap>
            {getDiscountedProductIds().includes(product.id) && (
              <OnSaleText>On Sale!</OnSaleText>
            )}
            {product.image.url && (
              <Image
                src={product.image.url}
                alt={product.image.alt}
                srcSet={srcSet.join(', ')}
              />
            )}
            <ProductHeadingWrap>
              <HeadingContent>
                <Location>
                  {product.location} - {product.type}
                </Location>
                <Title>{product.title}</Title>
              </HeadingContent>
              <ButtonRow>
                <Row nogutter>
                  <Col xs={12} md={6}>
                    <PriceCol>
                      from
                      <Price>
                        {product.currency} {product.displayPrice}
                      </Price>
                      per person
                    </PriceCol>
                  </Col>
                  <Col xs={12} md={6}>
                    {product.readyForBooking && (
                      <BuyButton buttonStyle="tertiary" onclick={onBookNow}>
                        Book Now
                      </BuyButton>
                    )}
                    {!product.readyForBooking && (
                      <BuyButton buttonStyle="tertiary">Unavailable</BuyButton>
                    )}
                  </Col>
                </Row>
              </ButtonRow>
            </ProductHeadingWrap>
          </ImageWrap>
          <Tabs>
            {windowSize.width && windowSize.width > 767 ? (
              <TabList>
                <Tab>Overview</Tab>
                <Tab>Gallery</Tab>

                {!!product.needToKnow && <Tab>Need To Know</Tab>}
                <Tab>Location</Tab>
              </TabList>
            ) : (
              <TabList>
                <Tab>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tab>
                <Tab>
                  <FontAwesomeIcon icon={faImages} />
                </Tab>
                <Tab>
                  <FontAwesomeIcon icon={faTasks} />
                </Tab>
                <Tab>
                  <FontAwesomeIcon icon={faMapMarked} />
                </Tab>
              </TabList>
            )}
            <TabPanel>
              <ContentContainer>
                <Row>
                  <Col md={8}>
                    {product.longDescription && (
                      <ReactMarkdown source={product.longDescription} />
                    )}
                    {product.whatToExpect && (
                      <div>
                        <SubTitle>What to expect</SubTitle>
                        <ReactMarkdown source={product.whatToExpect} />
                      </div>
                    )}
                  </Col>
                  <Col md={4} className="detailsColumn">
                    <ProductDetails>
                      <div className="Product__DetailsWrapper">
                        {(productDurationHours || productDurationMins) && (
                          <div className="detailsRow">
                            <span className="title">Duration:</span>
                            <span className="details">
                              {!!productDurationHours && (
                                <span>{productDurationHours}h </span>
                              )}
                              {!!productDurationMins && (
                                <span>{productDurationMins}min</span>
                              )}
                            </span>
                          </div>
                        )}
                        {!!product.goodFor.length && (
                          <div className="detailsRow">
                            <span className="title">Good for:</span>
                            <span className="details">
                              {product.goodFor.join(', ')}
                            </span>
                          </div>
                        )}
                        <div className="detailsRow">
                          <span className="title">Min Age:</span>
                          <span className="details">{product.minAge}</span>
                        </div>
                        <div className="detailsRow">
                          <span className="title">Group size:</span>
                          <span className="details">{product.groupSize}</span>
                        </div>
                      </div>
                    </ProductDetails>
                    <Hightlights>
                      <div className="Product__HighlightsWrapper">
                        <div className="title">Highlights</div>
                        {product.highlights.map((highlight, i) => (
                          <li
                            className="highlight_li"
                            key={`${new Date().getTime() * (i + 3)}`}
                          >
                            <div className="li_point" />
                            {highlight}
                          </li>
                        ))}
                      </div>
                    </Hightlights>
                    <Certifications>
                      {product.certifications.map((cert) => (
                        <div key={cert.url} className="certification">
                          <div className="certification__inner">
                            <img src={cert.url} alt={cert.alt} />
                          </div>
                        </div>
                      ))}
                    </Certifications>
                  </Col>
                </Row>
              </ContentContainer>
            </TabPanel>
            <TabPanel>
              <ContentContainer>
                <ProductGallery productImages={product.images} />
              </ContentContainer>
            </TabPanel>
            {!!product.needToKnow && (
              <TabPanel>
                <ContentContainer>
                  <ReactMarkdown source={product.needToKnow} />
                </ContentContainer>
              </TabPanel>
            )}
            <TabPanel>
              <ContentContainer>
                <MapWrapper>
                  <GMap
                    lat={product.locationCoordinates.lat}
                    lng={product.locationCoordinates.lng}
                  />
                </MapWrapper>
              </ContentContainer>
            </TabPanel>
          </Tabs>
        </div>
      )}
    </Default>
  )
}

export default ProductView
