import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Row, Col } from 'react-grid-system'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { ApiBooking, Product } from '../types'
import { getBooking, cancelBooking, getProduct } from '../api'
import Loading from './Loading'
import { h4Regular, h5Regular } from '../styles/fonts'
import GMap from './GoogleMap'
import Button from './Button'
import { mt } from '../styles/spacing'

const BookingDetailWrap = styled.div`
  .gmap {
    padding-bottom: 0;
    height: 300px;
  }
`

const Title = styled.h2`
  ${h4Regular}
`

const ProductName = styled.h3`
  ${h5Regular}
`

const Divider = styled.hr`
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  border-top: 1px solid #ccc;
`

const RightCol = styled(Col)`
  text-align: right;
`

const LocationText = styled.div`
  font-size: 0.8em;
  margin-bottom: 5px;
`

const ButtonRow = styled.div`
  ${mt(2)}
`

const CancelledBox = styled.div`
  display: flex;
`

const CancelledIconBox = styled.div`
  flex: 0 1 auto;
  background: ${({ theme }) => theme.colors.error};
  color: #fff;
  padding: 1rem;
  display: flex;
  align-items: center;
  font-size: 2rem;
`

const CancelledInfoBox = styled.div`
  flex: 1 1 auto;
  padding: 1rem;
  border: 2px solid #ccc;
  border-left: none;
`

const Cancelled = () => {
  return (
    <CancelledBox>
      <CancelledIconBox>
        <FontAwesomeIcon icon={faExclamationCircle} />
      </CancelledIconBox>
      <CancelledInfoBox>
        <div style={{ marginBottom: '0.5rem' }}>
          <strong>Booking cancelled</strong>
        </div>
        <div>
          <Link to="/cancellation-policy" target="_blank">
            If eligible
          </Link>
          , you will automatically receive a full refund for the cost of the
          experience and don’t need to take any further steps{' '}
        </div>
      </CancelledInfoBox>
    </CancelledBox>
  )
}

const getTitle = (booking: ApiBooking): string => {
  switch (booking.statusText) {
    case 'Booking':
    case 'Held':
    case 'PreBooked':
    case 'Pending': {
      return 'Booking Pending.'
    }
    case 'Booked': {
      return 'Booking Confirmed.'
    }
    default: {
      return `Booking ${booking.statusText}.`
    }
  }
}

type BookingDetailProps = {
  bookingId: string | null
}
const BookingDetail: React.FC<BookingDetailProps> = ({ bookingId }) => {
  const [booking, setBooking] = React.useState<ApiBooking | null>(null)
  const [product, setProduct] = React.useState<Product | null>(null)
  const [error, setError] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [visitDate, setVisitDate] = React.useState(moment())
  const [cancellationState, setCancellationState] = React.useState<
    'none' | 'confirm' | 'error' | 'success' | 'loading'
  >('none')

  React.useEffect(() => {
    const runGetBooking = async () => {
      if (!bookingId) {
        setBooking(null)
        setProduct(null)
        return
      }
      try {
        setIsLoading(true)
        setError(false)
        setProduct(null)
        const bookingResponse = await getBooking(bookingId)
        if (
          bookingResponse.data.data.items[0]?.attractionId &&
          bookingResponse.data.data.items[0]?.products[0]?.productId
        ) {
          const productResponse = await getProduct(
            bookingResponse.data.data.items[0].attractionId,
            bookingResponse.data.data.items[0]?.products[0]?.productId,
          )
          setProduct(productResponse)
        }
        setBooking(bookingResponse.data.data)
        setVisitDate(moment.parseZone(bookingResponse.data.data.visitDateLocal))
        setCancellationState('none')
      } catch (e) {
        console.log('error fetching booking', e)
        setError(true)
      } finally {
        setIsLoading(false)
      }
    }
    runGetBooking()
  }, [bookingId])

  const onCancel = async () => {
    if (
      (cancellationState !== 'none' && cancellationState !== 'confirm') ||
      !bookingId
    )
      return
    try {
      setCancellationState('loading')
      await cancelBooking(bookingId)
      setCancellationState('success')
    } catch (e) {
      console.log('Error cancelling booking')
      setCancellationState('error')
    }
  }

  if (isLoading) return <Loading />
  if (!booking) return null
  return (
    <BookingDetailWrap>
      {!isLoading && error && (
        <div>
          Sorry, there was an error fetching your booking details, please try
          again
        </div>
      )}
      {!isLoading && !error && (
        <div>
          <Title>{getTitle(booking)}</Title>
          {booking.emailAddress &&
            booking.statusText !== 'Cancelled' &&
            booking.statusText !== 'Cancelling' && (
              <p>
                Your booking receipt and confirmation has been emailed to{' '}
                {booking.emailAddress}
              </p>
            )}
          {(booking.statusText === 'Cancelled' ||
            booking.statusText === 'Cancelling') && <Cancelled />}
          <Divider />
          <ProductName>
            {booking.items[0]?.products[0]?.productName}
          </ProductName>
          <Row>
            <Col>Visit Date</Col>
            <RightCol>{visitDate.format('ddd, MMM D, YYYY')}</RightCol>
          </Row>
          <Row>
            <Col>Start time</Col>
            <RightCol>{visitDate.format('hh:mm a')}</RightCol>
          </Row>
          <Divider />
          <Row>
            <Col>
              <strong>Total</strong>
            </Col>
            <RightCol>
              <strong>
                {booking.currencyCode} {booking.totalPrice.toFixed(2)}
              </strong>
            </RightCol>
          </Row>
          <Divider />
          {product && (
            <div>
              <LocationText>
                <strong>Location:</strong> {product.address?.streetAddress},{' '}
                {product.address?.city}
              </LocationText>
              {product.address?.latitude && product.address?.longitude && (
                <GMap
                  lat={product.address.latitude}
                  lng={product.address.longitude}
                />
              )}
            </div>
          )}
          {booking.statusText !== 'Cancelled' &&
            booking.statusText !== 'Cancelling' &&
            visitDate.isAfter(moment()) && (
              <ButtonRow>
                {cancellationState === 'loading' && <Loading />}
                {cancellationState === 'none' && (
                  <Button onclick={() => setCancellationState('confirm')}>
                    Cancel this booking
                  </Button>
                )}
                {cancellationState === 'confirm' && (
                  <Button onclick={onCancel}>Are you sure?</Button>
                )}
                {cancellationState === 'success' && (
                  <div>
                    <Cancelled />
                  </div>
                )}
                {cancellationState === 'error' && (
                  <div>There was an error cancelling your booking.</div>
                )}
              </ButtonRow>
            )}
          <Divider />
          <Row>
            <Col>
              <small>Booking ID</small>
            </Col>
            <RightCol>
              <small>{booking.externalId}</small>
            </RightCol>
          </Row>
        </div>
      )}
    </BookingDetailWrap>
  )
}

export default BookingDetail
