import styled, { css } from 'styled-components'

export const fontHeading = css`
  font-family: ${({ theme }) => theme.fonts.heading};
`

export const fontBase = css`
  font-family: ${({ theme }) => theme.fonts.base};
`

export const h2Black = css`
  ${fontHeading}
  font-weight: 800;
  font-size: 37px;
  line-height: 1.2em;
`

export const h3Black = css`
  ${fontHeading}
  font-weight: 800;
  font-size: 32px;
  line-height: 1.2em;
`

export const h4Regular = css`
  ${fontHeading}
  font-weight: normal;
  font-size: 28px;
  line-height: 1.5em;
`

export const h5Regular = css`
  ${fontHeading}
  font-weight: normal;
  font-size: 23px;
  line-height: 1.5em;
`

export const labelLarge = css`
  ${fontHeading}
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
`

export const labelMedium = css`
  ${fontHeading}
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
  margin-bottom: 27px;
`

export const PageTitle = styled.h1`
  ${h2Black}
`
