import createReducerContextWithProvider from './createContext'
import storageUtils from '../util/storage'

type Action =
  | {
      action: 'LogIn'
      userId: string
    }
  | {
      action: 'LogOut'
    }

type State =
  | {
      loggedIn: false
      userId: null
    }
  | {
      loggedIn: true
      userId: string
    }

const authStateKey = 'tsl-auth-state'
const userIdKey = 'tsl-user-id'

function reducer(state: State, action: Action): State {
  switch (action.action) {
    case 'LogIn': {
      storageUtils.setItem(authStateKey, true)
      storageUtils.setItem(userIdKey, action.userId)
      return {
        ...state,
        loggedIn: true,
        userId: action.userId,
      }
    }
    case 'LogOut': {
      storageUtils.removeItem(authStateKey)
      storageUtils.removeItem(userIdKey)
      storageUtils.removeItem('tsl-refresh-token')
      return {
        ...state,
        loggedIn: false,
        userId: null,
      }
    }
    default: {
      throw new Error('Invalid action')
    }
  }
}

const initialLoggedIn = !!storageUtils.getItem(authStateKey)
const initialUserId = storageUtils.getItem(userIdKey)

const initialState: State =
  initialLoggedIn && initialUserId
    ? {
        loggedIn: true,
        userId: initialUserId,
      }
    : {
        loggedIn: false,
        userId: null,
      }

const {
  Provider: AuthProvider,
  useState: useAuthState,
  useDispatch: useAuthDispatch,
} = createReducerContextWithProvider('auth', reducer, initialState)

export { AuthProvider, useAuthDispatch, useAuthState }
