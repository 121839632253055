import { PriceGroupCode } from '../types'

export const getPriceLabel = (code: PriceGroupCode) => {
  switch (code) {
    case 'I': {
      return 'Infant'
    }
    case 'A': {
      return 'Adult'
    }
    case 'C': {
      return 'Child'
    }
    case 'GA': {
      return 'General Admission'
    }
    case 'S': {
      return 'Senior'
    }
    default: {
      return code
    }
  }
}

export const getDiscountedProductIds = (): string[] => {
  return (process.env.REACT_APP_DISCOUNTED_PRODUCTS || '').split(',')
}
