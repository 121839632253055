import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useParams } from 'react-router-dom'
import Select, { ValueType } from 'react-select'
import styled from 'styled-components'
import { useProductSate } from '../hooks/productContext'
import { fontHeading } from '../styles/fonts'
import {
  getRegionUrlValue,
  regions,
  getRegionByUrlValue,
} from '../util/regions'
import * as T from '../types'
import { breakpointUp } from '../styles/media'

type LocationSelectProps = {
  onChange(location?: T.LocationOption): void
}

const Wrapper = styled.div`
  color: #000;
  ${fontHeading}
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  background: #fff;

  ${breakpointUp('lg', 'font-size: 32px')}

  .location-select__placeholder {
    color: inherit;
  }

  .location-select__control {
    border-radius: 0;
    border: none;
  }

  .location-select__value-container {
    padding: 15px 20px 15px 10px;
  }

  .location-select__indicator-separator {
    display: none;
  }

  .location-select__dropdown-indicator svg {
    display: none;
  }

  .location-select__dropdown-indicator::before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 12px 0 12px;
    border-color: #000 transparent transparent transparent;
    margin: 7px auto 0;
    position: relative;
    display: block;
  }

  .location-select__single-value {
    overflow: visible;
  }
`

const Marker = styled.div`
  color: ${({ theme }) => theme.colors.tertiary};
  padding-left: 10px;
`

const SelectWrapper = styled.div`
  flex-grow: 1;
`

const LocationSelect: React.FC<LocationSelectProps> = ({ onChange }) => {
  const { validRegions } = useProductSate()
  const { region } = useParams<{ country?: string; region?: string }>()

  const selected = getRegionByUrlValue(region || '') || undefined

  const onSelectChange = (val: ValueType<T.LocationOption>) => {
    if (Array.isArray(val) || !val) {
      onChange(undefined)
      return
    }
    onChange(val as T.LocationOption)
  }

  const options: T.LocationOption[] = Object.entries(regions)
    .filter((r) => {
      return validRegions[r[0]]
    })
    .map((r) => {
      return {
        label: `${r[1]}, New Zealand`,
        value: {
          country: 'nz',
          region: getRegionUrlValue(r[1]),
        },
      }
    })

  return (
    <Wrapper>
      <Marker>
        <FontAwesomeIcon icon={faMapMarkerAlt} />
      </Marker>
      <SelectWrapper>
        <Select
          key={region}
          onChange={onSelectChange}
          options={options}
          classNamePrefix="location-select"
          placeholder="Where are you going?"
          defaultValue={
            selected && {
              label: `${selected[1]}, New Zealand`,
              value: {
                country: 'nz',
                region: getRegionUrlValue(selected[1]),
              },
            }
          }
        />
      </SelectWrapper>
    </Wrapper>
  )
}

export default LocationSelect
