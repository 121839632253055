import React from 'react'
import { Row, Col } from 'react-grid-system'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { breakpointUp } from '../styles/media'

import Container from './Container'

const Copyright = styled(Col)`
  font-size: 10px;
  line-height: 15px;
  border-left: 1px solid #fff;
`

const FooterEl = styled.footer`
  background: ${({ theme }) => theme.colors.primary};
  padding: 18px 0;
  color: ${({ theme }) => theme.colors.secondary};
`

const FooterLink = styled(Link)`
  color: #fff;
  font-size: 0.8em;
`

const CopyrightText = styled.div`
  margin-top: 12px;
`

const FooterRight = styled.div`
  margin-top: 20px;

  ${breakpointUp('md', 'margin-top: 0;')}
`

type Props = {
  logo?: null
}

const Footer: React.FC<Props> = ({ logo }) => {
  return (
    <FooterEl>
      <Container>
        <Row align="center">
          <Col xs={12} sm="content">
            {logo === undefined && (
              <img
                src={`${process.env.PUBLIC_URL}/logos/logo-house-of-travel.png`}
                alt="House of Travel Logo"
                width="143"
              />
            )}
          </Col>
          <Col>
            <FooterRight>
              <Row align="center" justify="end">
                <Col xs="content">
                  <img
                    src={`${process.env.PUBLIC_URL}/logos/powered-by-traversel.svg`}
                    alt="Powered by Traversel"
                  />
                </Col>
                <Copyright xs="content">
                  <div>
                    <FooterLink to="/privacy-policy">
                      Privacy &amp; Cookie Policy
                    </FooterLink>
                    <br />
                    <FooterLink to="/terms-of-use">Terms of Use</FooterLink>
                    <br />
                    <FooterLink to="/cancellation-policy">
                      Cancellation Policy
                    </FooterLink>
                    <br />
                    <FooterLink to="/support">Support</FooterLink>
                  </div>
                  <CopyrightText>&copy; 2020 Traversel</CopyrightText>
                </Copyright>
              </Row>
            </FooterRight>
          </Col>
        </Row>
      </Container>
    </FooterEl>
  )
}

export default Footer
