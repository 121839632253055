import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-grid-system'
import { Formik, Form } from 'formik'
import moment from 'moment'
import * as yup from 'yup'
import { Redirect } from 'react-router-dom'

import cartContext from '../../hooks/cartContext'
import ContentContainer from '../ContentContainer'
import IncrementInput from '../forms/Increment'
import { inputMarginSmall } from '../../styles/forms'
import NextButton from './ContinueButton'
import Loading from '../Loading'
import { getAttractionPricing, getAttractionPricings } from '../../api'
import AvailabilitySelect from './AvailabilitySelect'
import { PriceGroupCode, ConfirmedCartItem, BillingDetails } from '../../types'
import ErrorMessage from '../forms/ErrorMessage'
import ErrorFocus from '../forms/ErrorFocus'
import { my } from '../../styles/spacing'
import { breakpointUp } from '../../styles/media'
import { checkoutFormFooter } from '../../styles/misc'
import SectionHeading from './SectionHeading'
import Select from '../forms/Select'
import { getPriceLabel } from '../../util/pricing'
import BookingDate from './BookingDate'

const Increment = styled(IncrementInput)`
  ${inputMarginSmall}
`

const Disabled = styled.div`
  opacity: 0.2;
  pointer-events: none;
`

const PaxContainer = styled.div`
  max-width: 250px;
`

const InputWrap = styled.div`
  margin-bottom: 20px;
`

const FormFooter = styled.div`
  ${checkoutFormFooter}
`

const ColSpacer = styled.div`
  margin-top: 40px;
`

const FooterCol = styled(Col)`
  ${my(3)}
  ${breakpointUp(
    'md',
    `
    ${my(0)}
  `,
  )}
`

const validationSchema = yup.object().shape({
  date: yup.string().nullable().required('Date is required'),
  time: yup.string().nullable().required('Time is required'),
  pickupLocationName: yup.string(),
})

type BookingDetailsProps = {
  onConfirm(items: ConfirmedCartItem[]): void
  currentItems: ConfirmedCartItem[]
  currentBilling: BillingDetails
}

type PricingOption = {
  label: string
  age: string
  price: number
  currencyCode: string
  code: PriceGroupCode
  dob: string
}

const ShouldDisable: React.FC<{ disable: boolean }> = ({
  disable,
  children,
}) => {
  if (disable) return <Disabled>{children}</Disabled>
  return <div>{children}</div>
}

const BookingDetails: React.FC<BookingDetailsProps> = ({
  onConfirm,
  currentItems,
  currentBilling,
}) => {
  const cartState = cartContext.useCartState()
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const [prices, setPrices] = React.useState<PricingOption[]>([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [cartItems, setCartItems] = React.useState<ConfirmedCartItem[]>([])
  const [currentPickup, setCurrentPickup] = React.useState('')

  // get the current item from the cart
  const currentCartItem = cartState.cart[currentIndex]

  // fetch pricing details
  React.useEffect(() => {
    if (!currentCartItem) return
    const runGetPricing = async () => {
      try {
        setIsLoading(true)

        // get the pricings for this attraction
        const pricingsResponse = await getAttractionPricings(
          currentCartItem.attractionId,
        )

        // find the pricing that applies
        const pricingForProduct = pricingsResponse.data.data.items.find(
          (item) =>
            item.name === `ProdOps pricing for ${currentCartItem.productId}`,
        )
        if (!pricingForProduct) throw new Error('No current pricing')

        const pricingId = pricingForProduct.id

        // get this data
        const pricingResponse = await getAttractionPricing(
          currentCartItem.attractionId,
          pricingId,
        )

        // filter out the prices
        const priceOptions: PricingOption[] = pricingResponse.data.data.rules
          .filter((rule) => {
            // take only the rules that have this product in them
            return rule.filter.products.ids
              .map(({ id }) => id)
              .includes(currentCartItem.productId)
          })
          .reduce<PricingOption[]>((prev, rule) => {
            const options = rule.action.prices.map((action) => {
              const priceOption = {
                label: getPriceLabel(rule.ruleGroup),
                age: '',
                price: action.onSaleAmount ?? action.amount,
                currencyCode: pricingResponse.data.data.currency?.code || 'NZD',
                code: rule.ruleGroup,
                dob: moment()
                  .subtract(1, 'day')
                  .subtract(rule.paxAgeMin, 'years')
                  .format('YYYY-MM-DD'),
              }

              // populate the age if it's not adult
              if (rule.ruleGroup !== 'GA') {
                if (rule.paxAgeMax === 99) {
                  priceOption.age = `${rule.paxAgeMin}+ years`
                } else {
                  priceOption.age = `${rule.paxAgeMin} - ${rule.paxAgeMax} years`
                }
              }
              return priceOption
            })
            return [...prev, ...options]
          }, [])
        setPrices(priceOptions)
      } catch (e) {
        console.log('Error loading pricing', e)
        alert('Error loading prices')
      } finally {
        setIsLoading(false)
      }
    }
    runGetPricing()
  }, [currentCartItem])

  // display nothing if it doesn't exist, maybe added by error
  if (!currentCartItem) return <Redirect to="/" />

  if (isLoading) return <Loading />

  type FormValues = {
    date: null | string
    time: null | string
    pax: {
      [key: string]: number
    }
    firstName: string
    lastName: string
    email: string
    phone?: string
    specialRequirements?: string
    pickupLocationName?: string
  }
  const initialValues: FormValues = {
    date: null,
    time: null,
    pax: {},
    phone: '',
    ...currentBilling,
  }

  // populate initial values
  prices.forEach((price, idx) => {
    initialValues.pax[price.code] = idx === 0 ? currentCartItem.min : 0
  })

  // update the initial values with the existing ones from the cart
  const currentItem = currentItems[currentIndex]
  if (currentItem) {
    initialValues.date = moment(currentItem.time).format('YYYY-MM-DD')
    initialValues.time = currentItem.time
    currentItem.pax.forEach((p) => {
      // check if this is a valid pax
      const isValidPax = prices.some((price) => price.code === p.code)
      if (isValidPax) initialValues.pax[p.code] = p.quantity
    })
  }

  const currentPickupItem = (currentCartItem.pickup?.locations || []).find(
    (pickup) => {
      return pickup.locationName === currentPickup
    },
  )

  const hasPax = (values: FormValues) => {
    return !!Object.values(values.pax).reduce<number>(
      (prev, current) => prev + current,
      0,
    )
  }

  const sumPax = (values: FormValues): number => {
    return Object.values(values.pax).reduce<number>(
      (prev, current) => prev + current,
      0,
    )
  }

  const onSubmit = (values: FormValues) => {
    // check for a selected time
    if (!values.time) {
      alert('Please select a time')
      return
    }

    const totalPax = sumPax(values)

    if (totalPax < currentCartItem.min) {
      alert(`Minimum of ${currentCartItem.min} guests is required`)
      return
    }

    if (totalPax > currentCartItem.max) {
      alert(`You may only have a maximum of ${currentCartItem.max} guests`)
      return
    }

    // sort out our pax values
    let totalPrice = 0
    const pax = Object.keys(values.pax).map((key) => {
      // find the price for this code
      const paxPrice = prices.find((price) => {
        return price.code === key
      })

      totalPrice += (paxPrice?.price || 0) * (values.pax[key] || 0)

      return {
        code: key,
        label: paxPrice?.label || '',
        price: paxPrice?.price || 0,
        quantity: values.pax[key],
        dob: paxPrice?.dob || '1980-01-01',
      }
    })

    const pickupLocationParts: string[] = []
    if (currentPickupItem) {
      pickupLocationParts.push(currentPickupItem.locationName || '')
      pickupLocationParts.push(currentPickupItem.address || '')
      if (currentPickupItem.additionalInstructions)
        pickupLocationParts.push(currentPickupItem.additionalInstructions)
      if (currentPickupItem.minutesPrior !== 0)
        pickupLocationParts.push(`${currentPickupItem.minutesPrior} mins prior`)
    }

    // update our items
    const newCartItems = [
      ...cartItems,
      {
        ...currentCartItem,
        time: values.time,
        pax,
        total: totalPrice,
        additionalInfo: [],
        pickupLocationName: values.pickupLocationName || null,
        pickupLocationString: pickupLocationParts.join(', '),
      },
    ]
    setCartItems(newCartItems)

    // if there are more items, show the
    if (cartState.cart.length > currentIndex + 1) {
      setCurrentIndex(currentIndex + 1)
    } else {
      onConfirm(newCartItems)
    }
  }

  if (!cartState.cart.length) return <Redirect to="/" />

  const pickupOptions = (currentCartItem.pickup?.locations || [])
    .filter((location) => {
      return location.locationName !== null
    })
    .map((location) => {
      const label = [location.locationName]
      if (location.address) label.push(location.address)
      return {
        value: location.locationName || '',
        label: label.join(' - '),
      }
    })

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, touched }) => (
          <Form>
            <ErrorFocus />
            <ContentContainer>
              <Row gutterWidth={20}>
                <Col md={4}>
                  <SectionHeading>Guests</SectionHeading>
                  {currentCartItem.min > 1 && (
                    <p>
                      <small>
                        Min booking of {currentCartItem.min} guests is required.
                      </small>
                    </p>
                  )}
                  {sumPax(values) >= currentCartItem.max && (
                    <p>
                      <small>
                        There is a limit of {currentCartItem.max} guests per
                        booking.
                      </small>
                    </p>
                  )}
                  <PaxContainer>
                    {prices.map((price) => (
                      <Increment
                        key={price.code}
                        label={price.label}
                        name={`pax.${price.code}`}
                        min={0}
                        subLabel={`${price.currencyCode} ${price.price}`}
                        desc={price.age && `(${price.age})`}
                        disableIncrement={sumPax(values) >= currentCartItem.max}
                        disableDecrement={sumPax(values) <= currentCartItem.min}
                      />
                    ))}
                  </PaxContainer>
                  {touched.pax && !hasPax(values) && (
                    <ErrorMessage error="Please select at least one guest" />
                  )}
                </Col>
                <Col md={4}>
                  <ShouldDisable disable={!hasPax(values)}>
                    <BookingDate
                      attractionId={cartState.cart[0].attractionId}
                      productId={currentCartItem.productId}
                      quantity={Object.values(values.pax).reduce(
                        (p, c) => p + c,
                        0,
                      )}
                      dob="1991-01-01"
                      timeZone={currentCartItem.timeZone}
                      isFreeSell={currentCartItem.isFreeSell}
                    />
                  </ShouldDisable>
                </Col>
                <Col md={4}>
                  <ColSpacer />
                  <InputWrap>
                    <ShouldDisable disable={!hasPax(values) || !values.date}>
                      <SectionHeading>Time</SectionHeading>
                      <AvailabilitySelect
                        attractionId={cartState.cart[0].attractionId}
                        productId={currentCartItem.productId}
                        quantity={Object.values(values.pax).reduce(
                          (p, c) => p + c,
                          0,
                        )}
                        dob="1991-01-01"
                        visitDate={values.date}
                        name="time"
                        timeZone={currentCartItem.timeZone}
                        isFreeSell={currentCartItem.isFreeSell}
                      />
                    </ShouldDisable>
                  </InputWrap>
                  <ShouldDisable disable={!values.date || !values.time}>
                    {pickupOptions.length > 0 && (
                      <InputWrap>
                        <Select
                          name="pickupLocationName"
                          label="Pickup location"
                          withBorder
                          options={[
                            { value: '', label: 'Not required' },
                            ...pickupOptions,
                          ]}
                          help={currentCartItem?.pickup?.additionalNotes || ''}
                          onChange={(v) => setCurrentPickup(v)}
                        />
                        {currentPickupItem && (
                          <p>
                            <b>{currentPickupItem.locationName}</b>
                            <br />
                            {currentPickupItem.address}
                            <br />
                            {currentPickupItem.additionalInstructions && (
                              <>
                                {currentPickupItem.additionalInstructions}
                                <br />
                              </>
                            )}
                            {currentPickupItem.minutesPrior !== 0 && (
                              <>{currentPickupItem.minutesPrior} mins prior</>
                            )}
                          </p>
                        )}
                      </InputWrap>
                    )}
                  </ShouldDisable>
                </Col>
              </Row>
            </ContentContainer>
            <FormFooter>
              <ContentContainer>
                <Row align="center">
                  <FooterCol>{cartState.cart[0].title}</FooterCol>
                  <FooterCol xs="content">
                    NZD&nbsp;
                    {Object.keys(values.pax)
                      .reduce((prev, paxKey) => {
                        const price = prices.find((p) => p.code === paxKey)
                        if (!price) return prev
                        return prev + price.price * values.pax[paxKey]
                      }, 0)
                      .toFixed(2)}
                  </FooterCol>
                  <FooterCol xs={12} md="content">
                    <NextButton type="submit">Continue</NextButton>
                  </FooterCol>
                </Row>
              </ContentContainer>
            </FormFooter>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default BookingDetails
