import React from 'react'
import { Row, Col } from 'react-grid-system'
import { useField } from 'formik'
import moment from 'moment-timezone'
import styled from 'styled-components'

import Loading from '../Loading'
import { getAvailability } from '../../api'
import { AvailabilityItem } from '../../types'
import Button from '../Button'
import ErrorMessage from '../forms/ErrorMessage'

const Warning = styled.p`
  color: ${({ theme }) => theme.colors.tertiary};
  font-weight: 600;
`

type AvailabilitySelectProps = {
  attractionId: string
  productId: string
  quantity: number
  dob: string
  visitDate: string | null
  name: string
  timeZone: string
  isFreeSell: boolean
}

const AvailabilitySelect: React.FC<AvailabilitySelectProps> = ({
  attractionId,
  productId,
  quantity,
  dob,
  visitDate,
  name,
  timeZone,
  isFreeSell,
}) => {
  const [field, _meta, helpers] = useField(name)
  const [isLoading, setIsLoading] = React.useState(false)
  const [items, setItems] = React.useState<AvailabilityItem[]>([])
  React.useEffect(() => {
    if (!visitDate || !quantity) return
    const getProductAvailability = async () => {
      try {
        setIsLoading(true)
        const visitDateTz = moment.tz(visitDate, timeZone).format()
        const availabilityItems = await getAvailability(
          attractionId,
          productId,
          quantity,
          dob,
          { visitDate: visitDateTz },
        )

        // update the local state
        setItems(availabilityItems)
        if (isFreeSell) {
          if (availabilityItems.length) {
            helpers.setValue(availabilityItems[0].startDateTime)
          }
        }
        setIsLoading(false)
      } catch (e) {
        console.log('could not get availability')
        alert('could not get availability')
      }
    }
    getProductAvailability()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitDate, quantity, attractionId, productId, dob, timeZone, isFreeSell])
  if (!visitDate) return <p>Please select a date</p>
  if (isLoading) return <Loading />
  if (!quantity) return <p>Please select at least one person.</p>

  // reset the value to blank if nothing matches
  const currentItem = items.findIndex(
    (item) => field.value && field.value === item.startDateTime,
  )
  if (!isLoading && items.length && currentItem === -1 && field.value) {
    helpers.setValue(null)
  }
  return (
    <div>
      {items.length === 0 && (
        <p>
          There are no timeslots available for this date. Please try another
          date or quantity.
        </p>
      )}
      {items.map((item) => (
        <div>
          <Row key={item.eventId} align="center">
            <Col>
              {moment
                .parseZone(item.startDateTimeAttractionTz)
                .format('h:mma - Do MMM YYYY')}
            </Col>
            <Col>
              {field.value === item.startDateTime ? (
                <Button buttonStyle="tertiary" disabled>
                  Selected
                </Button>
              ) : (
                <Button onclick={() => helpers.setValue(item.startDateTime)}>
                  Select
                </Button>
              )}
            </Col>
          </Row>
          {field.value === item.startDateTime &&
            moment(item.startDateTime).isBefore(
              moment.tz(timeZone).add(2, 'days'),
              'day',
            ) && (
              <Warning>
                Please note that bookings within 48 hours are non-refundable.
              </Warning>
            )}
        </div>
      ))}
      <ErrorMessage name={name} />
    </div>
  )
}

export default AvailabilitySelect
