import React from 'react'

import Default from '../layouts/Default'
import ContentContainer from '../components/ContentContainer'
import { PageTitle } from '../styles/fonts'

const FourOhFour: React.FC = () => {
  return (
    <Default>
      <ContentContainer>
        <PageTitle>404</PageTitle>
        <p>Page not found</p>
      </ContentContainer>
    </Default>
  )
}

export default FourOhFour
