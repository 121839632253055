import React from 'react'
import styled, { css } from 'styled-components'

type ButtonProps = {
  buttonStyle?: 'tertiary' | 'filled' | 'tertiary-outline'
  className?: string
  onclick?(e: React.MouseEvent): void
  type?: 'button' | 'reset' | 'submit' | undefined
  disabled?: boolean
  to?: string
}

const ButtonEl = styled.button<ButtonProps>`
  border: 3px solid ${(props) => props.theme.colors.primary};
  padding: 13px 12px;
  font-family: ${(props) => props.theme.fonts.heading};
  font-weight: 600;
  font-size: 19px;
  line-height: 22px;
  cursor: pointer;
  min-width: 100px;
  appearance: none;
  background: none;

  &:hover {
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.secondary};
  }

  &[disabled] {
    cursor: initial;
    color: inherit;
    opacity: 0.5;

    &:hover {
      color: inherit;
    }
  }

  ${(props) =>
    props.buttonStyle === 'filled' &&
    css`
      background: ${({ theme }) => theme.colors.primary};
      color: #fff;

      &:hover {
        opacity: 0.8;
      }
    `}

  ${(props) =>
    props.buttonStyle === 'tertiary' &&
    css`
      background: ${({ theme }) => theme.colors.tertiary};
      border-color: ${({ theme }) => theme.colors.tertiary};
      color: ${({ theme }) => theme.colors.primary};

      &:hover {
        background: ${({ theme }) => theme.colors.tertiary};
        opacity: 0.8;
      }

      &[disabled] {
        &,
        &:hover {
          background: #000;
          color: #fff;
          border-color: #000;
          opacity: 0.5;
        }
      }
    `}
  
  ${(props) =>
    props.buttonStyle === 'tertiary-outline' &&
    css`
      color: ${({ theme }) => theme.colors.tertiary};
      border-color: ${({ theme }) => theme.colors.tertiary};

      &:hover {
        opacity: 0.8;
        background: none;
        color: ${({ theme }) => theme.colors.tertiary};
      }
    `}
`

const Button: React.FC<ButtonProps> = ({
  children,
  buttonStyle,
  className,
  onclick,
  type = 'button',
  disabled,
}) => {
  return (
    <ButtonEl
      buttonStyle={buttonStyle}
      className={className}
      onClick={onclick}
      type={type}
      disabled={disabled}
    >
      {children}
    </ButtonEl>
  )
}

export default Button
