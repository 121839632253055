import React from 'react'
import ReactModal from 'react-modal'
import styled, { ThemeContext } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { py, px } from '../styles/spacing'
import { h3Black } from '../styles/fonts'
import BlankButton from './BlankButton'
import Input from './forms/Input'
import Button from './Button'
import { requestLoginLink } from '../api'
import Loading from './Loading'

const Container = styled.div`
  ${py(2)}
  ${px(2)}
`

const CloseRow = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`

const Title = styled.h2`
  ${h3Black}
`

const EmailInput = styled(Input)`
  background: #fff;
  color: #000;
  padding: 5px 10px;
`

const FullWidthButton = styled(Button)`
  width: 100%;
  margin-top: 45px;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
`

const PCenter = styled.p`
  text-align: center;
`

const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.error};
`

const loginFormSchema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required()

type StateSignInProps = {
  onSubmit(email: string): void
}
const StateSignIn: React.FC<StateSignInProps> = ({ onSubmit }) => {
  const onFormSubmit = (values: yup.InferType<typeof loginFormSchema>) => {
    onSubmit(values.email)
  }
  return (
    <div>
      <Title>Sign In</Title>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={loginFormSchema}
        onSubmit={onFormSubmit}
      >
        {({ isValid, dirty }) => (
          <Form>
            <EmailInput label="Email Address" name="email" type="email" />
            <FullWidthButton
              type="submit"
              buttonStyle="tertiary"
              disabled={!dirty || !isValid}
            >
              Continue
            </FullWidthButton>
            <PCenter>
              Enter the email address used for previous bookings.
            </PCenter>
          </Form>
        )}
      </Formik>
    </div>
  )
}

type ConfirmProps = {
  email: string
  onReturn(): void
  onSuccess(): void
}
const StateConfirm: React.FC<ConfirmProps> = ({
  email,
  onReturn,
  onSuccess,
}) => {
  const [sending, setSending] = React.useState(false)
  const [error, setError] = React.useState(false)

  const sendLink = async () => {
    try {
      setError(false)
      setSending(true)
      await requestLoginLink(email)
      onSuccess()
    } catch (e) {
      setError(true)
    } finally {
      setSending(false)
    }
  }
  return (
    <div>
      <Title>Confirm Email</Title>
      <p>
        Who want’s to remember another password? We will send a link a link to{' '}
        <strong>{email}</strong> that lets you view all existing and previous
        bookings with just one click.
      </p>
      {error && (
        <ErrorText>
          Sorry, there was an error sending you a link, please try again.
        </ErrorText>
      )}
      <FullWidthButton
        type="button"
        buttonStyle="tertiary"
        disabled={sending}
        onclick={sendLink}
      >
        {sending && <Loading noMargin />}
        {!sending && <span>Send my link!</span>}
      </FullWidthButton>
      <PCenter>
        Oops!{' '}
        <BlankButton
          underline
          onClick={(e) => {
            e.preventDefault()
            onReturn()
          }}
        >
          Use a different email address.
        </BlankButton>
      </PCenter>
    </div>
  )
}

type FinishedProps = {
  email: string
}
const StateFinished: React.FC<FinishedProps> = ({ email }) => {
  return (
    <div>
      <Title>One Click Away</Title>
      <p>We sent you a link that will log you in with one click.</p>
      <p>
        Please check your email:
        <br />
        <strong>{email}</strong>
      </p>
      <p>All your booking information will be available when you return.</p>
    </div>
  )
}

type LoginScreen = 'email' | 'confirm' | 'finished'

type Props = {
  onAfterClose?: () => void
}
const Login: React.FC<Props> = ({ onAfterClose }) => {
  const [isOpen, setIsOpen] = React.useState(true)
  const themeContext = React.useContext(ThemeContext)
  const [screen, setScreen] = React.useState<LoginScreen>('email')
  const [email, setEmail] = React.useState('')

  return (
    <ReactModal
      isOpen={isOpen}
      onAfterClose={onAfterClose}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
        content: {
          maxWidth: '406px',
          borderRadius: '0',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          backgroundColor: themeContext.colors.primary,
          border: '1px solid #fff',
          width: '80%',
          color: themeContext.colors.secondary,
          padding: '0 20px 20px',
        },
      }}
    >
      <Container>
        <CloseRow>
          <BlankButton onClick={() => setIsOpen(false)}>
            <FontAwesomeIcon icon={faTimes} size="2x" />
          </BlankButton>
        </CloseRow>
        {screen === 'email' && (
          <StateSignIn
            onSubmit={(formEmail) => {
              setEmail(formEmail)
              setScreen('confirm')
            }}
          />
        )}
        {screen === 'confirm' && (
          <StateConfirm
            email={email}
            onReturn={() => {
              setScreen('email')
              setEmail('')
            }}
            onSuccess={() => {
              setScreen('finished')
            }}
          />
        )}
        {screen === 'finished' && <StateFinished email={email} />}
      </Container>
    </ReactModal>
  )
}

export default Login
