import React from 'react'
import styled from 'styled-components'

import Button from '../Button'
import { breakpointDown } from '../../styles/media'

const NextButton = styled(Button)`
  height: 80px;
  padding-left: 40px;
  padding-right: 40px;
  text-transform: uppercase;

  ${breakpointDown('md', `width: 100%`)}
`

type ContinueButtonProps = {
  onclick?: (e: React.MouseEvent) => void
  type?: 'button' | 'submit'
}
const ContinueButton: React.FC<ContinueButtonProps> = ({
  onclick,
  type,
  children,
}) => {
  return (
    <NextButton onclick={onclick} buttonStyle="tertiary" type={type}>
      {children}
    </NextButton>
  )
}

export default ContinueButton
