import axios from 'axios'
import qs from 'qs'

import * as T from '../types'
import storageUtils from '../util/storage'

const url = process.env.REACT_APP_API_ID

const api = axios.create({
  baseURL: url,
})

export const setApiToken = (token: string) => {
  api.defaults.headers = {
    ...api.defaults.headers,
    authorization: `Bearer ${token}`,
  }
}

type GrantTypes = 'client_credentials' | 'otp' | 'refresh_token'
const getTokenConfig = (
  grant_type: GrantTypes = 'client_credentials',
  scope = 'traversel-api',
  extraData = {},
) => {
  const data = qs.stringify({
    grant_type,
    client_id: '+web@houseoftravel.co.nz',
    scope,
    ...extraData,
  })
  const config: any = {
    method: 'post',
    url: `${url}/idsrv/connect/token`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
  }
  return config
}

// instead of refreshing our token
// we can just get a new one
api.interceptors.response.use(
  (response) => {
    // leave the response alone
    return response
  },
  (error) => {
    // if it's an error, let's start trying for a new token
    const originalRequest = error.config
    const tokenEndpoint = `${url}/idsrv/connect/token`

    // if we get a 401 response ( unauthorised )
    // and this is not a retry ( stops inifinite loop )
    // and also check it's not a request for a token
    if (
      error.response?.status === 401 &&
      !originalRequest.tsl_retry &&
      originalRequest.url !== tokenEndpoint
    ) {
      // set the retry flag
      originalRequest.tsl_retry = true
      // const refreshToken = storageUtils.getItem('tsl-refresh-token')
      const refreshToken = null
      const config = refreshToken
        ? getTokenConfig('refresh_token', '', {
            refresh_token: refreshToken,
          })
        : getTokenConfig()

      // dispatch an event for our app to hook into
      // we want to log the user out at this point
      const event = new Event('tsl-logout')
      window.dispatchEvent(event)

      return axios.request(config).then((res) => {
        if (res.status === 200) {
          const accessToken = res.data.access_token

          // update the headers
          setApiToken(accessToken)

          // delete the previous auth headers
          delete originalRequest.headers.authorization
          delete originalRequest.headers.Authorization

          // save the token
          // storageUtils.setItem('tsl-token', accessToken)

          // if there's a refresh token, save that too
          if (res.data.refresh_token) {
            storageUtils.setItem('tsl-refresh-token', res.data.refresh_token)
          }

          // return the original request
          return api(originalRequest)
        }

        return api(originalRequest)
      })
    }
    return Promise.reject(error)
  },
)

export const getToken = async () => {
  const config = getTokenConfig()
  const response = await api.request(config)

  // storageUtils.setItem('tsl-token', response.data.access_token)
  setApiToken(response.data.access_token)
}

export const getOtpToken = async (otp: string) => {
  const config = getTokenConfig('otp', 'traversel-api offline_access', { otp })
  const response = await api.request(config)

  storageUtils.setItem('tsl-token', response.data.access_token)
  storageUtils.setItem('tsl-refresh-token', response.data.refresh_token)
  setApiToken(response.data.access_token)
  return response.data.access_token
}

export const getDetail = (data: T.DetailItem[] | null, code: string) => {
  if (!data) return null
  const item = data.find((i) => {
    return i.code === code
  })
  if (!item) return null
  switch (item.format) {
    case 'JSON': {
      try {
        return JSON.parse(item.value)
      } catch (err) {
        console.log(err)
        return null
      }
    }
    case 'boolean': {
      return item.value === 'true'
    }
    default: {
      return item.value
    }
  }
}

export const getAttraction = async (id: string): Promise<T.ApiAttraction> => {
  const response = await api.get<T.ApiSingleResponse<T.ApiAttraction>>(
    `/api/data/attraction/${id}`,
  )

  return response.data.data
}

const formatProduct = (
  attraction: T.ApiAttraction,
  product: T.ApiProduct,
): T.Product => {
  const location = []
  if (product.address?.city) location.push(product.address.city)
  return {
    id: product.id,
    attractionId: attraction.id,
    attractionTitle: attraction.name,
    code: product.code,
    title: product.name,
    isActive: product.isActive,
    image: getDetail(product.details, 'Image') || {},
    images: getDetail(product.details, 'Images') || [],
    location: location.join(', '),
    city: product.address?.city || '',
    country: 'New Zealand',
    shortDesc: getDetail(product.details, 'Description') || '',
    displayPrice: parseFloat(getDetail(product.details, 'DisplayPrice') || 0),
    currency: attraction.currency?.code || 'NZD',
    type: attraction.descriptorTag?.code || '',
    longDescription: getDetail(product.details, 'Description') || '',
    whatToExpect: getDetail(product.details, 'WhatToExpect') || '',
    duration: getDetail(product.details, 'Duration') || '',
    goodFor: getDetail(product.details, 'GoodFor') || [],
    minAge: getDetail(product.details, 'MinAge') || '',
    groupSize: getDetail(product.details, 'GroupSize') || '',
    highlights: getDetail(product.details, 'Highlights') || [],
    // rating: attraction.rating?.value || '',
    rating: 0,
    ratingFrom: 0,
    bookingFields: product.detailsFromSupplier?.bookingFields || [],
    readyForBooking: product.detailsFromSupplier?.readyForBooking || false,
    needToKnow: getDetail(product.details, 'NeedToKnow') || '',
    locationCoordinates: {
      lat: product.address?.latitude || 0,
      lng: product.address?.longitude || 0,
    },
    certifications: getDetail(product.details, 'Certifications') || [],
    pickup: product.detailsFromSupplier?.pickup || null,
    // always default to auckland
    timeZone: attraction.timezone?.code || 'Pacific/Auckland',
    address: product.address,
    quantityRequiredMin:
      (product.detailsFromSupplier?.quantityRequired &&
        product.detailsFromSupplier?.quantityRequiredMin) ||
      1,
    quantityRequiredMax:
      (product.detailsFromSupplier?.quantityRequired &&
        product.detailsFromSupplier?.quantityRequiredMax) ||
      9999,
    isFreeSell: product.ticketing.isFreeSell,
  }
}

export const getProduct = async (
  attractionId: string,
  productId: string,
): Promise<T.Product> => {
  const attraction = await getAttraction(attractionId)
  const product = await api.get<T.ApiSingleResponse<T.ApiProduct>>(
    `/api/data/attraction/${attractionId}/product/${productId}`,
  )
  return formatProduct(attraction, product.data.data)
}

export const getProducts = async (id: string): Promise<T.Product[]> => {
  const attraction = await getAttraction(id)
  const response = await api.get<T.ApiListResponse<T.ApiProduct>>(
    `/api/data/attraction/${id}/product`,
  )
  if (response.data.data.items.length) {
    return response.data.data.items.map((data) => {
      return formatProduct(attraction, data)
    })
  }
  return []
}

type AvailabilityResponse = {
  data: T.AvailabilityItem[]
}
export const getAvailability = async (
  id: string,
  productId: string,
  quantity: number,
  visitorDoB: string,
  visit: {
    visitDate?: string
    visitStartDate?: string
    visitEndDate?: string
  },
): Promise<T.AvailabilityItem[]> => {
  const params = qs.stringify({
    quantity,
    visitorDoB,
    ...visit,
  })
  const response = await api.get<AvailabilityResponse>(
    `/api/data/attraction/${id}/product/${productId}/availability?${params}`,
  )
  return response.data.data
}

export const getNextAvailability = async (
  id: string,
  productId: string,
  quantity: number,
  visitorDoB: string,
  visitStartDate: string,
  visitEndDate: string,
): Promise<T.AvailabilityItem[]> => {
  const params = qs.stringify({
    quantity,
    visitorDoB,
    visitStartDate,
    visitEndDate,
    nextOnly: true,
  })
  const response = await api.get<AvailabilityResponse>(
    `/api/data/attraction/${id}/product/${productId}/availability?${params}`,
  )
  return response.data.data
}

export const makeBooking = async (
  visitDate: string,
  items: T.ConfirmedCartItem[],
  extraInfo: {
    email: string
    phone?: string
  },
) => {
  const internalInfo = []

  // add the pickup details
  if (items[0].pickupLocationString) {
    internalInfo.push({
      label: 'PickupInfo',
      value: items[0].pickupLocationString,
    })
  }
  const bookingData = {
    visitDate,
    emailAddress: extraInfo.email,
    phoneNumber: extraInfo.phone || '',
    internalInfo,
    additionalInfo: items[0].additionalInfo,
    items: items.map((item) => {
      // get a list of DoBs
      const dobs = item.pax.reduce<string[]>((prev, pax) => {
        const paxDobs = Array.from({ length: pax.quantity }).map(() => pax.dob)
        return [...prev, ...paxDobs]
      }, [])
      return {
        attractionId: item.attractionId,
        products: [
          {
            productCode: item.productCode,
            visitDate: item.time,
            visitorsDoB: dobs,
            visitorsAdditionalInfo: [],
            pickupLocationName: items[0].pickupLocationName || null,
          },
        ],
      }
    }),
  }
  return api.post('/api/data/ticket/bookingWithPayment', {
    data: bookingData,
  })
}

export const getAttractionPricings = (attractionId: string) => {
  return api.get<T.ApiListResponse<T.PricingListItem>>(
    `/api/data/attraction/${attractionId}/pricing`,
  )
}

export const getAttractionPricing = (
  attractionId: string,
  pricingId: string,
) => {
  return api.get<T.ApiSingleResponse<T.Pricing>>(
    `/api/data/attraction/${attractionId}/pricing/${pricingId}`,
  )
}

export const requestLoginLink = (emailAddress: string) => {
  return api.post('/api/main/user/otp', { emailAddress })
}

export const getBookings = () => {
  return api.get<{ data: T.ApiBookingListItem[] }>('/api/data/ticket/booking')
}

export const getBooking = (id: string) => {
  return api.get<T.ApiSingleResponse<T.ApiBooking>>(
    `/api/data/ticket/booking/${id}/`,
  )
}

export const cancelBooking = (id: string) => {
  return api.post(`/api/data/ticket/booking/${id}/cancel`, {
    data: {
      reason: 'Customer cancellation requested via Web UI',
    },
  })
}

export const searchProducts = (params: any) => {
  return api.get<{ data: { items: T.ProductSearchResult[] } }>(
    `/api/data/attraction/products/search`,
    {
      params,
    },
  )
}
