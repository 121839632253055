const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
}

export const breakpointUp = (bp: keyof typeof breakpoints, rules: string) => {
  return `@media screen and (min-width: ${breakpoints[bp]}px) {
    ${rules}
  }`
}

export const breakpointDown = (bp: keyof typeof breakpoints, rules: string) => {
  return `@media screen and (max-width: ${breakpoints[bp] - 1}px) {
    ${rules}
  }`
}
