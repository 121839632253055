import React from 'react'
import GoogleMapReact from 'google-map-react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

type CardProps = {
  lat: number
  lng: number
}

type MarkerProps = {
  lat: number
  lng: number
  text?: string
}

const MapWrapper = styled.div`
  width: 100%;
  padding-bottom: 50%;
  position: relative;

  .gmap__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

const MapMarker: React.FC<MarkerProps> = () => (
  <FontAwesomeIcon size="4x" icon={faMapMarkerAlt} />
)

export default function GMap({ lat, lng }: CardProps) {
  return (
    <MapWrapper className="gmap">
      <div className="gmap__inner">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAPS_KEY || '',
          }}
          defaultCenter={{ lat, lng }}
          defaultZoom={11}
        >
          <MapMarker lat={lat} lng={lng} />
        </GoogleMapReact>
      </div>
    </MapWrapper>
  )
}
