import React from 'react'
import styled from 'styled-components'
import { Row, Col, Hidden } from 'react-grid-system'
import { Link, useParams } from 'react-router-dom'

import Container from './Container'
import Button from './Button'
import Login from './Login'
import { useAuthState, useAuthDispatch } from '../hooks/authContext'
import { getToken } from '../api'
import BookingSidebar from './BookingSidebar'
import HoverMegaMenu from './HoverMegaMenu'
import { getRegionUrlValue, regions } from '../util/regions'
import { breakpointUp } from '../styles/media'
import { useProductSate } from '../hooks/productContext'

const HeaderEl = styled.header`
  color: ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.colors.secondary};
  padding-top: 15px;
  padding-bottom: 15px;
`

const Logo = styled.img`
  margin-right: 10px;
`

const NavItem = styled(Link)<{ hoverbackgroundchange?: boolean }>`
  padding: 8px 0 0;
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  margin-right: 20px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -5px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
    opacity: 0;
  }

  &:hover {
    color: ${(props) =>
      props.hoverbackgroundchange ? props.theme.colors.secondary : 'inherit'};
    background: ${(props) =>
      props.hoverbackgroundchange ? props.theme.colors.primary : 'inherit'};
    &::after {
      opacity: 1;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: 395px) {
    font-size: 14px;
  }
`

const NavWrapper = styled.nav`
  display: flex;
  @media only screen and (max-width: 669px) {
    margin: 23px 0;
  }
`

const MegaMenuContent = styled.div`
  color: #fff;
  .listGroupTitle {
    text-transform: capitalize;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.tertiary};
    margin-bottom: 10px;

    ${breakpointUp('md', 'margin-bottom: 30px;')}
  }
  .listGroupWrapper {
    display: block;
    column-count: 2;
    margin-bottom: 30px;
  }

  a {
    color: inherit;
    font-weight: normal;
    font-size: 14px;
    text-decoration: none;

    &:hover,
    &.selected {
      color: ${({ theme }) => theme.colors.tertiary};
    }
  }
`

type Props = {
  logo?: null
}

const Header: React.FC<Props> = ({ logo }) => {
  const [showLogin, setShowLogin] = React.useState(false)
  const authState = useAuthState()
  const authDispatch = useAuthDispatch()
  const { region } = useParams<{ region?: string }>()
  const productState = useProductSate()

  const logOut = async () => {
    try {
      authDispatch({ action: 'LogOut' })
      await getToken()
    } catch (e) {
      console.log('failed to get new token', e)
    }
  }

  const northIslandRegions = [
    'NZ-WGN',
    'NZ-AUK',
    'NZ-WKO',
    'NZ-HKB',
    'NZ-BOP',
    'NZ-NTL',
    'NZ-MWT',
    'NZ-TKI',
    'NZ-GIS',
  ].filter((r) => productState.validRegions[r])
  const southIslandRegions = Object.keys(regions).filter(
    (r) => !northIslandRegions.includes(r) && productState.validRegions[r],
  )

  return (
    <HeaderEl>
      <Container>
        <Row align="center">
          <Col>
            <Row align="center">
              <Col xs={12} md="content">
                {logo === undefined && (
                  <a href="https://mixandmatch.co.nz">
                    <Hidden xs sm>
                      <Logo
                        src={`${process.env.PUBLIC_URL}/logos/logo-mix-and-match.svg`}
                        alt="Logo"
                        width={230}
                      />
                    </Hidden>
                    <Hidden md lg xl xxl>
                      <Logo
                        src={`${process.env.PUBLIC_URL}/logos/mixandmatch-mobile.svg`}
                        alt="Logo"
                        width={120}
                      />
                    </Hidden>
                  </a>
                )}
              </Col>
              <Col>
                <NavWrapper>
                  <NavItem to="/">Activites Home.</NavItem>
                  <HoverMegaMenu hoverElement="Destinations.">
                    <MegaMenuContent>
                      <Row>
                        <Col xs={12} md={6}>
                          <div className="listGroupTitle">
                            North Island destinations
                          </div>
                          <div className="listGroupWrapper">
                            {northIslandRegions.map((r) => (
                              <div key={r}>
                                <Link
                                  to={`/location/nz/${getRegionUrlValue(
                                    regions[r],
                                  )}`}
                                  className={
                                    getRegionUrlValue(regions[r]) === region
                                      ? 'selected'
                                      : ''
                                  }
                                >
                                  {regions[r]}
                                </Link>
                              </div>
                            ))}
                          </div>
                        </Col>
                        <Col xs={12} md={6}>
                          <div className="listGroupTitle">
                            South Island destinations
                          </div>
                          <div className="listGroupWrapper">
                            {southIslandRegions.map((r) => (
                              <div key={r}>
                                <Link
                                  to={`/location/nz/${getRegionUrlValue(
                                    regions[r],
                                  )}`}
                                  className={
                                    getRegionUrlValue(regions[r]) === region
                                      ? 'selected'
                                      : ''
                                  }
                                >
                                  {regions[r]}
                                </Link>
                              </div>
                            ))}
                          </div>
                        </Col>
                      </Row>
                    </MegaMenuContent>
                  </HoverMegaMenu>
                </NavWrapper>
              </Col>
            </Row>
          </Col>
          <Col width="content">
            <Row align="center" nogutter>
              <Col width="content">
                {authState.loggedIn && (
                  <>
                    <BookingSidebar />
                    <Button onclick={logOut}>Sign Out</Button>
                  </>
                )}
                {!authState.loggedIn && (
                  <>
                    <Button onclick={() => setShowLogin(true)}>
                      My Bookings
                    </Button>
                    {showLogin && (
                      <Login onAfterClose={() => setShowLogin(false)} />
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </HeaderEl>
  )
}

export default Header
