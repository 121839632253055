import React from 'react'
import { Link } from 'react-router-dom'
import ContentContainer from '../components/ContentContainer'
import Default from '../layouts/Default'
import { PageTitle } from '../styles/fonts'

const SupplierTermsOfUse: React.FC = () => {
  return (
    <Default logo={null} footerLogo={null}>
      <ContentContainer>
        <p>
          <Link to="/">Home</Link>
        </p>
        <PageTitle>Supplier Terms of Use</PageTitle>
        <p>
          <strong>What this Covers</strong>
        </p>
        <p>
          These Supplier and Application Programming Interface Services Terms of
          Use (&quot;<strong>Terms of Use</strong>&quot;) constitute a contract
          between the Supplier identified in the Services Agreement, or any
          other agreement that references or links to these Terms of Use) and
          Traversel Pte Limited and/or Traversel NZ Limited and is referred to
          in these Terms of Use as &quot;<strong>Supplier</strong>&quot; (or as
          &quot;you&quot; or &quot;your&quot;) and the Traversel company
          referred to in these Terms of Use as &quot;<strong>Traversel</strong>
          &quot; (or as &quot;we,&quot; &quot;us&quot; or &quot;our&quot;).
          These Terms of Use apply to your use of any of Traversel&apos;s
          application programming interface services (&quot;
          <strong>APIs</strong>&quot;). Any access or use by you of Traversel’s
          Platform or other systems (the &quot;
          <strong>Platform</strong>&quot;) using the APIs is governed by these
          Terms of Use.
        </p>
        <p>
          In the event that you have a Services Agreement with Traversel, some
          portions of this Terms of Use may or may not be applicable.
        </p>
        <p>
          <strong>Platform</strong>
        </p>
        <p>
          <strong>How You May Use the Platform.</strong>
        </p>
        <p>In using the Platform, you shall:</p>
        <ul>
          <li>obey all applicable laws;</li>
          <li>
            comply with rules and restrictions imposed on travel supplier
            content made available through the Platform;
          </li>
          <li>
            comply with any other codes of conduct, policies, including, the API
            Terms
          </li>
          of Use below, or other rules for use of the Platform that we provide
          to you;
          <li>keep your Platform account password secret; and</li>
          <li>
            promptly notify us if you learn of a security breach related to the
            Platform.
          </li>
        </ul>
        <p>
          <strong>How You May Not Use the Platform.</strong>
        </p>
        <p>
          In using the Platform, you shall{' '}
          <span style={{ textDecoration: 'underline' }}>not</span>:
        </p>
        <ul>
          <li>engage in, facilitate, or further unlawful conduct;</li>
          <li>
            use the Platform in a way that harms Traversel, our Affiliates, or
            our respective customers, advertisers, resellers, distributors,
            and/or vendors, or their respective officers, directors, and
            employees (collectively, the &quot;
            <strong>Traversel Parties</strong>&quot;). (
            <strong>Affiliate</strong> shall mean an entity that directly (or
            indirectly through one or more intermediaries) controls, or is
            controlled by, or is under common control with, the entity referred
            to. The term &quot;<strong>control</strong>&quot; (including the
            terms &quot;controlling&quot;, &quot;controlled by&quot;, and
            &quot;under common control with&quot;) means the possession, direct
            or indirect, of the power to direct or cause the direction of the
            management and policies of the relevant entity, whether through the
            ownership of voting securities, or through contract, or otherwise.);
          </li>
          <li>
            use any portion of the Platform, or any web site that displays or
            performs content from the Platform owned or operated by Traversel,
            as a destination linked from any unsolicited bulk messages or
            unsolicited commercial messages (&quot;<strong>Spam</strong>&quot;);
          </li>
          <li>
            use any automated process or tool to access and/or use the Platform
            (such as a BOT or a spider) if use of such process or tool might
            impair the proper functioning of our System;
          </li>
          <li>
            copy, store, archive, republish, sell, or create a database of data
            or information obtained via the Platform, in whole or in part,
            directly or indirectly;
          </li>
          <li>
            use any unauthorized means to modify or reroute, or attempt to
            modify or reroute, the Platform;
          </li>
          <li>
            transmit, offer, sell, advertise, license, or deliver any
            infringing, defamatory, offensive, or illegal products, services, or
            materials;
          </li>
          <li>
            violate any applicable law or regulation, or violate the rights of
            any third party (including, without limitation, rights of privacy or
            proprietary rights); and/or
          </li>
          <li>
            damage, disable, overburden, or impair the Platform (or any
            network(s) connected to the Platform) or interfere with
            anyone&apos;s use and enjoyment of the Platform.
          </li>
        </ul>
        <p>
          Traversel reserves the right to determine, in its reasonable
          discretion, whether your use of the Platform and/or API is in
          accordance with the terms of this Agreement.
        </p>
        <p>
          <strong>You Are Responsible for Your Platform Account.</strong>
        </p>
        <p>
          Only you may use your Platform account and associated credentials. For
          some parts of the Platform, you may be allowed to set up additional
          member accounts that are dependent on your account (an &quot;
          <strong>Associated Account</strong>&quot;). You may authorize one or
          more third parties to access and/or use the Platform on your behalf or
          to access information related to your Platform account only where
          Traversel provides a mechanism for such access and only when you
          and/or the third party have signed any documents reasonably requested
          by Traversel in connection with such authorization. You are
          responsible for all activity that takes place with your Platform
          account or an Associated Account resulting from use of the Platform by
          any third party whom you have authorized to access your Platform
          account.
        </p>
        <p>
          If we permit you to authorize a third party to access and/or use the
          Platform on your behalf (e.g., via emulation) and/or to access
          information related to your Platform account, then (1) you waive your
          right to assert any claim against Traversel related to such third
          party access, and you release Traversel from any and all liability for
          loss or damage you may incur, to the extent that that such claim,
          loss, or damage is based on, arises out of, or is related to such
          third party’s access and use of your Platform account and related
          information; and (2) you will indemnify and hold the Traversel Parties
          harmless from and against any and all loss, liability, and expense
          (including reasonable attorneys&apos; fees) suffered or incurred by
          reason of any claims, proceedings, or suits based on or arising out of
          such third party’s access and use of your Platform account and related
          information.
        </p>
        <p>
          If another Traversel Supplier authorizes you to access their Platform
          account in connection with your provision of services to such
          customer, then you will indemnify and hold the Traversel Parties
          harmless from and against any and all loss, direct and indirect,
          liability, and expense (including but not limited reasonable
          attorneys&apos; fees) suffered or incurred by reason of any claims,
          proceedings, or suits based on or arising out of your access and use
          of their Platform account and/or the Platforms you provide. You will
          be solely responsible for defending any claim, subject to
          Traversel&apos;s right to participate with legal advisers it selects,
          and you will not agree to any settlement that imposes any obligation
          or liability on the Traversel Parties without Traversel&apos;s prior
          written consent.
        </p>
        <p>
          <strong>If You Are an Associated Account User.</strong>
        </p>
        <p>
          If you are the user of an Associated Account or if you emulate
          another’s Platform account, then the holder of the Platform account
          has full control over your access and use of the Platform. This
          control includes the right to close, or alter your Associated Account
          at any time, and, in some cases, to request and receive Platform use
          information related to your Associated Account.
        </p>
        <p>
          <strong>API</strong>
        </p>
        <p>
          <strong>Rights to Use APIs.</strong>
        </p>
        <p>
          Subject to your compliance with these Terms of Use, you may use any of
          the APIs we provide to access and use the Platform or provide data and
          materials to us on a revocable, non-exclusive, non-assignable,
          non-transferable basis.
        </p>
        <p>
          For API Access, you will need to obtain a Supplier identifier or other
          credentials (&quot;<strong>Credentials</strong>&quot;) from us in
          order to access and use the Platform. After you have agreed to the
          terms of this agreement, and supplied all required information, we
          will provide you with Credentials. All information you provide to us
          as part of the Credentials provisioning process must be accurate. You
          are solely and entirely responsible for all uses of the API and
          Platform occurring under your Credentials.
        </p>
        <p>
          We reserve the right, in our sole discretion, to refuse to provide
          access to the Services for any supplier or vendor.
        </p>
        <p>
          <strong>3. How You May Use the APIs.</strong>
        </p>
        <p>In using the APIs you shall:</p>
        <p>(a) comply with any rules for the APIs that we provide; and</p>
        <p>
          (b) design and operate automated applications so as to minimize usage
          of the computing resources of the System, and promptly remedy any
          situation resulting in an unusually high number of API calls being
          sent to or received by the System.
        </p>
        <p>
          Before the retirement of an API version, Traversel will provide 90
          days advance notice. You will upgrade to a currently supported version
          in advance of an API retirement. If you do not do so, you acknowledge
          that any applications using the retired API version are unlikely to
          function.
        </p>
        <p>
          <strong>Limitations and Conditions.</strong>
        </p>
        <p>
          We may, in our sole discretion, limit: (i) the rate at which the
          Platform may be called, (ii) the amount of storage, if any, made
          available through the Platforms, (iii) the size of data packets that
          may be uploaded to, or served from, the Platforms, and/or (iv) the
          duration of any session of use of the Platforms (all of the foregoing
          being forms of &quot;<strong>Throttling</strong>&quot;) as necessary
          in order to preserve the proper functioning of our Systems. We may
          perform this Throttling per application, per Credential, or on any
          other basis. You will not take steps to circumvent any technical
          measures we may put in place to enforce Throttling.
        </p>
        <p>
          <strong>Indemnification.</strong>
        </p>
        <p>
          You will indemnify and hold Traversel and its Affiliates and our
          respective Suppliers, advertisers, resellers, distributors, and
          suppliers, and their respective directors, employees, and agents
          (&quot;
          <strong>Traversel Parties</strong>&quot;) harmless from and against
          any and all loss, liability, and expense (including reasonable
          attorneys&apos; fees and disbursements) suffered or incurred by reason
          of any claims from your use as a supplier to the Platform. You will be
          solely responsible for defending any claim, subject to
          Traversel&apos;s right to participate with legal counsel it selects,
          and you will not agree to any settlement that imposes any obligation
          or liability on the Traversel Parties without Traversel&apos;s prior
          written consent.
        </p>
        <p>
          <strong>Maintenance.</strong>
        </p>
        <p>
          We may temporarily suspend the Platform to perform necessary repairs,
          maintenance, or improvements of the System or the Platform. We will
          provide reasonable advance notice of any such suspension where it is
          practicable under the circumstances; and we will use commercially
          reasonable efforts to promptly restore the Platform. We exclude all
          our liability of any kind relating to any problems of whatever nature
          which have been caused by the suspension of the Platform.
        </p>
        <p>
          <strong>Confidentiality. </strong>
        </p>
        <p>
          Each party agrees that neither it nor its employees, officers,
          subcontractors, representatives, suppliers, or directors will
          disclose, convey, or in any other manner make available any
          Confidential Information to any third party, except to its
          consultants, outside accountants, or legal counsel who are bound by
          obligations of confidentiality no less stringent than those set forth
          in this clause. Each party shall use at least the same level of care
          that such party uses to protect its own proprietary information of a
          similar nature, but in no event shall said party use less than
          reasonable care in protecting the Confidential Information. &quot;
          <strong>Confidential Information</strong>&quot; means information and
          materials which are technical and/or business in nature (which may
          include, among other things, marketing materials; processes; financial
          data; analyses; forecasts; past, present or prospective Suppliers or
          employees; documents; business practices; business plans; product
          plans; proposals, and the like) that a party or its Affiliates (the
          &quot;
          <strong>Disclosing Party</strong>&quot;) has made, or expects to make,
          available to the other party or its Affiliates (the &quot;
          <strong>Receiving Party</strong>&quot;).
        </p>
        <p>
          Confidential Information may be written, oral, visual, or in any other
          media or format. Confidential Information does not include any
          information (i) which is, at the time of disclosure, part of the
          public domain, (ii) which has become part of the public domain through
          no breach by the Receiving Party of this clause, (iii) which was
          lawfully disclosed to the Receiving Party by a third party without
          restriction on such disclosure, (iv) which is independently developed
          by the Receiving Party without breach of this clause, or (v) is or was
          rightfully in the Receiving Party&apos;s possession prior to
          disclosure by the Disclosing Party hereunder.
        </p>
        <p>
          <strong>How We May Change the Terms of Use.</strong>
        </p>
        <p>
          If we change these Terms of Use, then we will notify you at least 30
          days before the change takes place. If you do not agree to these
          changes, then you must stop using the Platform before the change takes
          place. If you do not stop using the Platform and/or API Credentials,
          then your use will continue under the changed Terms of Use.
        </p>
        <p>
          <strong>Warranty</strong>
        </p>
        <p>
          We provide the Platform &quot;as-is,&quot; &quot;with all faults&quot;
          and &quot;as available.&quot; Unless otherwise expressly stated in
          writing, Traversel makes no representation or warranty regarding the
          accuracy, reliability or timeliness of information available via the
          Platform. We and our Affiliates, resellers, distributors, and other
          vendors give no express warranties, guarantees, or conditions. We
          exclude any implied warranties, including those of merchantability,
          fitness for a particular purpose, workmanlike effort, and
          non-infringement.
        </p>
        <p>
          <strong>Liability Limitation</strong>
        </p>
        <p>
          You can recover from Traversel Parties only direct damages up to an
          amount equal to the aggregate amount actually paid to us for use of
          our APIs or Platform during the three month period preceding the date
          the claim of action arose, unless otherwise agreed in writing. Neither
          party may recover from the other consequential damages, lost profits,
          or special, indirect, incidental, or punitive damages.
        </p>
        <p>The limitation set out above applies to anything related to:</p>
        <p>(a) the Platform and API access,</p>
        <p>
          (b) content (including code) on third party Internet sites, third
          party programs, or third party conduct,
        </p>
        <p>
          (c) viruses or other disabling features that affect your access to or
          use of the Platform,
        </p>
        <p>
          (d) incompatibility between the Platform and other services, software,
          and hardware,
        </p>
        <p>
          (e) delays or failures you may have in initiating, conducting, or
          completing any transmissions, or requests in connection with the
          Platform in an accurate or timely manner, and
        </p>
        <p>
          (f) claims for breach of contract, breach of warranty, guarantee, or
          condition, strict liability, negligence, or other tort.
        </p>
        <p>It also applies even if:</p>
        <p>
          (a) this remedy does not fully compensate you for any losses, or fails
          of its essential purpose; or
        </p>
        <p>
          (b) Traversel knew or should have known about the possibility of the
          damages.
        </p>
        <p>
          <strong>
            Changes to the Platform; Cancellation of the Platform.
          </strong>
        </p>
        <p>
          We may change the Platform and/or delete supplier features at any time
          and for any reason. We may cancel or suspend your Platform and/or API
          at any time upon not less than 30 days notice. Our cancellation or
          suspension may be without cause. Upon cancellation, your right to use
          the Platform and/or API stops right away.
        </p>
        <p>
          <strong>Interpreting the Contract.</strong>
        </p>
        <p>
          All parts of these Terms of Use apply to the maximum extent permitted
          by law. A court may hold that we cannot enforce a part of these Terms
          of Use as written. If this happens, then you and we will replace that
          part with terms that most closely match the intent of the part that we
          cannot enforce. The rest of these Terms of Use will not change. These
          Terms of Use, together with the Services Agreement, constitutes the
          entire agreement between you and us regarding your use of the
          Platform. It supersedes any prior contract or statements regarding
          your use of the Service. If you have confidentiality obligations
          related to the Platform, those obligations remain in force. Should the
          terms of the Services Agreement materially differ from this Agreement
          as negotiated between the parties, the different terms as stated in
          the Services Agreement shall prevail over the terms listed herein.
        </p>
        <p>
          <strong>Assignment</strong>
        </p>
        <p>
          We may assign these Terms of Use, in whole or in part, at any time
          with or without notice to you. You may not assign these Terms of Use,
          or any part of them, to any other person without our prior written
          consent. Any attempt by you to do so is void. You may not transfer to
          anyone else, either temporarily or permanently, any rights to use the
          Platform and/or API or any part of the Platform.
        </p>
        <p>
          <strong>No Third Party Beneficiaries</strong>
        </p>
        <p>
          Our Agreement is solely for your and our benefit. It is not for the
          benefit of any other person, except for permitted successors and
          assigns.
        </p>
        <p>
          <strong>Intellectual Property Rights</strong>
        </p>
        <p>
          Except for the licenses expressly granted to you under this or the
          Services Agreement, Traversel retains all right, title, and interest
          in and to the Platform, the APIs (if applicable), any software and/or
          documentation provided, and all intellectual property rights therein.
        </p>
        <p>
          Except as expressly provided, this Agreement does not grant you any
          rights in any Traversel logo, trademark, or service mark, and you will
          not use any logo, trademark, or service mark of Traversel for any
          purpose without the prior written approval of Traversel.
        </p>
        <p>
          <strong>Your Materials</strong>
        </p>
        <p>
          You may be able to submit information, materials, and/or data in
          connection with your use of the Platform (&quot;
          <strong>Material</strong>
          &quot;). Except for anything that we license to you, we do not claim
          ownership of your Material. However, by providing your Material, you
          are granting to Traversel and/or its Affiliates or subcontractors free
          permission to:
        </p>
        <ul>
          <li>
            use, copy, process, distribute, display, publish, display,
            transcode, and otherwise modify your Material, each in connection
            with providing the Platform; and
          </li>
          <li>
            use such Material as embodied in the data resulting from operation
            of the System.
          </li>
        </ul>
        <p>
          You understand that Traversel may need to make copies, change the
          format, transcode, or otherwise process Material, in order to:
        </p>
        <ul>
          <li>store and retrieve the Material;</li>
          <li>
            make the Material available to you and those members of the public
            to whom you have granted access;
          </li>
          <li>
            conform to connecting networks&apos; technical requirements; or
          </li>
          <li>conform to the limitations and terms of the Platform.</li>
        </ul>
        <p>
          This section applies only to legally permissible Material and only to
          the extent that use and publishing of the legally permissible Material
          does not breach the law. You understand that sharing Material that
          violates others&apos; copyrights or other rights violates this Terms
          of Use and also the Services Agreement. You represent and warrant that
          you have all the rights necessary for you to grant the rights in this
          section and that the use and publication of the Material does not
          breach any law. We will not pay you for your Material. We may, at our
          sole discretion refuse to publish your Material and may remove your
          Material from the Platform at any time with or without written notice
          to you.
        </p>
        <p>
          <strong>Privacy.</strong>
        </p>
        <p>
          Any information you provide us in connection with your use of the
          Platform about you and/or your customers will be used and protected in
          accordance with applicable laws. In particular, we may access or
          disclose information about you and/or your use of the Platform in
          order to comply with the law or respond to lawful requests or legal
          process.
        </p>
        <p>
          <strong>Force Majeure. </strong>Except for payment obligations,
          neither party shall be deemed to be in default of these Terms of Use
          to the extent that performance of any obligation is delayed or
          prevented by an event of force majeure, such as an act of God,
          terrorism, war, civil disorder, fire, flood, explosion, riot, labor
          dispute, governmental actions, or any other cause beyond its control.
        </p>
        <p />
        <p />
      </ContentContainer>
    </Default>
  )
}

export default SupplierTermsOfUse
