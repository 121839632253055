import React from 'react'
import styled from 'styled-components'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import { Row, Col } from 'react-grid-system'
import {
  BillingDetails,
  AdditionalInfoItem,
  ConfirmedCartItem,
} from '../../types'
import { checkoutFormFooter } from '../../styles/misc'
import { my } from '../../styles/spacing'
import { breakpointUp } from '../../styles/media'
import ErrorFocus from '../forms/ErrorFocus'
import ContentContainer from '../ContentContainer'
import SectionHeading from './SectionHeading'
import Input from '../forms/Input'
import Textarea from '../forms/Textarea'
import cartContext from '../../hooks/cartContext'
import NextButton from './ContinueButton'

interface BillingAndContactDetails {
  currentBilling: BillingDetails
  items: ConfirmedCartItem[]
  onConfirm(items: ConfirmedCartItem[], billingInfo: BillingDetails): void
}

const validationSchema = yup
  .object()
  .shape({
    firstName: yup.string().required('First Name is required').defined(),
    lastName: yup.string().required('Last Name is required').defined(),
    email: yup.string().required('You Email is required').defined(),
    phone: yup
      .string()
      .matches(/^\+[0-9 ]+$/, 'Phone number must use the format +123 123 1234'),
    specialRequirements: yup.string(),
  })
  .defined()

type FormValues = yup.InferType<typeof validationSchema>

const FormFooter = styled.div`
  ${checkoutFormFooter}
`

const FooterCol = styled(Col)`
  ${my(3)}
  ${breakpointUp(
    'md',
    `
    ${my(0)}
  `,
  )}
`

const MainRow = styled.div`
  margin-top: 40px;
`

const InputWrap = styled.div`
  margin-bottom: 20px;
`

const BillingAndContact: React.FC<BillingAndContactDetails> = ({
  currentBilling,
  items,
  onConfirm,
}) => {
  const cartState = cartContext.useCartState()
  const onSubmit = (values: FormValues) => {
    // set the additional info
    const additionalInfo: AdditionalInfoItem[] = [
      {
        label: 'First Name',
        value: values.firstName,
      },
      {
        label: 'Last Name',
        value: values.lastName,
      },
    ]

    if (values.phone) {
      additionalInfo.push({
        label: 'Mobile',
        value: values.phone,
      })
    }

    if (values.specialRequirements) {
      additionalInfo.push({
        label: 'Special Requirements',
        value: values.specialRequirements,
      })
    }

    const newCartItems = [
      {
        ...items[0],
        additionalInfo,
      },
    ]

    onConfirm(newCartItems, {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
    })
  }

  return (
    <div>
      <Formik
        initialValues={{ phone: '', ...currentBilling }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {() => (
          <Form>
            <ErrorFocus />
            <ContentContainer>
              <MainRow>
                <SectionHeading noMargin>
                  Contact &amp; Billing Info
                </SectionHeading>
                <InputWrap>
                  <Input name="firstName" label="First Name" withBorder />
                </InputWrap>
                <InputWrap>
                  <Input name="lastName" label="Last Name" withBorder />
                </InputWrap>
                <InputWrap>
                  <Input name="email" label="Email" type="email" withBorder />
                </InputWrap>
                <InputWrap>
                  <Input
                    name="phone"
                    label="Phone (optional)"
                    placeholder="+12 34 567 89 10"
                    withBorder
                  />
                </InputWrap>
                <InputWrap>
                  <Textarea
                    name="specialRequirements"
                    label="Special Requirements"
                    withBorder
                  />
                </InputWrap>
              </MainRow>
            </ContentContainer>
            <FormFooter>
              <ContentContainer>
                <Row align="center">
                  <FooterCol>{cartState.cart[0].title}</FooterCol>
                  <FooterCol xs="content">
                    NZD&nbsp;
                    {items
                      .reduce((accumulator, current) => {
                        return accumulator + current.total
                      }, 0)
                      .toFixed(2)}
                  </FooterCol>
                  <FooterCol xs={12} md="content">
                    <NextButton type="submit">Continue</NextButton>
                  </FooterCol>
                </Row>
              </ContentContainer>
            </FormFooter>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default BillingAndContact
