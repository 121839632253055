import React from 'react'
import RSelect, {
  OptionsType,
  GroupedOptionsType,
  Styles,
  ValueType,
} from 'react-select'
import styled, { css } from 'styled-components'
import { useField } from 'formik'

import { Help, Label } from '../../styles/forms'

type Option = {
  label: string
  value: string
}
type SelectProps = {
  options: OptionsType<Option> | GroupedOptionsType<Option>
  label: string
  name: string
  placeholder?: string
  withBorder?: boolean
  help?: string
  onChange?: (v: string) => void
}

const fieldWithBorder = css`
  border: 1px solid #000;
  padding: 5px 10px;
`

const FieldWrap = styled.div<{ withBorder: boolean }>`
  ${(props) => props.withBorder && fieldWithBorder}
`

const customStyles: Partial<Styles> = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    minHeight: 0,
  }),
  valueContainer: (p) => ({
    ...p,
    padding: 0,
  }),
  dropdownIndicator: (p) => ({
    ...p,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
}

const Select: React.FC<SelectProps> = ({
  options,
  label,
  name,
  placeholder,
  withBorder = false,
  help,
  onChange: userOnChange,
}) => {
  const [field, _meta, helpers] = useField(name)

  const onChange = (value: ValueType<Option>) => {
    let updatedValue: any = value
    if (Array.isArray(value)) {
      updatedValue = value.map((val) => val.value)
    } else if (value && 'value' in value) {
      updatedValue = value.value
    }
    if (typeof userOnChange === 'function') userOnChange(updatedValue)
    helpers.setValue(updatedValue)
  }
  return (
    <FieldWrap withBorder={withBorder}>
      {label && <Label>{label}</Label>}
      {help && <Help>{help}</Help>}
      <RSelect
        options={options}
        name={name}
        placeholder={placeholder}
        styles={customStyles}
        onChange={onChange}
        defaultInputValue={field.value}
      />
    </FieldWrap>
  )
}

export default Select
