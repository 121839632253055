export const regions: Record<string, string> = {
  'NZ-OTA': 'Otago',
  'NZ-NSN': 'Nelson',
  'NZ-TAS': 'Tasman',
  'NZ-MBH': 'Marlborough',
  'NZ-CAN': 'Canterbury',
  'NZ-WGN': 'Wellington',
  'NZ-AUK': 'Auckland',
  'NZ-WKO': 'Waikato',
  'NZ-HKB': "Hawke's Bay",
  'NZ-BOP': 'Bay of Plenty',
  'NZ-NTL': 'Northland',
  'NZ-WTC': 'West Coast',
  'NZ-MWT': 'Manawatū-Whanganui',
  'NZ-STL': 'Southland',
  'NZ-TKI': 'Taranaki',
  'NZ-GIS': 'Gisborne',
}

export const getRegionsAsOptions = () => {
  return Object.entries(regions).map(([value, text]) => ({ value, text }))
}

export const getRegionNameByCode = (c: string): string => {
  return regions[c] || ''
}

export const getRegionUrlValue = (region: string): string => {
  return region
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^a-z -]/g, '')
}

export const getRegionByUrlValue = (
  region: string,
): [string, string] | undefined => {
  return Object.entries(regions).find((r) => {
    return getRegionUrlValue(r[1]) === region
  })
}

interface ImageCredit {
  img: string
  credit?: string
}
const regionHeaders: Record<string, ImageCredit> = {
  'NZ-AUK': {
    img: 'Auckland-NZ-Miles-Holden.jpg',
    credit: 'Miles Holden',
  },
  'NZ-GIS': {
    img: 'Gisborne-NZ.jpg',
  },
  'NZ-WKO': {
    img: 'Waikato-NZ-Corin-Walker-Bain.jpg',
    credit: 'Walker Bain',
  },
  'NZ-WGN': {
    img: 'Wellington-NZ.jpg',
  },
  'NZ-TAS': {
    img: 'Tasman-NZ-Fraser-Clements.jpg',
    credit: 'Fraser Clements',
  },
  'NZ-STL': {
    img: 'Southland-NZ-Graeme-Murray.jpg',
    credit: 'Graeme Murray',
  },
  'NZ-OTA': {
    img: 'Otago-NZ-Miles-Holden.jpg',
    credit: 'Miles Holden',
  },
  'NZ-BOP': {
    img: 'Bay-of-Plenty-NZ-Graeme-Murray.jpg',
    credit: 'Graeme Murray',
  },
  'NZ-MBH': {
    img: 'Marlborough-NZ-Miles-Holden.jpg',
    credit: 'Miles Holden',
  },
  'NZ-CAN': {
    img: 'Canterbury-NZ-Fraser-Gunn.jpg',
    credit: 'Fraser Gunn',
  },
  'NZ-NTL': {
    img: 'Northland-NZ-Alistair-Guthrie.jpg',
    credit: 'Alistair Guthrie',
  },
}

export const getRegionImage = (region: string): ImageCredit | null => {
  return regionHeaders[region] || null
}
