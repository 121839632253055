export const addCloudinaryArgs = (imageUrl: string, args: string): string => {
  // check for a cloudinary URL
  if (!/cloudinary\.com/.test(imageUrl)) return imageUrl

  // take the URL after upload/
  const delimiter = 'upload/'
  const path = imageUrl.substr(imageUrl.indexOf(delimiter) + delimiter.length)

  // this is everything up to 'upload/' inclusive
  const prePath = imageUrl.slice(
    0,
    imageUrl.indexOf(delimiter) + delimiter.length,
  )

  return `${prePath}${args}/${path}`
}
