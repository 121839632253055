import React from 'react'
import styled from 'styled-components'
import { ErrorMessage } from 'formik'

const Error = styled.div`
  color: ${({ theme }) => theme.colors.error};
`

type ErrorMessageComponentProps = {
  name?: string
  error?: string
}
const ErrorMessageComponent: React.FC<ErrorMessageComponentProps> = ({
  name,
  error,
}) => {
  return (
    <Error className="traversel-form-error">
      {error && <div>{error}</div>}
      {name && <ErrorMessage component="div" name={name} />}
    </Error>
  )
}

export default ErrorMessageComponent
