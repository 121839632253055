/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Row, Col } from 'react-grid-system'
import styled from 'styled-components'

import * as T from '../types'
import ProductCard from './ProductCard'
import { mb } from '../styles/spacing'
import ProductCardSkeleton from './ProductCardSkeleton'

const CardWrapper = styled.div`
  height: 100%;
`

const CardCol = styled(Col)`
  ${mb(3)}
`

type ProductGridProps = {
  products: T.ProductSearchResult[]
  isLoading: boolean
}
const ProductGrid: React.FC<ProductGridProps> = ({ products, isLoading }) => {
  return (
    <div>
      <Row gutterWidth={20}>
        {isLoading && (
          <>
            {[...Array(4)].map((_, i) => (
              <CardCol sm={6} md={3} key={i}>
                <CardWrapper>
                  <ProductCardSkeleton />
                </CardWrapper>
              </CardCol>
            ))}
          </>
        )}
        {!isLoading && (
          <>
            {products.map((product) => (
              <CardCol sm={6} md={3} key={product.id}>
                <CardWrapper>
                  <ProductCard product={product} />
                </CardWrapper>
              </CardCol>
            ))}
          </>
        )}
      </Row>
    </div>
  )
}

export default ProductGrid
