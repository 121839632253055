import { useEffect } from 'react'
import { useFormikContext } from 'formik'

const ErrorFocus = () => {
  const { isSubmitting, isValidating, errors } = useFormikContext()

  useEffect(() => {
    const error = document.querySelector('.traversel-form-error div')
    if (error) {
      error.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isSubmitting, isValidating, errors])

  return null
}

export default ErrorFocus
