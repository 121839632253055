import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-grid-system'
import { Link } from 'react-router-dom'

import * as T from '../types'
import { fontHeading } from '../styles/fonts'
import { getDetail } from '../api'
import { addCloudinaryArgs } from '../util/images'

const Card = styled.div`
  border: 1px solid #9b9b9b;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 100%;
  position: relative;

  > * {
    flex-basis: 100%;
  }

  &:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
  }
`

const OnSaleCard = styled.div`
  border: 4px solid ${(props) => props.theme.colors.tertiary};
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  pointer-events: none;
  z-index: 10;
`

const OnSaleText = styled.span`
  background: ${(props) => props.theme.colors.tertiary};
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  right: 0;
  top: 0;
  padding: 2px 15px;
  text-transform: uppercase;
`

const ImageWrap = styled.div`
  padding-bottom: 58%;
  position: relative;
  overflow: hidden;
  background: #ccc;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

const CardContent = styled.div`
  padding: 15px;
  background: #fff;
`

const CardHeading = styled.h3`
  ${fontHeading}
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  margin: 0;

  a {
    color: inherit;
    text-decoration: none;
  }
`

const CardSubheading = styled.div`
  ${fontHeading}
  font-weight: bold;
  font-size: 12px;
  line-height: 19px;
  text-transform: uppercase;
`

const CardDescription = styled.p`
  ${fontHeading}
  font-size: 14px;
  font-weight: 300;
`

const CardActions = styled(Row)`
  margin-top: auto;
  border-top: 1px solid ${({ theme }) => theme.colors.primary};
`

const CardPriceCell = styled(Col)`
  ${fontHeading}
  text-align: right;
  font-size: 14px;
  line-height: 12px;
`

const CardPriceCellInner = styled.div`
  padding: 10px;
`

const CardPrice = styled.div`
  font-weight: 600;
  font-size: 21px;
  line-height: 18px;
`

const CardActionButton = styled(Link)`
  ${fontHeading}
  font-size: 16px;
  font-weight: 600;
  display: block;
  color: ${({ theme }) => theme.colors.tertiary};
  background: ${({ theme }) => theme.colors.primary};
  text-align: center;
  text-transform: uppercase;
  padding: 18px 5px;
  text-decoration: none;
`

type ProductCardProps = {
  product: T.ProductSearchResult
}
const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const image = getDetail(product.details, 'Image')
  const shortDesc = getDetail(product.details, 'ShortDescription')
  const locationParts: string[] = []
  if (product.address?.city) locationParts.push(product.address.city)
  if (product.address?.countryName)
    locationParts.push(product.address.countryName)
  const location = locationParts.join(', ')

  const imageUrl = addCloudinaryArgs(image.url, 'w_550,h_320,c_fill')
  const srcSetSizeMin = 320
  const srcSetSizeMax = 2200
  const srcSet = []
  for (let i = srcSetSizeMin; i <= srcSetSizeMax; i += 200) {
    // the image is 58% high as it is wide
    const height = Math.round(i * 0.58)
    const size = addCloudinaryArgs(image.url, `w_${i},h_${height},c_fill`)
    srcSet.push(`${size} ${i}w`)
  }

  const route = `/attraction/${product.attractionId}/product/${product.id}`

  return (
    <Card>
      {product.discounted && (
        <OnSaleCard>
          <OnSaleText>On Sale!</OnSaleText>
        </OnSaleCard>
      )}
      <div>
        <Link to={route}>
          <ImageWrap>
            {image.url && (
              <Image
                src={imageUrl}
                alt={image.alt}
                srcSet={srcSet.join(', ')}
                sizes="(max-width: 575px) 100vw, (max-width: 765px) 50vw, (max-width: 1260px) 25vw, 298px"
              />
            )}
          </ImageWrap>
        </Link>
        <CardContent>
          <CardHeading>
            <Link to={route}>{product.name}</Link>
          </CardHeading>
          <CardSubheading>{location}</CardSubheading>
          <CardDescription>{shortDesc}</CardDescription>
        </CardContent>
      </div>
      <CardActions nogutter align="center">
        <Col>
          <CardActionButton to={route}>Find out more</CardActionButton>
        </Col>
        <CardPriceCell>
          <CardPriceCellInner>
            <CardPrice>
              NZD {parseFloat(getDetail(product.details, 'DisplayPrice') || 0)}
            </CardPrice>
            Per Person
          </CardPriceCellInner>
        </CardPriceCell>
      </CardActions>
    </Card>
  )
}

export default ProductCard
