import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-grid-system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'

import cartContext from '../hooks/cartContext'
import BlankButton from './BlankButton'
import Button from './Button'

const CartWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 25%;
  min-width: 300px;
  max-width: 710px;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  transition: all 0.2s ease-in-out;
  transform: translateX(100%);
  padding: 30px;
  display: flex;
  flex-direction: column;

  &.cart--open {
    transform: translateX(0);
  }
`

const CartBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
`

const CartButtonRow = styled.div`
  margin-top: auto;
`

const Cart: React.FC = () => {
  const cartState = cartContext.useCartState()
  const cartDispatch = cartContext.useCartDispatch()
  const history = useHistory()

  const hideCart = (e: React.MouseEvent) => {
    e.preventDefault()
    cartDispatch({
      action: 'CloseCart',
    })
  }

  return (
    <div>
      {cartState.open && <CartBackdrop onClick={hideCart} />}
      <CartWrapper className={`${cartState.open ? 'cart--open' : ''}`}>
        <Row>
          <Col>Cart</Col>
          <Col xs="content">
            <BlankButton onClick={hideCart}>
              <FontAwesomeIcon icon={faTimes} />
            </BlankButton>
          </Col>
        </Row>
        {cartState.cart.map((item) => (
          <div key={item.productCode}>{item.title}</div>
        ))}
        <CartButtonRow>
          <Button
            buttonStyle="tertiary"
            onclick={(e) => {
              hideCart(e)
              history.push('/checkout')
            }}
          >
            Checkout
          </Button>
        </CartButtonRow>
      </CartWrapper>
    </div>
  )
}

export default Cart
