import React from 'react'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col } from 'react-grid-system'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { useField } from 'formik'

import { fontHeading } from '../../styles/fonts'
import { Label, LeftIcon } from '../../styles/forms'
import ErrorMessage from './ErrorMessage'

const StyledInput = styled.input`
  ${fontHeading}
  font-size: 16px;
  line-height: 16px;
  font-weight: 300;
  border: none;
  width: 100%;
  box-sizing: border-box;

  ::placeholder {
    color: #595959;
  }
`

const fieldWithBorder = css`
  border: 1px solid #000;
  padding: 5px 10px;
`

const FieldWrap = styled.div<{ withBorder: boolean }>`
  ${(props) => props.withBorder && fieldWithBorder}
`

type InputProps = {
  name: string
  label?: string
  placeholder?: string
  icon?: IconDefinition
  withBorder?: boolean
  disabled?: boolean
  required?: boolean
  type?: 'text' | 'email' | 'password'
  className?: string
}
const Input: React.FC<InputProps> = ({
  name,
  label,
  placeholder,
  icon,
  withBorder = false,
  disabled,
  required = false,
  type = 'text',
  className,
}) => {
  const [field] = useField(name)
  return (
    <FieldWrap withBorder={withBorder} className={className}>
      {label && <Label htmlFor={`${name}_input`}>{label}</Label>}
      <Row nogutter>
        {icon && (
          <Col width="content">
            <LeftIcon>
              <FontAwesomeIcon icon={icon} />
            </LeftIcon>
          </Col>
        )}
        <Col>
          <StyledInput
            onChange={field.onChange}
            onBlur={field.onBlur}
            type={type}
            placeholder={placeholder}
            name={name}
            id={`${name}_input`}
            disabled={disabled}
            value={field.value}
            required={required}
          />
        </Col>
      </Row>
      <ErrorMessage name={name} />
    </FieldWrap>
  )
}

export default Input
