import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import CookieConsent from '../components/CookieConsent'

type Props = {
  logo?: null
  footerLogo?: null
}
const Default: React.FC<Props> = ({ children, logo, footerLogo }) => {
  return (
    <div>
      <Header logo={logo} />
      {children}
      <Footer logo={footerLogo} />
      <CookieConsent />
    </div>
  )
}

export default Default
