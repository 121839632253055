import React from 'react'
import { Col, Row } from 'react-grid-system'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import HeroImage from '../assets/img/default-hero.jpg'
import { fontHeading } from '../styles/fonts'
import * as T from '../types'
import Button from './Button'
import ContentContainer from './ContentContainer'
import LocationSelect from './LocationSelect'
import { breakpointUp } from '../styles/media'
import { getRegionByUrlValue, getRegionImage } from '../util/regions'

type HeroProps = {
  image?: string
}

const Container = styled.div`
  position: relative;
  z-index: 20;
`

const Hero = styled.div<HeroProps>`
  background: url(${(props) => props.image || HeroImage}) no-repeat scroll
    center;
  background-size: cover;
  height: 540px;
`

const SelectContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 30px 0 30px;
`

const Heading = styled.h2`
  margin: 0 0 15px;
  font-size: 30px;
  font-weight: bold;
  ${fontHeading}
  line-height: 1.2em;

  ${breakpointUp(
    'md',
    `
    font-size: 40px;
  `,
  )}
`

const ButtonCell = styled(Col)`
  display: flex;
  margin-top: 3px;

  ${breakpointUp('md', 'margin-top: 0')}

  button {
    width: 100%;
  }
`

const ImageCell = styled(Col)`
  margin-top: 20px;

  ${breakpointUp('md', 'margin-top: 0; text-align: right;')}
`

const ImageCredit = styled.div`
  color: #fff;
  text-align: right;
  position: absolute;
  right: 15px;
  top: 5px;
`

const LocationHeader: React.FC = () => {
  const { region } = useParams<{ country?: string; region?: string }>()
  const currentRegion = getRegionByUrlValue(region || '') || undefined
  const history = useHistory()
  const [selected, setSelected] = React.useState<T.LocationOption | undefined>(
    undefined,
  )
  const onGo = () => {
    if (!selected) return
    history.push(`/location/${selected.value.country}/${selected.value.region}`)
  }

  const image = currentRegion ? getRegionImage(currentRegion[0]) : undefined
  return (
    <Container>
      <Hero
        image={image?.img && `${process.env.PUBLIC_URL}/headers/${image.img}`}
      />
      <SelectContainer>
        {!!image?.credit && <ImageCredit>Photo by {image.credit}</ImageCredit>}
        <ContentContainer>
          <Heading>Find your perfect NZ experience</Heading>
          <Row align="center">
            <Col xs={12} md={7}>
              <Row gutterWidth={3}>
                <Col>
                  <LocationSelect
                    onChange={(location) => {
                      if (!location || !location.value) return
                      setSelected(location)
                    }}
                  />
                </Col>
                <ButtonCell xs={12} md="content">
                  <Button onclick={onGo} buttonStyle="tertiary">
                    Go.
                  </Button>
                </ButtonCell>
              </Row>
            </Col>
            <ImageCell>
              <img
                src={`${process.env.PUBLIC_URL}/logos/nz-owned.svg`}
                alt="NZ Owned"
                style={{ marginRight: 71, verticalAlign: 'middle' }}
              />
              <img
                src={`${process.env.PUBLIC_URL}/logos/powered-by-traversel.svg`}
                alt="Powered by Traversel"
                style={{ width: 140, verticalAlign: 'middle' }}
              />
            </ImageCell>
          </Row>
        </ContentContainer>
      </SelectContainer>
    </Container>
  )
}

export default LocationHeader
