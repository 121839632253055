import styled, { css } from 'styled-components'

import { fontHeading } from './fonts'

export const Label = styled.label`
  ${fontHeading}
  font-size: 12px;
  font-weight: bold;
`

export const LeftIcon = styled.span`
  color: #9b9b9b;
  margin-right: 10px;
`

export const inputMargin = css`
  margin-bottom: 30px;
`

export const inputMarginSmall = css`
  margin-bottom: 10px;
`

export const Help = styled.p`
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  margin: 0;
`
