import React from 'react'
import { TabList as OriginalTabList } from 'react-tabs'
import styled from 'styled-components'

import ContentContainer from './ContentContainer'
import { fontHeading } from '../styles/fonts'

const Background = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};

  .react-tabs__tab-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-content: flex-start;
    overflow-x: auto;
  }

  .react-tabs__tab {
    ${fontHeading}
    font-weight: bold;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    padding: 27px 15px;
    text-align: center;
  }

  .react-tabs__tab--selected {
    background: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.primary};
  }

  .react-tabs__tab--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .tabs__content-container {
    position: relative;
  }

  @media only screen and (min-width: 767px) {
    .react-tabs__tab {
      font-size: 21px;
      line-height: 25px;
      padding: 27px 35px;
    }
  }
`

type Props = {
  rightContent?: React.ReactNode
}
const TabList: React.FC<Props> = ({ children, rightContent }) => {
  return (
    <Background>
      <ContentContainer className="tabs__content-container">
        <OriginalTabList>{children}</OriginalTabList>
        {rightContent}
      </ContentContainer>
    </Background>
  )
}

;(TabList as any).tabsRole = 'TabList'

export default TabList
