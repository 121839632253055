import React from 'react'
import styled from 'styled-components'
import { Link, useHistory, useParams } from 'react-router-dom'
import jwtDecode from 'jwt-decode'

import { py, px } from '../styles/spacing'
import { h3Black } from '../styles/fonts'
import Loading from '../components/Loading'
import { getOtpToken } from '../api'
import { useAuthDispatch } from '../hooks/authContext'
import { trackEvent } from '../util/analytics'

const Wrapper = styled.div`
  ${py(2)}
  ${px(2)}
  text-align: center;
`

const Title = styled.h1`
  ${h3Black}
`

const Auth: React.FC = () => {
  const history = useHistory()
  const { token } = useParams<{ token: string }>()
  const [error, setError] = React.useState(false)
  const authDispatch = useAuthDispatch()

  React.useEffect(() => {
    const runGetToken = async () => {
      try {
        const accessToken = await getOtpToken(token)
        const decoded: any = jwtDecode(accessToken)
        authDispatch({
          action: 'LogIn',
          userId: decoded['https://traversel.com/claims/uid'] || '',
        })

        trackEvent('loggedIn', {
          userId: decoded['https://traversel.com/claims/uid'] || '',
        })

        history.push('/')
      } catch (e) {
        console.log(e)
        setError(true)
      }
    }
    runGetToken()
  }, [token, history, authDispatch])

  return (
    <Wrapper>
      {error && (
        <div>
          <Title>Oops!</Title>
          <p>
            There was an error logging you in. Please{' '}
            <Link to="/">try again</Link>
          </p>
        </div>
      )}
      {!error && (
        <div>
          <Title>Logging you in...</Title>
          <Loading />
        </div>
      )}
    </Wrapper>
  )
}

export default Auth
