import React from 'react'
import styled from 'styled-components'
import Color from 'color'

const StyledWrapper = styled.div`
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.tertiary};
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${({ theme }) =>
      Color(theme.colors.tertiary).alpha(0.2).string()};
    border-color: ${({ theme }) =>
      Color(theme.colors.tertiary).alpha(0.2).string()};
    color: inherit;
  }

  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${({ theme }) => theme.colors.tertiary};
    border-color: ${({ theme }) => theme.colors.tertiary};
  }

  .CalendarDay__blocked_calendar {
    background: #fff;
    border: 1px solid #e4e7e7;
    color: #cacccd;
  }

  .DateInput {
    width: 90px;
  }

  .DateInput_input,
  .DateRangePickerInput_arrow {
    font-size: 16px;
    font-weight: 300;
    padding: 0;
    color: #595959;
  }

  .DateRangePickerInput_arrow {
    padding: 0 10px;
  }

  .DateInput_input__focused {
    border-color: ${({ theme }) => theme.colors.tertiary};
  }

  .DateRangePickerInput {
    border: none;
  }

  .DateRangePickerInput_calendarIcon {
    padding: 0;
    margin: 0 10px 0 0;
    font-size: 18px;
  }

  .DateRangePickerInput_calendarIcon_svg {
    width: 18px;
    height: 18px;
    vertical-align: top;
  }

  .DayPickerNavigation_button__verticalDefault {
    box-sizing: border-box;
  }

  .DayPickerNavigation_button__horizontalDefault {
    top: 22px;
    padding: 4px 9px;
  }
`

const DateWrapper: React.FC = ({ children }) => {
  return <StyledWrapper>{children}</StyledWrapper>
}

export default DateWrapper
