import React from 'react'
import styled, { keyframes } from 'styled-components'

const fade = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
`

const Card = styled.div`
  border: 1px solid #9b9b9b;
  animation: ${fade} 3s ease-in-out infinite;

  > * {
    flex-basis: 100%;
  }
`

const CardContent = styled.div`
  padding: 15px;
  background: #fff;
`

const CardHeader = styled.div`
  height: 19px;
  background: #ccc;
`
const CardSubheading = styled.div`
  height: 15px;
  background: #ccc;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 75%;
`

const CardDescription = styled.div`
  height: 70px;
  background: #ccc;
`

const ImageWrap = styled.div`
  padding-bottom: 58%;
  position: relative;
  overflow: hidden;
  background: #ccc;
`

const ProductCardSkeleton: React.FC = () => {
  return (
    <Card>
      <ImageWrap />
      <CardContent>
        <CardHeader />
        <CardSubheading />
        <CardDescription />
      </CardContent>
    </Card>
  )
}

export default ProductCardSkeleton
