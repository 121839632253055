import React from 'react'
import styled from 'styled-components'
import { Carousel } from 'react-responsive-carousel'
import * as T from '../types'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const GalleryWrapper = styled.div`
  height: fit-content;
`

const ImageWrapper = styled.div`
  background: #fff;
  padding-top: 100px;
  img {
    height: 500px;
    width: auto !important;
  }
`

type ProductGridProps = { productImages: T.ProductImages[] }
const ProductGallery: React.FC<ProductGridProps> = ({ productImages }) => {
  return (
    <GalleryWrapper>
      <Carousel>
        {productImages.map((image) => (
          <ImageWrapper>
            <img src={image.url} alt={image.alt} />
          </ImageWrapper>
        ))}
      </Carousel>
    </GalleryWrapper>
  )
}

export default ProductGallery
