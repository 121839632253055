import clone from 'lodash/clone'

import createReducerContextWithProvider from './createContext'
import { CartItem } from '../types'
import storageUtil from '../util/storage'

type Action =
  | {
      action: 'AddToCart'
      item: CartItem
    }
  | {
      action: 'OpenCart'
    }
  | {
      action: 'CloseCart'
    }
  | {
      action: 'ClearCart'
    }

type State = {
  cart: CartItem[]
  open: boolean
}

const itemKey = 'cartItems'

function reducer(state: State, action: Action): State {
  const newState = clone(state)
  switch (action.action) {
    case 'AddToCart': {
      // for now we'll only allow one item in the cart
      newState.cart = [action.item]
      // newState.cart.push(action.item)
      storageUtil.setItem(itemKey, JSON.stringify(newState.cart))
      return newState
    }
    case 'OpenCart': {
      return {
        ...newState,
        open: true,
      }
    }
    case 'CloseCart': {
      return {
        ...newState,
        open: false,
      }
    }
    case 'ClearCart': {
      storageUtil.removeItem(itemKey)
      return {
        ...state,
        cart: [],
      }
    }
    default: {
      return state
    }
  }
}

const defaultState = { cart: [], open: false }
// eslint-disable-next-line import/no-named-as-default-member
const hydratedState = storageUtil.getItem(itemKey)
if (hydratedState) defaultState.cart = JSON.parse(hydratedState)

const {
  Provider: CartProvider,
  useState: useCartState,
  useDispatch: useCartDispatch,
} = createReducerContextWithProvider<State, Action>(
  'Cart',
  reducer,
  defaultState,
)

export default { CartProvider, useCartDispatch, useCartState }
