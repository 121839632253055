import React from 'react'
import styled, { css } from 'styled-components'

type Props = {
  onClick?(e: React.MouseEvent<HTMLButtonElement>): void
  underline?: boolean
  block?: boolean
}

const Button = styled.button<Props>`
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  appearance: none;
  font: inherit;
  padding: 0;

  ${(props) =>
    props.underline &&
    css`
      text-decoration: underline;
    `}

  ${(props) =>
    props.block &&
    css`
      display: block;
      width: 100%;
      text-align: left;
    `}
`

const BlankButton: React.FC<Props> = ({
  onClick,
  children,
  underline,
  block,
}) => {
  return (
    <Button onClick={onClick} type="button" underline={underline} block={block}>
      {children}
    </Button>
  )
}

export default BlankButton
