import React from 'react'
import styled from 'styled-components'

import { labelMedium } from '../../styles/fonts'

type HeadingProps = {
  noMargin?: boolean
}
const StyledSectionHeading = styled.h2<HeadingProps>`
  ${labelMedium}
  margin-bottom: 20px;
  margin-top: ${(props) => (props.noMargin ? '0' : '40px')};
`

const SectionHeading: React.FC<HeadingProps> = ({ noMargin, children }) => {
  return (
    <StyledSectionHeading noMargin={noMargin}>{children}</StyledSectionHeading>
  )
}

export default SectionHeading
