/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { DayPickerSingleDateController } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import { useField } from 'formik'
import moment from 'moment'
import styled from 'styled-components'

import { Label } from '../../styles/forms'
import DateWrapper from './DateWrapper'
import { useWindowSize } from '../../hooks/useWindowSize'
import ErrorMesssage from './ErrorMessage'

type DatePickerProps = {
  name: string
  label?: string
  min?: string | Date | moment.Moment | null
  max?: string | Date | moment.Moment
  yearMin?: number
  yearMax?: number
  onChange?: (m: moment.Moment | null) => void
  blocked?: Record<number, boolean>
  onDisplayMonthChange?: (month: moment.Moment) => void
}

const StyledSelect = styled.select`
  padding: 5px 10px;
  margin: 0 2px;
  border: 1px solid #eaeaea;
  border-radius: 0;
`

type RenderMonthProps = {
  month: moment.Moment
  onMonthSelect: (currentMonth: moment.Moment, newMonthVal: string) => void
  onYearSelect: (currentMonth: moment.Moment, newYearVal: string) => void
  isVisible: boolean
  yearMin?: number
  yearMax?: number
}
const renderMonthElement = ({
  month,
  onMonthSelect,
  onYearSelect,
  yearMax,
  yearMin,
}: RenderMonthProps) => {
  let i
  const years = []
  const startYear = yearMax || moment().year()
  const endYear = yearMin || moment().year() - 100
  // eslint-disable-next-line no-plusplus
  for (i = startYear; i >= endYear; i--) {
    years.push(
      <option value={i} key={`year-${i}`}>
        {i}
      </option>,
    )
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        <StyledSelect
          value={month.month()}
          onChange={(e) => onMonthSelect(month, e.target.value)}
        >
          {moment.months().map((label, value) => (
            <option value={value} key={label}>
              {label}
            </option>
          ))}
        </StyledSelect>
      </div>
      <div>
        <StyledSelect
          value={month.year()}
          onChange={(e) => onYearSelect(month, e.target.value)}
        >
          {years}
        </StyledSelect>
      </div>
    </div>
  )
}

const SingleDate: React.FC<DatePickerProps> = ({
  name,
  label,
  min,
  max,
  yearMin,
  yearMax,
  onChange,
  blocked,
  onDisplayMonthChange,
}) => {
  const [field, _meta, helpers] = useField<null | string>(name)
  const [focused, setFocused] = React.useState<boolean>(false)
  const windowSize = useWindowSize()

  const onDatesChange: (date: moment.Moment | null) => void = (newDate) => {
    helpers.setValue(newDate?.format('YYYY-MM-DD') || null)
    if (typeof onChange === 'function') onChange(newDate)
  }

  const momentValue = field.value ? moment(field.value) : null

  const minMoment = min === null ? null : moment(min)
  const maxMoment = moment(max)

  return (
    <div>
      {label && <Label htmlFor={`${name}_start`}>{label}</Label>}
      <DateWrapper>
        <DayPickerSingleDateController
          date={momentValue}
          onDateChange={onDatesChange}
          onFocusChange={(arg) => setFocused(!!arg.focused)}
          focused={focused}
          hideKeyboardShortcutsPanel
          orientation={windowSize.width < 768 ? 'vertical' : 'horizontal'}
          isOutsideRange={(d) => {
            const day = moment(d)
            return (
              (minMoment !== null && day.isBefore(minMoment)) ||
              (!!max && day.isAfter(maxMoment))
            )
          }}
          renderMonthElement={(props) => {
            if (onDisplayMonthChange && props.isVisible) {
              onDisplayMonthChange(props.month)
            }
            return renderMonthElement({ ...props, yearMax, yearMin })
          }}
          isDayBlocked={(day) => {
            const dayUnix = day.startOf('day').unix()
            return blocked?.[dayUnix] || false
          }}
          verticalHeight={400}
        />
      </DateWrapper>
      <ErrorMesssage name={name} />
    </div>
  )
}

export default SingleDate
