import React from 'react'
import styled from 'styled-components'

import ContentContainer from '../components/ContentContainer'

const Header = styled.div`
  margin-top: 20px;
`

const PoweredByTraverselWrap = styled.div`
  background-color: #000;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 0;
  font-size: 0;

  img {
    height: auto;
    max-width: 100%;
  }
`

const Container = styled(ContentContainer)`
  max-width: 800px;
`

const ScheduledMaintenance: React.FC = () => {
  return (
    <div>
      <PoweredByTraverselWrap>
        <Container>
          <img
            src={`${process.env.PUBLIC_URL}/logos/powered-by-traversel.svg`}
            alt="Powered by Traversel"
          />
        </Container>
      </PoweredByTraverselWrap>
      <Container>
        <Header>
          <img
            src={`${process.env.PUBLIC_URL}/logos/logo-mix-and-match.svg`}
            alt="Logo"
            width={230}
          />
        </Header>
        <p>
          Traversel is carrying out site maintenance and is currently
          unavailable. If you need urgent assistance with a booking please
          contact the Mix and Match New Zealand-based Call Centre on:
          <br />
          <strong>Phone:</strong> <a href="tel:0800 367 468">0800 367 468</a>
          <br />
          <strong>International callers dial:</strong>{' '}
          <a href="tel:+64 3 357 3021">+64 3 357 3021</a>
          <br />
          <strong>Email:</strong>{' '}
          <a href="mailto:help@mixandmatch.co.nz">help@mixandmatch.co.nz</a>
        </p>
        <p>
          Call Centre open hours:
          <br />
          <strong>Monday-Friday:</strong> 08.00am-midnight
          <br />
          <strong>Sat - Sun:</strong> 09.00am-5.30pm
          <br />
          <strong>Public Holidays:</strong> 09.00am-5.30pm
        </p>
        <p>After hours calls will be diverted to an answering service.</p>
      </Container>
    </div>
  )
}

export default ScheduledMaintenance
