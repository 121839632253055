import { ProductSearchResult } from '../types'
import { getDiscountedProductIds } from '../util/pricing'
import createReducerContextWithProvider from './createContext'

type Action =
  | {
      action: 'SetProducts'
      products: ProductSearchResult[]
    }
  | {
      action: 'SetLoading'
      isLoading: boolean
    }

type State = {
  discountedProducts: ProductSearchResult[]
  homePageProducts: ProductSearchResult[]
  productsByRegion: Record<string, ProductSearchResult[]>
  isLoading: boolean
  validRegions: Record<string, boolean>
}

function reducer(state: State, action: Action): State {
  switch (action.action) {
    case 'SetLoading': {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }
    case 'SetProducts': {
      const discountedProductIds = getDiscountedProductIds()
      const transformedProducts = action.products.map((product) => {
        return {
          ...product,
          discounted: discountedProductIds.includes(product.id),
        }
      })

      // get all the discounted products
      const discountedProducts = transformedProducts.filter(
        (product) => product.discounted,
      )

      // find all the products to display on the homepage
      const homePageProducts = transformedProducts.filter((product) => {
        const displayOnHome = product.details?.find(
          (detail) => detail.code === 'DisplayOnHome',
        )
        return displayOnHome?.value === 'true'
      })

      const productsByRegion = transformedProducts.reduce<
        Record<string, ProductSearchResult[]>
      >((prev, current) => {
        if (!current.address?.stateProvinceCode) return prev
        const regionKey = current.address.stateProvinceCode
        const previousRecords = prev[regionKey] || []
        return {
          ...prev,
          [regionKey]: [...previousRecords, current],
        }
      }, {})

      const validRegions = transformedProducts.reduce<Record<string, boolean>>(
        (prev, current) => {
          const stateCode = current.address?.stateProvinceCode
          if (!stateCode) return prev
          return {
            ...prev,
            [stateCode]: true,
          }
        },
        {},
      )

      return {
        ...state,
        discountedProducts,
        homePageProducts,
        productsByRegion,
        validRegions,
      }
    }
    default: {
      return state
    }
  }
}

const initialState = {
  homePageProducts: [],
  discountedProducts: [],
  productsByRegion: {},
  isLoading: false,
  validRegions: {},
}

const {
  Provider: ProductsProvider,
  useState: useProductSate,
  useDispatch: useProductDispatch,
} = createReducerContextWithProvider('products', reducer, initialState)

export { ProductsProvider, useProductDispatch, useProductSate }
