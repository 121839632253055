import { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  fonts: {
    base: "Montserrat, 'Helvetica Neue', 'Helvetica', 'sans-serif'",
    heading: 'Montserrat, Helvetica, Arial, sans-serif',
  },
  colors: {
    primary: '#1D1D1B',
    secondary: '#fff',
    tertiary: '#F7C615',
    error: '#f55c5c',
  },
}

export default theme
