import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import 'react-dates/initialize'

import Home from './views/Home'
import Product from './views/Product'
import Checkout from './views/Checkout'
import Auth from './views/Auth'
import theme from './theme'
import cartContext from './hooks/cartContext'
import Loading from './components/Loading'
import Cart from './components/Cart'
import CheckoutSuccess from './components/checkout/Success'
import './App.css'
import {
  AuthProvider,
  useAuthDispatch,
  useAuthState,
} from './hooks/authContext'
import storageUtils from './util/storage'
import { setApiToken, getToken, searchProducts } from './api'
import TermsOfUse from './views/TermsOfUse'
import Cancellation from './views/Cancellation'
import PrivacyPolicy from './views/PrivacyPolicy'
import Support from './views/Support'
import FourOhFour from './views/404'
import SupplierTermsOfUse from './views/SupplierTermsOfUse'
import { trackEvent } from './util/analytics'
import ScheduledMaintenance from './views/ScheduledMaintenance'
import Location from './views/Location'
import { ProductsProvider, useProductDispatch } from './hooks/productContext'

const Wrapper = styled.div`
  font-family: ${(props) => props.theme.fonts.basic};
`

const RouteHandler: React.FC = ({ children }) => {
  const loc = useLocation()
  const authState = useAuthState()
  const cartDispatch = cartContext.useCartDispatch()
  // fire events whenever the route changes
  React.useEffect(() => {
    if (!/^\/checkout/.test(loc.pathname)) {
      cartDispatch({ action: 'ClearCart' })
    }
    if (authState.loggedIn) {
      trackEvent('userLoginStatus', {
        loginStatus: 'loggedIn',
        userId: authState.userId,
      })
    } else {
      trackEvent('userLoginStatus', {
        loginStatus: 'Not loggedIn',
      })
    }
  }, [loc.pathname, authState.loggedIn, authState.userId, cartDispatch])

  return <>{children}</>
}

const AppWrapper = () => {
  const authDispatch = useAuthDispatch()
  const productDispatch = useProductDispatch()

  // subscript to window events
  React.useEffect(() => {
    const onLogout = () => {
      authDispatch({ action: 'LogOut' })
    }
    window.addEventListener('tsl-logout', onLogout)
    return () => {
      window.removeEventListener('tsl-logout', onLogout)
    }
  }, [authDispatch])

  // fetch all the products initially
  React.useEffect(() => {
    ;(async () => {
      try {
        productDispatch({
          action: 'SetLoading',
          isLoading: true,
        })
        const searchResults = await searchProducts({
          includeDetails: 'DisplayOnHome',
        })

        productDispatch({
          action: 'SetProducts',
          products: searchResults.data.data.items,
        })
      } catch (e) {
        console.log(e)
      } finally {
        productDispatch({
          action: 'SetLoading',
          isLoading: false,
        })
      }
    })()
  }, [productDispatch])

  const maintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true'
  return (
    <Wrapper>
      <Router>
        <RouteHandler>
          {maintenanceMode && (
            <Switch>
              <Route path="*">
                <ScheduledMaintenance />
              </Route>
            </Switch>
          )}
          {!maintenanceMode && (
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/auth/callback/:token">
                <Auth />
              </Route>
              <Route path="/location/:country/:region">
                <Location />
              </Route>
              <Route path="/attraction/:attractionId/product/:productId">
                <Product />
              </Route>
              <Route path="/checkout" exact>
                <Checkout />
              </Route>
              <Route path="/checkout/success">
                <CheckoutSuccess />
              </Route>
              <Route path="/terms-of-use">
                <TermsOfUse />
              </Route>
              <Route path="/supplier-terms-of-use">
                <SupplierTermsOfUse />
              </Route>
              <Route path="/cancellation-policy">
                <Cancellation />
              </Route>
              <Route path="/privacy-policy">
                <PrivacyPolicy />
              </Route>
              <Route path="/support">
                <Support />
              </Route>
              <Route path="*" status={404}>
                <FourOhFour />
              </Route>
            </Switch>
          )}
        </RouteHandler>
        <Cart />
      </Router>
    </Wrapper>
  )
}

function App() {
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    const runGetToken = async () => {
      try {
        // first check for a token
        const token = storageUtils.getItem('tsl-token')
        if (token) {
          setApiToken(token)
        } else {
          await getToken()
        }
        setIsLoading(false)
      } catch (e) {
        alert('Could not get token')
      }
    }

    runGetToken()
  }, [])

  if (isLoading) return <Loading />

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <cartContext.CartProvider>
          <ProductsProvider>
            <AppWrapper />
          </ProductsProvider>
        </cartContext.CartProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App
