import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

const MenuWrapper = styled.div<{
  mouseOver: boolean
  hoverElLeft: number
}>`
  width: 100vw;
  position: absolute;
  left: -${(props) => props.hoverElLeft}px;
  display: ${(props) => (props.mouseOver ? 'block' : 'none')};
  z-index: 999999999;
  :hover {
    display: block;
  }
`

const MenuInner = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  padding: 20px;
  text-align: left;
`

const HoverEl = styled.div`
  :hover {
  }
`

const HoverWrapper = styled.div`
  position: relative;
  padding: 8px 8px 0;
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  margin-right: 20px;

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
    background: ${(props) => props.theme.colors.primary};
    &::after {
      opacity: 1;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: 395px) {
    font-size: 14px;
  }

  .HoverMegaMenu__HoverElement {
    cursor: pointer;
  }
`

type HoverMegaMenuProps = {
  hoverElement: any
  className?: string
  useClick?: boolean
}
const HoverMegaMenu: React.FC<HoverMegaMenuProps> = ({
  children,
  hoverElement,
  className,
  useClick = false,
}) => {
  const [mouseOver, setMouseOver] = useState(false)
  const [hoverElementFromLeft, setHoverElementFromLeft] = useState(0)
  const hoverRef = useRef<HTMLDivElement | null>(null)
  const { pathname } = useLocation()

  useEffect(() => {
    if (!mouseOver) return
    const hoverElementLeft = hoverRef.current?.getBoundingClientRect().x
    if (hoverElementLeft) {
      setHoverElementFromLeft(hoverElementLeft)
    }
  }, [mouseOver])

  // watch for location changes and hide the nav
  useEffect(() => {
    setTimeout(() => {
      setMouseOver(false)
    }, 100)
  }, [pathname])

  return (
    <HoverWrapper className={className} ref={hoverRef}>
      <HoverEl
        className={`HoverMegaMenu__HoverElement ${
          mouseOver && 'HoverMegaMenu--active'
        }`}
        onMouseEnter={() => !useClick && setMouseOver(true)}
        onMouseLeave={() => !useClick && setMouseOver(false)}
        onClick={() => useClick && setMouseOver(!mouseOver)}
      >
        {hoverElement}
      </HoverEl>
      <MenuWrapper
        hoverElLeft={hoverElementFromLeft}
        mouseOver={mouseOver}
        className="HoverMegaMenu__Wrapper"
      >
        <MenuInner>{children}</MenuInner>
      </MenuWrapper>
    </HoverWrapper>
  )
}

export default HoverMegaMenu
