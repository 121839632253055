const setItem = (key: string, item: any): void => {
  try {
    const toSave = typeof item === 'object' ? JSON.stringify(item) : item
    localStorage.setItem(key, toSave)
  } catch (e) {
    console.log('Could not save to localStorage', e)
  }
}

const getItem = <T = string>(key: string, isObject = false): null | T => {
  try {
    const item = localStorage.getItem(key)
    if (!item) return null
    return isObject ? JSON.parse(item) : item
  } catch (e) {
    console.log('Could not retrieve from localStorage')
    return null
  }
}

const removeItem = (key: string): void => {
  try {
    localStorage.removeItem(key)
  } catch (e) {
    console.log('Could not remove localStorage item', e)
  }
}

export default {
  setItem,
  getItem,
  removeItem,
}
